@import 'src/css/common/_custom_scrollbar';
@import 'src/css/common/_input';

.popup {
	max-width: 210px !important;
	min-width: 250px !important;
	height: auto;
	z-index: 999 !important;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
	background-color: var(--white);
	border-radius: 20px !important;
	padding: 12px !important;
}

.watchlists {
	// max-height: 210px;
	overflow-y: auto;
	background: var(--menu-primary);
}

.watchlists::-webkit-scrollbar-track-piece {
	background-color: white;
}

.watchlists::-webkit-scrollbar {
	width: 4px;
}

.listContainer {
	width: 100%;
	padding: 8px 12px;
	background-color: var(--menu-primary);
	gap: 8px;
	border-radius: 8px;

	&:hover {
		background-color: var(--surface-low); /* background-color-gray-5 */
	}
}

.listContainerActive {
	background-color: var(--surface-low);
	border: 1px solid var(--border-accent)
}

.itemContainer {
	display: flex;
	white-space: nowrap;
	cursor: pointer;
	justify-content: space-between;
}

.watchlistItem {
	width: 85%;
	overflow-x: hidden;
	text-overflow: ellipsis;
	display: flex;
	align-items: center;
	overflow-y: hidden;

	&:hover {
		.icon {
			color: var(--text-primary); /* //color-dark */
		}
	}

	.icon {
		font-size: 16px;
		color: var(--gray-30);

		&.active {
			color: var(--icon-primary); /* //color-dark */
		}
	}
}

.icon {
	cursor: pointer;
	color: var(--icon-tertiary); /* //color-dark */
	width: 19px;
	height: 19px;
}

.iconPen {
	color: var(--text-primary);
}

.watchlistName {
	cursor: pointer;
	color: var(--text-primary); /* //color-dark */
	padding-left: 8px;
}

.createWLContainer {
	display: contents;
	justify-content: space-between;
	width: 206px;
	height: 260px;
	padding: 16px 0 0;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
	background-color: #fff;
}

.createWatchlistItems {
	text-align: center;
	padding: 5px 5px;
	z-index: 1;
	border-bottom: 1px solid var(--gray-10);
}

.createWatchlistTitle {
	font-size: 13px;
	font-weight: bold;
	color: var(--text-primary); /* //color-dark */
	margin-left: 3px;
}

.inputContainer {
	width: 100%;
	height: 171px;
	padding: 10px 30px 0;
	background-color: var(--surface-primary);
}

.inputTitle {
	width: 100%;
	height: 13px;
	margin: 10px 0;

	font-size: 11px;
	color: var(--text-secondary);
}

.inputBox {
	text-align: center;
}

.costInput {
	@extend %cost-input;

	width: 100%;
	max-width: none;
	margin-bottom: 5px;
}

.lightErrorInput {
	@extend .costInput;
	border: 1px solid var(--watermelon);
}

.messageRow {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.errorMessage {
	width: 100%;
	font-size: 12px;
	color: var(--watermelon);
}

.inputContainerText {
	text-align: right;
	height: 16px;
	margin-left: auto;

	font-size: 13px;
	font-weight: normal;
	text-align: right;
	color: var(--gray-30);
}

.popupFooter {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 50px;
	padding: 11px 14px 9px;
	border-radius: 3px;
	box-shadow: var(--box-shadow-reversed);
	background-color: var(--surface-primary);

	button {
		width: 45%;
		max-width: none;
	}
}

.Title {
	font-weight: 700;
	cursor: pointer;
}

.lightTitle {
	color: black;

	font-size: 13px;
	font-weight: bold;
}

.darkTitle {
	color: var(--color-neutral-1);

	font-size: 13px;
	font-weight: bold;
}
