.emptyStateContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 15px;
	border: solid 1px var(--gray-30);
	border-radius: 0 0 12px 12px;
	border-top: none !important;
}

.emptyIcon {
	color: var(--gray-30);
	font-size: 80px;
	opacity: 0.3;
	margin-bottom: 10px;
}

.noMatch {
	color: var(--text-secondary);
	text-align: center;
	margin-bottom: 10px;
}

.searchTerm {
	color: var(--text-primary); /* //color-dark */
}

.tickerMessage {
	@extend .noMatch;
	margin-bottom: 0;
}
