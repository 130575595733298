$border-radius: 4px;

%notification {
	width: 100%;
	display: flex;
	border-radius: $border-radius;
	overflow: hidden;

	.title,
	.body {
		width: 100%;
	}

	.body {
		display: flex;
		flex-direction: column;
		background-color: var(--surface-primary);
	}
}

%icon {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.fa {
		color: var(--constant-light);
		font-size: 28px;
	}
}

.messageContainer {
	flex-basis: 80%;
	padding: 8px 12px 8px 8px;
	display: inline-block;
	overflow: hidden;
}

.message {
	margin: 0;

	color: var(--text-primary); /* //color-dark */
	font-weight: bold;
}

.toastTitle {
	margin-top: 5px;
	color: var(--text-primary); /* //color-dark */
	padding: 5px 0 0 9px;
	font-weight: bold;
}

.title {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.icon {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.fa {
		color: var(--light);
		font-size: 28px;
	}
}

.closeIcon {
	width: 20px;
	margin-top: 5px;
	color: var(--text-primary); /* //color-dark */
}

.notificationInfo {
	@extend %notification;

	.icon {
		@extend %icon;

		border-left: 8px solid var(--border-accent);
	}
}

.notificationError {
	@extend %notification;

	.icon {
		@extend %icon;

		border-left: 8px solid var(--border-alert);
	}
}

.notificationFatal {
	@extend .notificationError;

	.body {
		background-color: var(--surface-alert);
	}

	.icon {
		border-left: 8px solid var(--border-alert);
	}

	.title,
	.toastTitle,
	.message {
		color: var(--constant-light);
	}
}
