.container {
  .dayAndTimeWrapper {
    display: flex;
    justify-content: flex-end;

    .label {
      margin-right: 5px;
      font-weight: 700;
    }

    .valuesWrapper {
      display: flex;
      flex-flow: column;

      .value {
        display: flex;
        justify-content: flex-end;
        align-content: center;
      }
    }
  }
}