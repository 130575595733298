.container {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: var(--text-secondary);
	text-align: center;
	font-size: var(--font-size-15);
	width: 100%;
	height: 100%;

	&.fullHeight {
		height: 100%;
	}

	.noResultsIcon {
		font-size: 80px;
		color: var(--surface-tertiary);
	}

	.message {
		padding: 0 8px;
		margin: 12px 0;
	}
}
