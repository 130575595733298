.calendarContainer {
	position: absolute;
	z-index: 3;
	right: 10px;

	.calendarInput :global .react-datepicker__day--in-range {
		background: var(--light) !important;
		color: var(--topaz) !important;
	}

	.calendarInput :global .react-datepicker__day--selected,
	:global .react-datepicker__day--in-range {
		background-color: var(--surface-accent) !important;
		color: var(--constant-light) !important;
		font-weight: bold !important;
	}

	.calendarInput :global .react-datepicker__day-name,
	:global .react-datepicker__day,
	:global .react-datepicker__time-name {
		color: var(--text-primary) !important; /* //color-dark */
		font-size: 13px !important;
		line-height: 2.7rem !important;
		width: 2.7rem !important;
	}

	.calendarInput :global .react-datepicker__day--disabled,
	:global .react-datepicker__month-text--disabled,
	:global .react-datepicker__quarter-text--disabled,
	:global .react-datepicker__year-text--disabled {
		color: var(--gray-30) !important;
	}

	.calendarInput :global .react-datepicker__header {
		background-color: var(--surface-primary) !important;
		border-top-left-radius: 11px !important;
		border-top-right-radius: 11px !important;
		border: none !important;
		box-shadow: var(--box-shadow);
	}

	.calendarInput :global .react-datepicker__current-month,
	:global .react-datepicker-time__header,
	:global .react-datepicker-year-header {
		color: var(--text-primary) !important; /* //color-dark */
		font-size: 14px !important;
	}

	.calendarInput :global .react-datepicker__navigation {
		&:focus-visible {
			outline: none;
		}
	}

	.calendarInput :global .react-datepicker__navigation--next {
		right: 75px !important;
		border-left-color: var(--text-primary) !important; /* //color-dark */
		top: 18px !important;
		z-index: 1 !important;
	}

	.calendarInput :global .react-datepicker__navigation--previous {
		left: 70px !important;
		border-right-color: var(--text-primary) !important; /* //color-dark */
		top: 17px !important;
		z-index: 1 !important;
	}

	.calendarInput :global .react-datepicker__month {
		background-color: var(--surface-primary); /* background-color-light */
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
		border-bottom: none !important;
		margin: 0 !important;
	}

	.calendarInput :global .react-datepicker__day:hover,
	.calendarInput :global .react-datepicker__month-text:hover,
	.calendarInput :global .react-datepicker__quarter-text:hover,
	.calendarInput :global .react-datepicker__year-text:hover {
		border-radius: 3.3rem;
		background: var(--gray-10);
	}

	.calendarInput :global .react-datepicker__day--keyboard-selected {
		color: var(--text-primary) !important; /* //color-dark */
		background-color: transparent !important;
	}
}

html[dir='rtl'] {
	.calendarContainer {
		right: -128px;
	}
}
