@import 'src/css/common/_custom_scrollbar';
@import 'src/css/common/_input';

.container {
	height: 100%;
	background-color: var(--surface-primary);
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .darkCheck {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .darkCheck {
	background-color: white;
}

/* Show the redCheck when checked */
.container input:checked ~ .darkCheck:after {
	display: block;
}

/* Style the redCheck/indicator */
.container .darkCheck:after {
	left: 7px;
	top: 3px;
	width: 10px;
	height: 15px;
	border: solid var(--color-neutral-2);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .lightCheck {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .lightCheck {
	background-color: white;
}

/* Show the greenCheck when checked */
.container input:checked ~ .lightCheck:after {
	display: block;
}

/* Style the greenCheck/indicator */
.container .lightCheck:after {
	left: 7px;
	top: 3px;
	width: 10px;
	height: 15px;
	border: solid var(--color-neutral-6);
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.textAll {
	font-size: 13px;
	font-weight: normal;
	cursor: pointer;
	line-height: 2.3;
	padding-left: 30px;
	color: var(--text-primary); /* //color-dark */
}

.windowEditHeader {
	align-items: center;
	height: 32px;
	display: flex;
	justify-content: space-between;
	padding: 10px 30px;
	border-bottom: 1px solid lightgrey;

	@media screen and (max-width: 1023px) {
		padding: 0px;
	}
}

.windowHeader {
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 10px;

	@media screen and (max-width: 1023px) {
		padding: 0px;
	}
}

.editHeader {
	padding: 6px 15px;
	height: 32px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
	display: flex;
	justify-content: space-between;
	background-color: var(--gray-5) !important;
	border-bottom: 1px solid var(--border-primary);
}

.editIcon {
	color: var(--text-primary); /* //color-dark */
}

.actionRow {
	display: flex;
	width: 100%;
	padding: var(--p-8);
	flex-grow: 0;
	justify-content: space-between;
	// Width equals to Watchlist(mainPanel) grid area width
	padding: var(--p-8);
	background-color: var(--surface-primary); /* background-color-gray-5 */
}

.editOptionContainer {
	width: fit-content;
	display: flex;
	align-items: center;
	padding-left: 10px;

	&:nth-child(1) {
		padding-left: 0;
		padding-right: 10px;
	}

	&:nth-child(2) {
		padding-right: 10px;
	}
}

.selectAll,
.move,
.remove {
	display: flex;
	align-items: center;

	font-size: 13px;
	font-weight: normal;
	margin: 0;
	color: var(--text-primary); /* //color-dark */
	cursor: pointer !important;
}

.selectAll {
	top: auto;
}

.move {
	.editOptionIcons {
		transform: scaleX(-1);
	}
}

.removeItemsIcon {
	height: 16px;
	width: 16px !important;
	margin: 2px 4px 0 0;
}

.WatchListCategories {
	overflow: hidden;
	height: calc(100% - 32px);
}

.windowWatchListCategories {
	height: 89vh;
	overflow: hidden;
	margin-top: var(--m-12);
	padding: 5px;

	@media screen and (max-width: 1023px) {
		height: 88vh;
		padding: 0px;
	}
}

// Account panel is expanded
.WatchListCategoriesChild {
	height: 100%;
	width: 100%;
	box-sizing: content-box;
}

// WatchList is on edit mode and the account panel is expanded
.WatchListCategoriesChildEdit {
	@extend .WatchListCategoriesChild;
	overflow: hidden;
	display: flex;
	flex-flow: column nowrap;
}

.windowEditDragContainer {
	height: 88vh;
	overflow-y: auto;
}

.instrumentBox {
	width: 100%;
	flex-grow: 0;
}

.windowMarketTickerContainerEdit {
	display: flex;
	flex-flow: column nowrap;
	overflow-y: auto;
	overflow-y: overlay;
	flex-grow: 1;
	background-color: var(--surface-primary);
}

.MarketTickerContainer {
	@extend %custom-scrollbar-element;

	display: flex;
	flex-flow: column nowrap;
	height: calc(100% - 32px);
	overflow-y: auto;
	overflow-y: overlay;
	overflow-x: hidden;
	cursor: pointer !important;
	background-color: var(--surface-primary); /* background-color-gray-5 */

	// only for Firefox
	scrollbar-width: thin;
}

.editContainer {
	// Set width same as Watchlist(mainPanel) grid width
	padding: var(--p-8);
	border-bottom: solid 1px var(--border-primary);
	display: flex;
	justify-content: space-between;
	background-color: var(--surface-primary); /* background-color-light */

	&:hover,
	&.showActions {
		.TickerIcons {
			display: block;
		}

		.equityField {
			display: none;
		}
	}
}

.windowEditContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding: 11px;
	flex: 0 0 100%;
	border-bottom: solid 1px #e8e9ea;
}

.TickerInfo {
	display: flex;
	justify-content: left;
	cursor: pointer !important;
}

.windowTickerInfo {
	display: flex;
	cursor: pointer !important;

	@media screen and (max-width: 1023px) {
		justify-content: space-between;
	}
}

.checkboxContainer {
	position: relative;
	margin-bottom: 6px;
	top: 3px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-right: 0px !important;
}

.editOptionCheckboxContainer {
	@extend .checkboxContainer;
	margin-right: 0;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	cursor: pointer;
	position: absolute;
	top: auto;
	height: 23px;
	width: 23px;
	background-color: var(--surface-primary);
	border: 1px solid var(--border-accent); //border-topaz
	border-radius: 15px;
	left: 0;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
	background-color: var(--surface-primary); /* background-color-gray-5 */
}

/* When the checkbox is checked, add a black background */
.checkboxContainer input:checked ~ .checkmark {
	background-color: var(--surface-accent) !important;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
	left: 8px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid 1px white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.tickerContainer {
	width: fit-content;
	padding-left: 30px;
}

.Symbol {
	transition: all 0.15s ease-in;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	font-size: 13px;
	font-weight: bold;
}

.SymbolDescription {
	line-height: 1.25;
	margin-top: var(--m-4);
	width: fit-content;

	font-size: 11px;
	font-weight: normal;
}

.lightSymbolDescription {
	color: var(--color-grey-4);
}

.darkSymbolDescription {
	color: var(--color-neutral-3);
}

.lightSymbol {
	color: var(--color-neutral-6);
}

.darkSymbol {
	color: var(--constant-light);

	&:hover {
		color: var(--color-neutral-3);
	}
}

.grabIndicatorContainer {
	margin-right: 15px;
	margin-top: 15px;
	color: #babec0;
	cursor: grab;
	display: inline-flex;

	// Edit mode - The icon on the right for dragging
	.grabIndicator {
		height: 18px;
		width: 8px;
	}
}

.Title {
	font-weight: 700;
	cursor: pointer;
}

.openModalTitle {
	@extend .Title;
	margin-left: auto;
	margin-right: auto;
	// margin-bottom: 5px;
}

.lightTitle {
	color: black;

	font-size: 13px;
	font-weight: bold;
}

.darkTitle {
	color: var(--color-neutral-1);

	font-size: 13px;
	font-weight: bold;
}

.disableBtn {
	pointer-events: none;
	color: var(--text-secondary);
	opacity: 0.3;
}

.editBarText {
	padding-left: 8px !important;
	cursor: pointer;
}

.editOptionIcons {
	height: 18px;
	width: 24px !important; // using important to override the font-awesome sizing
}

html[dir='rtl'] {
	.selectAll {
		margin-left: 10px;
	}

	.editOptionIcons {
		margin-left: 5px;
		transform: initial;
	}

	.editOptionContainer {
		text-align: center;

		&:nth-child(1) {
			padding-right: 0px;
		}

		&:nth-child(2) {
			padding-right: 0px;
		}
	}

	.tickerContainer {
		padding: 0px 30px 0px 0px;
	}

	.checkmark {
		right: 0;
		left: initial;
	}

	.grabIndicatorContainer {
		margin-left: 15px;
	}

	.textAll {
		padding: 0px 32px 0px 0px;
	}
}
