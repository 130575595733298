.tileContainer {
	flex-basis: 0;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	overflow: hidden;
	border-radius: 4px;
	height: 210px;
	padding: 35px 0 20px 0;
	color: var(--text-primary); /* //color-dark */
	cursor: pointer;
	border: 2px solid var(--border-primary);
	letter-spacing: -0.36px;
	background-color: var(--surface-secondary);

	.selectedText {
		color: var(--light);
	}

	.defaultText {
		color: var(--text-primary);
	}

	.selectorTitle {
		position: absolute;
		top: 0;
		font-size: var(--font-size-11);
	}

	.selectorIndicator {
		background-color: var(--surface-accent);
		width: 100%;
		height: 20px;
		top: 0;
		position: absolute;
	}

	.tierIcon {
		width: 50px;
		height: 50px;
	}

	.tierTitle {
		font-size: var(--font-size-15);
		margin-top: 10px;
		white-space: nowrap;
	}

	.tierName {
		font-weight: 600;
	}

	.tileSection {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.padded {
		padding: 9px 0;
	}

	.currencyPairs {
		font-size: var(--font-size-24);
		font-weight: bold;
		line-height: 24px;
	}

	.tradableCurrencyPairs {
		font-size: var(--font-size-13);
	}

	.price {
		font-weight: bold;
		padding-top: 3px;
	}
}

.selected {
	background-color: var(--surface-primary);
	border: 2px solid var(--border-accent); //border-topaz
}
