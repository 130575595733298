.container {
	display: flex;
	align-items: center;
	margin-right: 8px;
	height: 36px;
	gap: 1px;

	.accountPanelButton {
		cursor: pointer;
		height: 100%;
		display: flex;
		align-items: center;
		padding: 0 4px;
		background-color: var(--surface-secondary);
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
		transition: all ease 0.2s;

		&:hover {
			background-color: var(--surface-tertiary);
		}
	}
	.accountStatsWrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: var(--surface-secondary);
		gap: 12px;
		padding: 0 12px;

		.iconPlusEqual {
			font-size: 12px;
			color: var(--icon-tertiary);
		}
	}

	.buttonToggleIcon {
		height: 14px;
		color: var(--icon-primary);
	}
}

html[dir='rtl'] {
	.container {
		margin-left: 8px;
		margin-right: 0;
	}

	.accountPanelButton {
		border-radius: 0 4px 4px 0;
	}
}
