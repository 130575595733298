.textCenter {
	text-align: center;
}

.textBold {
	font-weight: bold;
}

.hidden {
	display: none !important;
}

.selectedSearchCategory {
	z-index: 2;
	margin-right: -10px;
	height: 100%;
	position: absolute;
	right: 2px;
	top: 0;
}

.hideSelectedSearchCategory {
	display: none;
}

.searchContainer {
	display: flex;
	align-items: center;
	height: 34px;
	background-color: var(--input-low);
	padding: 0.5rem;
	white-space: nowrap;
	border-radius: 8px;

	&:not(:only-child) {
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}
	&:hover {
		outline: 1px solid var(--border-primary);
	}
}

.menuPanel {
	width: 100%;
	color: var(--text-primary); /* //color-dark */
	background-color: var(--surface-primary);
	z-index: 10;
	border-style: none;
	border-width: 1px;
	border-top: none !important;
	border-color: var(--border-primary);
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
	border-radius: 20px;
}

.groupPanel {
	@extend .menuPanel;
	margin-top: 0px;
	margin-bottom: 0px;
	border-style: solid;
	box-shadow: none;
	z-index: 999;
	padding: 12px!important;
	background-color: var(--surface-secondary);
	border-radius: 20px 0 0 20px;
	max-width: 162px;

	.itemContainer {
		position: relative;
		top: 0%;
		left: 0%;
	}

	.itemContent {
		padding: 8px 12px;
		font-size: 13px;
		border-radius: 8px;
		border: 1px solid transparent;
		&.selected{
			border-color: var(--border-accent);
			font-weight: 700;
		}
		&.selected, &:hover {
			background-color: var(--surface-low);
		}
	}

	&:not(:only-child) {
		border-right-style: none;
		width: 40%;

		.itemContainer {
			.itemContent.selected {
				.selectionIndicator {
					background-color: var(--surface-accent);
					display: block;
					right: -2px;
				}
			}
		}
	}
}

.itemContainerHeader {
	@extend .itemContainer;
	pointer-events: none !important;
}

//The input for creating a watchlist
.itemContainerForm,
.itemContainerFooter {
	@extend .itemContainer;

	&:hover {
		background: var(--light) !important;
	}
}

.maxedContainer {
	@extend .itemContainer;
	opacity: 0.3 !important;
}

.maxedItemContent {
	padding: 10px;
	cursor: default !important;
}

.errorItemContent {
	@extend .itemContent;
	cursor: default !important;
}

.limitMessage {
	font-size: 11px;
	font-style: italic;
	cursor: default;
}

.instrumentPanel {
	@extend .menuPanel;
	z-index: 99;
	box-shadow: none;
	z-index: 5; // make sure the panel is below the selected category marker - z-index: 10
	padding: 12px 0;
	width: calc(100% - 162px);

	&.hide {
		display: none;
	}

	:global {
		.dx-datagrid {
			color: var(--text-primary); /* //color-dark */
			background-color: var(--surface-primary);
		}

		.dx-datagrid .dx-row,
		.dx-widget {
			font-size: inherit;
			font-weight: inherit;
			background-color: var(--surface-primary);
		}

		.dx-datagrid .dx-column-indicators .dx-sort.dx-sort {
			color: var(--text-primary); /* //color-dark */
		}

		// .dx-gridbase-container {
		// 	height: 439px !important;
		// }

		.dx-datagrid .dx-datagrid-rowsview {
			overflow: visible;
			color: var(--text-primary); /* //color-dark */
			border-top: 1px solid var(--border-primary);
			margin-top: -1px;

			.dx-datagrid-content.dx-datagrid-content-fixed {
				overflow: visible;
			}

			.dx-row {
				border: none;
			}

			//Instrument lines
			.dx-column-lines {
				& > td {
					color: inherit;
					background-color: var(--surface-primary);
					border-color: var(--border-primary); //border--gray-10);
					border-width: 1px;
					border-style: none;
					border-bottom-style: solid;
					overflow: hidden;
					padding: 12px 0 12px 11px;
					font-size: 13px;
				}

				&.dx-state-hover:not(.dx-selection) > td {
					background-color: var(--surface-low) !important;
					color: var(--text-primary) !important; /* //color-dark */
				}

				&.dx-data-row {
					cursor: pointer;
				}

				&.dx-selection > td {
					background-color: var(--surface-primary);

					&:local(.watchlistCell) {
						position: relative;
						top: 0%;
						left: 0%;
						padding: 0px;
						overflow: visible;

						:local(.content) {
							padding: 7px;
						}
					}

					&.dx-cell-focus-disabled:local(.watchlistCell),
					&:not(.dx-cell-focus-disabled):local(.watchlistCell .content.selected) {
						background-color: var(--surface-primary); /* background-color-gray-5 */
					}
				}
			}
		}

		.dx-datagrid .dx-datagrid-headers {
			border-bottom: none;
			border: none !important;
			color: var(--text-secondary);
			margin-bottom: -1px;

			.dx-header-row > td {
				border-width: 1px;
				padding: 12px 16px;

				.dx-sort-indicator {
					color: var(--text-primary); /* //color-dark */
					font-weight: bold;
				}
			}
		}

		.dx-datagrid-bottom-load-panel {
			background: var(--light) !important;
			border: none !important;
		}
	}
}

.groupPanel,
.instrumentPanel {
	.groupIcon {
		height: 18px;
		min-width: 18px;
		background-repeat: no-repeat;
		background-position-x: center;
		background-position-y: center;
		background-size: auto;
		display: inline-block;
		-webkit-background-size: auto;
		-moz-background-size: auto;
		-ms-background-size: auto;
		-o-background-size: auto;
		margin-right: 5px;
		cursor: pointer;
		background-size: contain;

		&.commodities {
			background-image: url('../../../../images/commodities.svg');
		}

		&.crypto {
			background-image: url('../../../../images/crypto.svg');
		}

		&.cryptosb {
			background-image: url('../../../../images/crypto.svg');
		}

		&.cfd {
			background-image: url('../../../../images/et-fs.svg');
		}

		&.etfs {
			background-image: url('../../../../images/et-fs.svg');
		}

		&.energy {
			background-image: url('../../../../images/energy.svg');
		}
		&.energysb {
			background-image: url('../../../../images/energy.svg');
		}

		&.forex {
			background-image: url('../../../../images/forex.svg');
		}
		&.forexsb {
			background-image: url('../../../../images/forex.svg');
		}
		&.indices {
			background-image: url('../../../../images/indices.svg');
		}
		&.indicessb {
			background-image: url('../../../../images/indices.svg');
		}
		&.metals {
			background-image: url('../../../../images/metals.svg');
		}
		&.metalssb {
			background-image: url('../../../../images/metals.svg');
		}
		&.stocks,
		&.shares {
			background-image: url('../../../../images/stocks.svg');
		}

		// Tier 1
		&.1 {
			background-image: url('../../../../images/tiers/tier1.svg');
		}

		// Tier 2
		&.2 {
			background-image: url('../../../../images/tiers/tier2.svg');
		}

		// Tier 3
		&.3 {
			background-image: url('../../../../images/tiers/tier3.svg');
		}
	}

	.watchlistIcon {
		@extend .groupIcon;
		margin: 0px;
	}

	.headerContainer {
		background-color: var(--surface-primary);
		padding: 10px 0px 10px 10px;
		font-weight: bold;

		&:first-child {
			border-bottom-style: solid;
		}
	}
}

.watchlistPanel {
	@extend .menuPanel;
	position: absolute !important;
	top: -1px;
	min-height: 100.5%;
	width: auto;
	min-width: 310px;
	border: solid 1px var(--border-primary) !important;
	list-style: none;
	padding-left: 0px;
	margin-top: 0px;
	margin-bottom: 0px;
	z-index: -1;
	left: 100%;
	display: flex;
	flex-flow: column nowrap;
    border-radius: 20px;
    overflow: hidden;

	.itemContainer {
		background-color: var(--surface-primary);
		box-shadow: none;

		&:hover {
			background-color: var(--surface-primary); /* background-color-light */
		}
	}

	.watchlistName {
		flex-grow: 1;
	}

	.itemContent {
		padding: 10px;
	}

	.watchlistName {
		padding: 10px;
		box-shadow: none;
	}

	.watchlistIcon {
		float: right;
		box-shadow: none;
	}

	.addIcon {
		margin-right: 5px;
		box-shadow: none;
	}

	.errorAddIcon {
		@extend .addIcon;
		opacity: 0.3;
		cursor: default;
	}
}

.itemContent {
	cursor: pointer;
	display: flex;
	align-items: center;
}

.createWatchlistContainer {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	box-shadow: var(--box-shadow-reversed);
}

.createWatchlist {
	label {
		display: block;
		color: var(--text-secondary);
		font-size: 11px;
		margin-top: 5px;
		margin-bottom: 5px;
		margin-right: 4px;
	}

	.inputContainer {
		color: var(--text-primary); /* //color-dark */
		padding: 0.25em;
		white-space: nowrap;
		border-style: solid;
		border-width: 1px;
		border-color: var(--border-primary); //border--gray-10);
		border-radius: 12px;

		input[type='text'] {
			background: transparent;
			outline-style: none;
			border-style: none;
			display: inline-block;
			width: 95%;
			height: 1.5rem;
			caret-color: var(--icon-accent);
		}
	}

	.errorInput {
		@extend .inputContainer;
		border: 1px solid var(--border-alert);
	}

	.actionButtonContainer {
		white-space: nowrap;
		text-align: center;

		button {
			width: 85px;
			height: 30px;
			margin: 0px;
			border-radius: 4px;
			border: solid 1px var(--border-invert);
			font-weight: bold;

			&:not(:last-child) {
				margin-right: 8px;
			}

			&:disabled {
				opacity: 0.3;
			}

			&.cancel {
				color: var(--text-primary); /* //color-dark */
				background-color: var(--surface-primary);

				&:active,
				&:hover {
					color: var(--light);
					background-color: var(--button-primary-hover);
				}
			}

			&.submit {
				color: var(--constant-light);
				background-color: var(--button-primary);
				border-color: var(--border-accent); //border-topaz

				&:not(:disabled) {
					&:active,
					&:hover {
						background-color: var(--button-primary-hover);
						border-color: var(--button-primary-hover);
					}
				}
			}
		}
	}
}

.createWatchlistFooterContainer {
	position: absolute;
	bottom: 0%;
	left: 0%;
	width: 100%;
	box-shadow: var(--box-shadow-reversed);
}

.createFooter {
	display: flex;
	align-items: center;
}

.textContainer {
	display: flex;
	flex-direction: column;
}

.createErrorMessage {
	opacity: 0.5;

	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.27px;
}

.errorCreate {
	opacity: 0.5;
}

.Popup {
	left: 2px !important;
	top: -2px !important;
	padding: 5px 9px !important;
}

.displayInput {
	display: flex;
	justify-content: space-between;
}

.inputExlpanation {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}

.errorMessage {
	font-size: var(--font-size-13);
	color: var(--text-alert);
}

.maxWatchlistError {
	@extend .errorMessage;
}

.matchText {
	font-weight: bold;
	background-color: var(--surface-accent);
	color: var(--constant-light);
}

.emptyState {
	align-items: center;
	text-align: center;
	height: 420px;
}

.searchIcon {
	color: var(--icon-tertiary);
	margin-top: 100px;
}

.emptyStateText {
	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.36px;
	text-align: center;
	margin-top: 30px;
	color: var(--text-secondary);
}

.emptyStateSearchString {
	color: var(--text-primary); /* //color-dark */
}

.watchlistCell {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
	div{
		display: flex;
	}
}

// .selected {
// 	background-color: var(--surface-primary); /* background-color-gray-5 */

// }

.addToWatchlistContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.watchlistIcon {
	font-size: 1.3em !important;
	cursor: pointer;
}

.existsInWatchlistIcon {
	@extend .watchlistIcon;
	color: var(--icon-success); /* color-mint */
}

.addToWatchlistIcon {
	@extend .watchlistIcon;
	color: var(--icon-secondary); /* //color-dark */
}

.disabledIcon {
	@extend .watchlistIcon;
	cursor: default !important;
}

.createWatchlistIcon {
	@extend .watchlistIcon;
	margin-right: 5px !important;
}

.instrumentContainer {
	display: flex;
	align-items: center;
	gap: 4px;
	cursor: pointer;
	overflow: hidden;

	span,
	img {
		width: 18px;
		height: 18px;
	}
}

html[dir='rtl'] {
	.watchlistPanel {
		right: 100%;
	}
	
	.instrumentFullNameTrim{
		width: calc(100% - 30px);
		text-align: right;
		direction: ltr;
	}

	.groupPanel{
		width: auto;
		max-width: 210px;
		border-radius: 0 20px 20px 0;
	}

	.badge {
		margin-right: 0;
		margin-left: 4px;
	}

	.selectedSearchCategory {
		transform: rotate(180deg) translateX(100%);
		right: initial;
		left: 0;
	}

	.actionButtonContainer {
		button {
			&:not(:last-child) {
				margin-left: 8px;
			}
		}
	}

	.groupIcon {
		margin-left: 5px;
		margin-right: 0px;
	}

	.watchlistCell {
		padding-right: 0px !important;
	}

	.errorMessage {
		right: 16px;
		left: initial;
	}

	.headerContainer {
		padding-right: 16px;
	}

	.categoryTitle {
		&:nth-child(1) {
			display: flex;
		}

		.groupIcon {
			width: 32px;
			background-position-x: right;
		}
	}

	.instrumentPanel {
		:global {
			.dx-datagrid-headers .dx-datagrid-table .dx-row > td,
			.dx-datagrid .dx-datagrid-rowsview .dx-column-lines > td {
				padding-right: 16px;
			}
		}
	}
}

.instrumentText{
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 100%;
}
.instrumentSymbol{
	font-size: 13px;
	line-height: 15.6px;
	font-weight: 700;
	color: var(--text-primary)
}
.instrumentFullName{
	font-size: 11px;
	line-height: 12px;
	font-weight: 400;
	color: var(--text-secondary);
}
.instrumentFullNameTrim{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 98%;
}
.tableRow{
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 8px 12px;
	&:hover{
		background-color: var(--surface-secondary);
	}
}
.watchlistAddRemoveIcon{
	height: auto;
	display: flex;
	padding: 4px;
	box-sizing: border-box;
	&:hover{
		background-color: var(--surface-tertiary);
		border-radius: 50%;
	}
}