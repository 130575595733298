.calendar {
	width: 100%;
	height: 100%;
}

.displayNone {
	display: none;
}

.loader {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.fallBackCalendar {
	width: 100%;
	height: 100%;
	padding: 10px 10px 0 10px;

	background-color: var(--constant-light);

	:global {
		#fxst_grid {
			overflow-y: auto;
			height: calc(100% - 70px);
			margin-top: 8px;
		}
		input {
			height: auto;
			padding: unset;
		}
	}
}

.calendarContainer {
	color: var(--text-primary) !important; /* //color-dark */
	width: 100%;
	height: 100%;
	padding-top: 10px;

	.calendar {
		height: 100%;
	}

	:global {
		.fxs_widget button.fxs_c_detail_action_button.fxs_c_detail_action_button_active,
		.fxs_widget a.fxs_c_detail_action_button.fxs_c_detail_action_button_active,
		.fxs_widget button.fxs_c_detail_action_button:hover,
		.fxs_widget a.fxs_c_detail_action_button:hover {
			color: var(--text-primary); /* //color-dark */
			background-color: var(--button-primay);
		}
		.fxs_widget {
			color: var(--text-primary); /* //color-dark */
			height: 100%;
		}

		.fxs_widget .fxs_c_desktop-filters .fxs_c_time-filters .fa-calendar-alt {
			color: var(--text-primary); /* //color-dark */
		}

		.fxs_widget .fxs_options-box .fxs_btn span {
			display: flex !important;
			flex-flow: row nowrap;
			justify-content: space-between;
		}

		.fxs_widget .fxs_selectable-wrapper .fxs_options-box .fxs_selectable-option svg path {
			fill: var(--icon-primary);
			color: var(--text-primary); /* //color-dark */
		}

		.fxs_widget a {
			color: var(--text-accent); //color-topaz
		}

		input {
			height: auto;
			padding: unset;
		}
		.fxs_widget .fxs_c_configuration_rollover_footer,
		.fxs_widget .fxs_c_configuration_rollover_trigger:hover .fxs_c_configuration_rollover {
			border: 1px solid var(--border-primay);
			background-color: var(--surface-primary);
			color: var(--text-primary); /* //color-dark */
		}

		.fxs_widget input[type='text'],
		.fxs_widget input[type='email'],
		.fxs_widget input[type='password'],
		.fxs_widget input[type='search'],
		.fxs_widget input[type='tel'],
		.fxs_widget textarea,
		.fxs_widget select {
			color: var(--text-primary); /* //color-dark */
			background-color: var(--surface-primary);
			border: 1px solid var(--border-primary);
		}
		.fxs_widget .fxs_c_row.fxs_c_now .fxs_c_item {
			border-bottom: 1px solid var(--border-primary);
		}
		.fxs_options-box {
			background-color: var(--surface-primary) !important;
		}
		.fxs_widget .fxs_cs_modal_close .fxs_icon {
			color: var(--text-primary); /* //color-dark */
		}

		.fxs_widget .fxs_c_time_filter_dropdown {
			background-color: var(--surface-primary);
		}

		.fxs_widget .fxs_c_advanced-filter {
			background-color: var(--surface-primary);
			color: var(--text-primary); /* //color-dark */
			border: 1px solid var(--border-primary);
			max-height: 700px;
			overflow-y: scroll;
		}
		.fxs_widget .fxs_notification .fxs_icon {
			fill: var(--icon-primary);
			color: var(--text-primary); /* //color-dark */
		}

		.fxs_widget .fxs_cs_view_item.fxs_cs_view_item_active .fxs_cs_view_description,
		.fxs_widget .fxs_cs_view_description {
			color: var(--text-primary); /* //color-dark */
		}

		.fxs_widget .fxs_cs_view_item {
			background-color: var(--surface-primary); /* background-color-gray-5 */
			border-color: var(--surface-primary);
		}

		.fxs_widget .fxs_cs_view_item.fxs_cs_view_item_active {
			border-color: var(--text-secondary);
			background-color: var(--surface-primary);
			color: var(--text-primary); /* //color-dark */
		}

		.fxs_widget .fxs_cs_modal_content .fxs_cs_detail.fxs_detail {
			background-color: var(--surface-primary);
			color: var(--text-primary); /* //color-dark */
		}

		.fxs_widget .fxs_headline_base_rules,
		.fxs_widget h1,
		.fxs_widget h2,
		.fxs_widget h3,
		.fxs_widget h4,
		.fxs_widget h5,
		.fxs_widget h6,
		.fxs_widget .fxs_headline_muted {
			color: var(--text-primary); /* //color-dark */
		}
		.fxs_widget .fxs_c_modal-content {
			background-color: var(--surface-primary);
			color: var(--text-primary); /* //color-dark */
		}
		.fxs_widget .fxs_btn.fxs_btn_cta {
			background-color: var(--button-primay);
			border-color: var(--border-accent);
		}
		.fxs_widget .fxs_c_datePicker_calendars_area .DayPicker-Caption select {
			background-color: var(--surface-primary);
			color: var(--text-primary); /* //color-dark */
			border: 1px solid var(--border-primary);
		}

		.fxs_widget .fxs_vertical_divider {
			background-color: var(--text-secondary);
		}

		.fxs_widget .fxs_c_DatePicker-Container {
			background-color: var(--surface-primary);
			border: 1px solid var(--border-primary);
		}
		.fxs_widget.fxs_c_ecocal p.fxs_c_datePicker_label,
		.fxs_widget.fxs_c_ecocal p.fxs_c_datePicker_selected_date {
			color: var(--text-primary); /* //color-dark */
		}

		.fxs_widget
			.fxs_c_datePicker_end_calendar_area
			.fxs_c_DatePicker
			.DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--outside),
		.fxs_widget
			.fxs_c_datePicker_begin_calendar_area
			.fxs_c_DatePicker
			.DayPicker-Day--end.DayPicker-Day--selected:not(.DayPicker-Day--outside) {
			border-color: var(--text-secondary) !important;
			color: var(--text-primary) !important; /* //color-dark */
			background-color: red !important;
		}

		.fxs_widget .fxs_c_DatePicker .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
			color: var(--text-primary) !important; /* //color-dark */
			color: var(--surface-primary); //backgro: var(-- gray- 10) !important;
		}
		.fxs_widget .fxs_c_datePicker_end_calendar_area .fxs_c_DatePicker .DayPicker-Day--end.DayPicker-Day--selected,
		.fxs_widget
			.fxs_c_datePicker_begin_calendar_area
			.fxs_c_DatePicker
			.DayPicker-Day--start.DayPicker-Day--selected:not(.DayPicker-Day--outside) {
			color: var(--text-primary) !important; /* //color-dark */
			background-color: var(--text-secondary) !important;
		}

		.fxs_widget .fxs_c_datepicker_button:hover {
			background-color: var(--gray-30);
		}

		.fxs_widget .fxs_c_datepicker_button.fxs_c_datepicker_button_active {
			color: var(--text-primary); /* //color-dark */
			color: var(--text-primary); //color--gray-10);
		}

		.fxs_widget .fxs_c_datepicker_button,
		.fxs_widget .fxs_c_datepicker_button:active,
		.fxs_widget .fxs_c_datepicker_button:focus {
			color: var(--text-primary); /* //color-dark */
		}

		.fxs_widget .fxs_c_datepicker_button_area,
		.fxs_widget .fxs_c_datepicker_calendars_wrapper {
			color: var(--text-primary); /* //color-dark */
			background-color: var(--surface-primary);
			border-right-color: var(--text-secondary);
		}

		.fxs_widget .fxs_c_timezone-container dt:hover {
			background-color: var(--surface-primary); /* background-color-gray-5 */
		}
		.fxs_widget .fxs_c_timezone-container {
			background-color: var(--surface-primary);
			border: 1px solid var(--gray-50);
			color: var(--text-primary); /* //color-dark */
		}
		.fxs_widget .fxs_btn.fxs_c_datepicker_trigger_button.fxs_c_datePicker_active,
		.fxs_widget .fxs_btn.fxs_c_datepicker_trigger_button {
			color: var(--text-primary); //color--gray-10);
			border: none;
		}

		.fxs_widget .fxs_btn_table_filter:hover:hover {
			background-color: var(--gray-30);
		}

		.fxs_widget .fxs_btn.fxs_btn_xsmall {
			color: var(--text-primary) !important; /* //color-dark */
			background-color: var(--surface-primary); /* background-color-gray-5 */
			border: none;
		}

		.fxs_widget .fxs_c_desktop-filters {
			padding: 0 20px 0 10px;
		}

		.fxs_widget.fxs_c_ecocal {
			min-width: 100%;
		}

		.fxs_widget .fxs_c_ecocal_data {
			border-bottom: none;
			padding: 0 10px;
			height: 95%;
			max-height: unset;
			overflow-y: scroll;
		}
		.fxs_widget table.fxs_c_table {
			border: none;
		}

		.fxs_widget .fxs_c_row:hover .fxs_c_item {
			border-bottom: 1px solid var(--gray-50);
		}

		.fxs_widget .fxs_c_row:hover,
		.fxs_widget .fxs_c_row.fxs_c_now:hover {
			background-color: var(--surface-primary); /* background-color-gray-5 */
			border: none;
			border-bottom: 1px solid var(--gray-50);
		}

		.fxs_widget .fxs_c_row.fxs_c_now,
		.fxs_widget .fxs_c_row.fxs_c_event_detail_shown {
			border: 1px 0 1px 0 solid var(--gray-50);
		}

		.fxs_widget .fxs_c_row .fxs_c_item,
		.fxs_widget .fxs_c_row.fxs_c_now.fxs_c_event_detail_shown .fxs_c_item,
		.fxs_widget .fxs_c_row.fxs_c_event_detail_shown .fxs_c_item,
		.fxs_widget .fxs_c_row.fxs_c_event_detail_shown:hover .fxs_c_item {
			border-bottom: 1px solid var(--gray-50);
		}
		.fxs_widget .fxs_c_row.fxs_c_now:hover .fxs_c_item.fxs_c_dashboard.fxs_c_detailActive,
		.fxs_widget .fxs_c_row .fxs_c_item.fxs_c_dashboard.fxs_c_detailActive {
			background-color: var(--surface-primary); /* background-color-gray-5 */
			border: 1px solid var(--gray-50);
			color: var(--text-primary); /* //color-dark */
		}
		.fxs_widget .fxs_c_header .fxs_c_item {
			background-color: var(--surface-primary); /* background-color-gray-5 */
			color: var(--text-primary); /* //color-dark */
		}

		.fxs_widget .fxs_c_detail {
			background-color: var(--surface-primary); /* background-color-gray-5 */
			color: var(--text-primary); /* //color-dark */
			border: none;
			border-bottom: 1px solid var(--gray-50);
		}

		.fxs_widget .fxs_c_header {
			background-color: var(--surface-primary); /* background-color-gray-5 */
		}
		.fxs_widget .fxs_c_row.fxs_c_now,
		.fxs_widget .fxs_c_row.fxs_c_event_detail_shown {
			color: var(--text-primary); //color--gray-10);
			color: var(--text-primary); /* //color-dark */
		}
		.fxs_widget .fxs_c_row {
			background-color: var(--surface-primary);
			color: var(--text-primary); /* //color-dark */
		}
		.fxs_widget .fxs_c_period {
			background-color: var(--surface-primary); /* background-color-gray-5 */
			color: var(--text-primary); /* //color-dark */
		}
	}
}
