.popup {
	margin-top: 12px !important;
	padding: 0 !important;
	box-shadow: var(--box-shadow-S) !important;
	background: var(--surface-primary) !important;
	width: 304px;
	border-radius: 20px !important;
	max-width: unset !important;
}

.userIconContainerClicked {
	background-color: var(--menu-primary);
	border-color: var(--border-primary);
}

.userIconContainer {
	width: 34px;
	height: 34px;
	border-radius: 50%;
	border: 2px solid transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	&:hover {
		color: var(--text-primary); //color--gray-10);
		background-color: var(--surface-tertiary);
		background-color: var(--gray-10);
	}

	img {
		width: 28px;
		height: 28px;
	}

	&:hover {
		background-color: var(--gray-10);
		border-color: var(--gray-10);
	}
}
