.playerModal {
	width: 70% !important;
	height: 40% !important;
	left: 50%;
	transform: translateX(-50%);
	aspect-ratio: 16/9;
}

.container {
	width: 100%;
	height: 100%;
	background-color: var(--surface-primary); /* background-color-gray-5 */
	overflow-y: auto;
	padding: 24px;

	.list {
		height: auto;
		display: grid;
		gap: 20px;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: fit-content();

		.videoArticle {
			background-color: var(--surface-low);
			align-self: flex-start;
			cursor: pointer;
			height: 100%;
			display: flex;
			flex-flow: column nowrap;
			padding: 8px;
			border-radius: 20px;

			&:hover {
				color: var(--text-primary); //color--gray-10);
				background: var(--surface-primary); //color--gray-10);
				box-shadow: var(--box-shadow-S);
			}

			.descriptionWrapper {
				direction: ltr;
				flex-grow: 1;
				display: flex;
				flex-flow: column nowrap;
				justify-content: space-between;
				padding: 8px;
				padding-top: 12px;
			}

			.image {
				width: 100%;
				height: auto;
				border-radius: 12px;
			}
		}
	}

	@media only screen and (min-width: 1520px) and (max-width: 1799px) {
		.list {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	@media only screen and (min-width: 1800px) and (max-width: 2400px) {
		.list {
			grid-template-columns: repeat(5, 1fr);
		}
	}

	@media only screen and (min-width: 2400px) {
		.list {
			max-width: 2400px;
			margin: 0 auto;
		}
	}

	.videoArticle {
		.headline {
			font-size: 15px;
			font-weight: bold;
			color: var(--text-primary); /* //color-dark */
			line-height: normal;
		}

		.date {
			font-size: 13px;
			color: var(--text-secondary);
		}

		.description {
			font-size: 13px;
			color: var(--gray-90);
			display: inline-block;
			max-height: 300px;
		}
	}

	.loadingTutorials {
		margin-top: 100px;
	}
}
