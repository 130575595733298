@import 'src/css/common/_vertically_center';

$side-spacing: 18px;

.modalHeader {
	padding: 7px;
	// backdrop-filter: blur(10px);
	box-shadow: var(--box-shadow);
	display: flex;
	flex-direction: column;
	z-index: 1;
}

.modalBody {
	padding: 0 !important;
}

.modalFooter {
	border-top: 1px solid var(--border-primary);
}

.orderQuantityLabels {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modalOrderQuantityLabels {
	@extend .orderQuantityLabels;
	gap: 12px;
	padding: 8px 0 12px;

	font-size: var(--fs-15);
}

.header {
	font-size: var(--fs-15);
	padding: 8px 12px;
	text-align: left;
	background-color: var(--surface-primary); /* background-color-light */
	color: var(--text-primary); /* //color-dark */
	display: flex;
	flex-direction: row;
	align-items: start;
	gap: 12px;
}

.modalButtons {
	display: flex;
	justify-content: space-between;
}

.closeModal {
	align-items: flex-end;
	cursor: pointer;
	transition: all 0.15s ease-in;
	margin-right: 10px;
	margin-top: 10px;
	position: absolute;
	display: flex;
	top: 0;
	justify-content: space-between;
	width: 36px;
	right: 0%;

	&:hover,
	&:active {
		filter: brightness(0.5);
	}
}

.closeModal {
	align-items: flex-end;
	cursor: pointer;
	transition: all 0.15s ease-in;
	margin-right: 10px;
	margin-top: 10px;
	position: absolute;
	display: flex;
	justify-content: space-between;
	width: 36px;
	right: 0%;

	&:hover,
	&:active {
		filter: brightness(0.5);
	}
}

.modalButton {
	max-height: 42px;
	max-width: 198px;
	padding: 12px 44px;
	font-size: var(--fs-15);

	&:hover,
	&:active {
		background: var(--dark);
		color: var(--light);
	}
}

.modalButtons {
	width: 100%;
}

.modalInfoPosition {
	display: flex;
	justify-content: center;
}

.ticketInfo {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	background-color: var(--surface-primary); /* background-color-light */
	flex-grow: 1;
	font-size: var(--fs-13);

	.orderQuantityLabels {
		padding: 8px 12px;
	}

	.closeContainer {
		background-color: var(--surface-secondary);
		flex-grow: 0;
		margin: 8px 12px;
		border-radius: 12px;

		.closeFields {
			display: flex;
			justify-content: space-between;
			margin: 0px 12px;
			padding: 12px 0;
			border-bottom: 1px solid var(--border-primary);

			&:last-of-type {
				border-bottom: none;
			}
		}
	}
}

.iconContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;

	.icon {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		height: 28px;
		width: 28px;
		font-size: var(--fs-18);

		&:hover {
			background-color: var(--surface-tertiary);
			border-radius: 50%;
		}
	}
}

.orderModalFooter {
	border-top: 1px solid var(--border-primary);
	backdrop-filter: blur(10px);
	background-color: var(--surface-primary);
}

.modalPartialButtons {
	display: flex;
	justify-content: space-between;
	padding: 9px 12px;

	& > * {
		flex-grow: 1;
		max-width: unset;

		&:first-child {
			margin-right: 4px;
		}
		&:last-child {
			margin-left: 4px;
		}
	}
}

.undockedPartialButtons {
	@extend .modalPartialButtons;
}

.closeTicket {
	float: right;
	margin-right: 10px;
	margin-top: 10px;
}

.amountInput {
	margin-top: -15px;
}

.ticketInfoModal {
	@extend .ticketInfo;

	height: auto;
	padding-top: 0 !important;
	padding-right: 0 !important;
	padding-left: 0 !important;

	& > div {
		margin-top: 0 !important;
	}

	.modalOrderQuantityLabels {
		margin-top: 0;
	}
}

.disableButton {
	pointer-events: none;
	color: var(--text-secondary);
	background: var(--gray-10);
}

.errorValidation {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	margin-right: 15px;
	color: var(--watermelon);
}

.invalidRemainder {
	display: inline-block;
	margin-left: 196px;
	color: #f74760;
}

.closeContainer {
	background-color: var(--surface-primary); /* background-color-gray-5 */
	// Set container height based on ticket modal height
	flex-grow: 1;
}

.closeContainerSummary {
	@extend .closeContainer;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	font-size: var(--fs-13);

	.closeContainer {
		background-color: var(--surface-secondary);
		flex-grow: 0;
		margin: 8px 12px;
		border-radius: 12px;

		.closeFields {
			display: flex;
			justify-content: space-between;
			margin: 0px 12px;
			padding: 12px 0;
			border-bottom: 1px solid var(--border-primary);
			&:last-of-type {
				border-bottom: none;
			}
		}
	}
}

.modalContainerSummary:not(:empty) {
	background-color: var(--surface-secondary);
	border-radius: 12px;

	.closeFields {
		border-bottom: 1px solid var(--border-primary);
		margin: 0px 12px;
		padding: 12px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:last-of-type {
			border-bottom: none;
		}
	}
}

.closeContainerModal {
	background-color: var(--surface-secondary);
	border-radius: 12px;
	font-size: var(--fs-13);

	.closeFields {
		padding-right: $side-spacing;
		padding-left: $side-spacing;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0px 12px;
		padding: 12px 0;
		border-bottom: 1px solid var(--border-primary);
		color: var(--text-primary); /* //color-dark */

		&:last-of-type {
			border-bottom: none;
		}
	}
}

.positive {
	color: var(--text-positive); /* color-positive*/
}

.negative {
	color: var(--text-negative); /* color-negative*/
}

.notificationCustomError {
	width: 100%;
	display: flex;
	background-color: var(--surface-primary);
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;

	.notificationCustomIcon {
		border-left: 8px solid var(--border-alert);
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
	}
}

.notificationTitle {
	display: flex;
	width: 300px;
	justify-content: space-between;
}

.toastTitle {
	margin-top: 5px;
	padding: 5px 0 0 9px;
	color: var(--text-primary); /* //color-dark */
}

.showIcon {
	margin-top: 10px;
	color: var(--text-primary); /* //color-dark */
}

.notificationCustomIcon {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	.fa {
		color: var(--light);
		font-size: 28px;
	}
}

.notificationMessage {
	color: var(--text-primary); /* //color-dark */
	padding-left: 10px;
	padding-bottom: 10px;
}

.closeButtonLocation {
	@include vertically-center;
}

// refers to all of the text inside of the modals
.text {
	color: var(--text-primary); /* //color-dark */
}

.label {
	width: 50%;
	color: var(--text-secondary);
}

.value {
	color: var(--text-primary); /* //color-dark */
}

.input {
	width: 50%;
}

html[dir='rtl'] {
	.modalPartialButtons {
		& > * {
			&:first-child {
				margin-left: 4px;
			}
			&:last-child {
				margin-right: 4px;
			}
		}
	}

	.errorValidation {
		margin: 0 0 0 15px;
	}

	.amountLabel {
		margin-right: 16px;
	}

	.amountInput {
		width: 180px;
	}
}
