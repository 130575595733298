@import 'src/css/common/_modal_header_simple.scss';
@import 'src/css/common/_modal_footer.scss';

.modalDialog {
	.modalHeader {
		@extend %modalHeaderSimple;

		text-align: center;
	}

	.modalBody {
		overflow: auto;
	}

	.modalFooter {
		@extend %modalFooter;

		& .columnButtons {
			display: flex;
			gap: 16px;
			flex-direction: column;
			width: 100%;
			align-items: center;

			& > button {
				flex: 1;
				max-width: none;
			}
		}
	}

	.subscriptionDetailsContent {
		max-height: 440px;
	}

	.detailsText {
		margin-top: 15px;
	}
}
