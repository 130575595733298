.iconContainerClicked {
	background-color: var(--surface-tertiary);
}

.iconContainer {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 34px;
	height: 34px;
	margin-right: 7px;
	border-radius: 50%;
	cursor: pointer;

	&:hover {
		background-color: var(--surface-tertiary);
	}

	.notificationIcon {
		color: var(--icon-primary);
		font-size: 24px;
	}

	.newMessageBadge {
		position: absolute;
		top: -5px;
		right: -8px;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background-color: var(--icon-alert);
		color: var(--constant-light);
		font-size: 11px;

		-webkit-user-select: none; /* Safari */
		-ms-user-select: none; /* IE 10 and IE 11 */
		user-select: none; /* Standard syntax */
	}
}

html[dir='rtl'] {
	.iconContainer {
		margin-right: 10px;
		margin-left: 7px;
	}

	variable .newMessageBadge {
		right: auto;
		left: -8px;
	}
}
