.wtrModal:global(.ui.small.modal > .header:not(.ui)),
.wtrModal:global(.ui.modal > .header) {
	padding: 32px 32px 0;
	background-color: var(--surface-primary);
	color: var(--text-primary); /* //color-dark */
	text-align: center;
	border-bottom: none;
	font-size: var(--font-size-20);
	font-weight: bold;
}

.wtrModal:global(.ui.modal) {
	height: auto;
	top: auto;
	left: auto;
	box-shadow: var(--box-shadow);
	border-radius: 20px;
}

.wtrModal:global(.ui.modal > .content) {
	background-color: var(--surface-primary);
	color: var(--text-primary); /* //color-dark */
	padding: 20px 32px;
}

.wtrModal:global(.ui.modal > .actions) {
	display: flex;
	justify-content: space-between;
	gap: 16px;
	border-top: none !important; // to overwrite the semantic ui lib
	padding: 0 32px 32px;
	background-color: var(--surface-primary); /* background-color-light */
}

html[dir='rtl'] {
	.wtrModal:global(.ui.modal > .actions) {
		button {
			&:first-of-type {
				margin-right: 0;
				margin-left: 12px;
			}
		}
	}

	.wtrModal:global(.ui.modal > .content) {
		text-align: right;
	}
}
