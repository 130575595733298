@import 'src/css/common/_btn_styled_as_link.scss';

.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 13px;
	background: var(--surface-secondary);
	padding: 20px;
	border-radius: 20px 20px 0 0;

	.account {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: column;
		text-align: center;

		.userIconContainer {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background-color: var(--surface-tertiary);

			.userIcon {
				font-size: 32px;
			}
		}

		.userIconContainerFullWidth {
			img {
				width: 100%;
				height: 100%;
			}
		}

		.fullName {
			margin-top: 12px;
			margin-bottom: -12px;
			font-size: var(--font-size-15);
			text-align: center;
			color: var(--text-primary);
			font-weight: bold;
		}
		.fullNameJP {
			margin-top: 12px;
			// margin-bottom: -12px;
			font-size: var(--font-size-15);
			text-align: center;
			color: var(--text-primary);
			font-weight: bold;
		}
		.email {
			margin-top: 12px;
			font-size: var(--font-size-11);
			text-align: center;
			color: var(--text-secondary);
		}

		.tierInfo {
			@extend .email;
			color: var(--text-primary);
			font-size: var(--font-size-12);

			.viewSubscriptionDetailsButton {
				@extend %buttonStyledAsLink;
				margin-left: 10px;
			}
		}
	}

	.switchButtonContainer {
		margin-top: 12px;
		width: 100%;
	}
}

.borderBottom {
	width: 100%;
	height: 3px;
	margin: 8px 0;
}

.options {
	border-radius: 0 0 20px 20px;
}
