.resultItem {
	background-color: var(--surface-primary);
	width: 100%;
	height: 50px;
	border-bottom: 1px solid var(--gray-10);
	cursor: pointer;
	padding: 0 6px;

	&:hover {
		background-color: var(--surface-primary); /* background-color-gray-5 */
	}
}

.resultItemContainer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 8px;
}

.instrumentIcon {
	width: 20px;
	height: 20px;
}

.textGroup {
	display: block;
	max-width: 86%;
	overflow-x: hidden;
	text-overflow: ellipsis;
	margin-left: 10px;
	margin-right: 10px;
	white-space: nowrap;
}

.resultCode {
	font-size: var(--fs-13);
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.36px;
	margin-bottom: 0;
}

.resultName {
	@extend .resultCode;
	font-weight: normal;
	color: var(--color-grey-4);
}

.leftContainer {
	display: flex;
	align-items: center;
	min-width: 384px;
}

.icon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	font-size: 1.3em;
}

.addToWatchlistIcon {
	@extend .icon;
	color: var(--text-primary); /* //color-dark */
}

.existsIcon {
	@extend .icon;
	color: var(--text-positive); /* color-mint */
}

.code {
	display: block;
	color: var(--text-primary); /* //color-dark */
}

.matchText {
	font-weight: bold;
	color: var(--text-primary); //color--gray-10);
}

.matchName {
	@extend .resultName;
	background-color: lightgray;
}

html[dir='rtl'] {
	.icon {
		margin-left: 16px;
		margin-right: 0px !important;
	}

	.instrumentIcon {
		margin: 0px 15px 0px 0px !important;
	}

	.watchlistIcon {
		margin: 0 0 0 16px;
	}
}
