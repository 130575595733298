.optionsWrapper {
	background-color: var(--surface-primary); /* background-color-light */
	display: flex;
	flex-direction: column;
	flex: 1;
	border-radius: 0 0 8px 8px;

	.option {
		height: 40px;
		min-height: 40px;
		flex: 1;
		padding: 8px 12px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		transition: all ease-in-out 0.2s;

		&:last-child {
			border-radius: 0 0 8px 8px;
		}

		&:hover {
			background-color: var(--surface-primary); /* background-color-gray-5 */
		}

		svg {
			font-size: 18px;
		}
	}

	.cancelOption {
		@extend .option;

		color: var(--watermelon);
	}
}
