.watchlistAccordion {
	font-size: var(--font-size-13);
	display: flex;
	align-items: center;
	height: 30px;
	background-color: var(--surface-primary);
	justify-content: space-between;
	padding-right: 12px;
}

.windowInstrument {
	margin-left: 4%;
	font-weight: 700;
	cursor: pointer;
}

.header {
	background-color: var(--surface-primary);
	width: 100%;
	position: relative; // needed for the box-shadow to work; othwerise it's hidden by the list
	border-bottom: 1px solid var(--border-primary);
}

.headerEdit {
	@extend .header;

	width: 100%;
	border-bottom: 1px solid var(--gray-10);
	box-shadow: none;
}

.marketActions {
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 11px;
	font-weight: normal;
}

.lightMarketActions {
	color: var(--color-neutral-5);
	white-space: nowrap;
}

.darkMarketActions {
	color: var(--color-neutral-3);
}

.lightIconTheme {
	color: var(--color-black);
	cursor: pointer;
}

.darkIconTheme {
	color: var(--constant-light);
	cursor: pointer;
}

.instrument {
	width: 112px;
	display: flex;
	align-items: center;
	height: 22px;

	font-size: var(--fs-11);
	line-height: 1.69;
	letter-spacing: -0.31px;
	font-weight: bold;
	color: var(--text-primary); /* //color-dark */
	padding-left: 12px;
	cursor: pointer;
}

.editInstrument {
	@extend .instrument;

	margin-left: 26px;
	cursor: default;
}

.delayedQuote {
	float: right;
	font-size: 11px;
	color: #939b9f;
	padding-bottom: 5px;
}

.watchlistHeader {
	color: var(--color-grey-4);

	&:nth-child(1) {
		width: 81px;
		margin: 0 4px 0 12px;
	}
}

.columnLabel {
	width: 50%;
	color: var(--text-secondary);
	border-left: 1px solid var(--border-primary);
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 8px;
}

.instrumentChevron {
	color: #8d9396;
	margin-left: 5px;
}

.instrumentHeader {
	@extend .instrument;
}

.columnLabelContainer {
	@extend .marketActions;
	min-width: 185px;
	max-width: 185px;
	height: 100%;
}

html[dir='rtl'] {
	.watchlistAccordion {
		padding-left: 12px;
		padding-right: 12px;
	}

	.instrumentHeader {
		padding-right: 12px;
		padding-left: 0;
	}
	.marketActions {
		justify-content: initial;
		width: 164px;
		margin-right: 0;
	}

	.columnLabel {
		&:first-child {
			width: 81px;
			margin: 0 6px 0 4px;
		}
	}
}
