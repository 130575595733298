//Accordion Styling Overrides
$container-padding-x: 0;
$accordion-button-color: black;
$accordion-button-bg: white;
$accordion-button-color: black;
$accordion-button-active-bg: white;
$accordion-button-active-color: black;
$accordion-button-focus-border-color: white;
$accordion-border-color: #babec0;
$accordion-border-width: 0.5px;
$accordion-button-focus-box-shadow: none;
// $accordion-bg: rgba(28, 40, 46, 0.05);
$accordion-button-padding-x: 10px;
$accordion-button-padding-y: 5px;
$accordion-padding-x: 0;
$accordion-padding-y: 0;
// $transition-collapse: none;
$transition-collapse: height 0.1s ease;

//Account Panel list styling ovverides
$list-group-item-padding-y: 0px;
$list-group-item-padding-x: 0px;
$list-group-color: var(--text-primary); /* //color-dark */
$list-group-bg: var(--light);
// $list-group-item-margin: 8px;

//Modal Overlay to support nested modals
$zindex-modal-backdrop: 998;
$zindex-modal: 998;
$modal-transition: none;
// $modal-fade-transform: none;
// $modal-show-transform: none;
// $modal-scale-transform: none;
$transition-fade: none;

//Progress Bar color overrides
$progress-height: 2.5em;
$progress-bar-bg: #21ba45;
$progress-bg: transparent;
$progress-border-radius: 0.55rem;

//ToolTip
$tooltip-max-width: 100%;

@import '~bootstrap/scss/bootstrap';
