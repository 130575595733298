%modalFooter {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	gap: 12px;
	padding: 16px 0 0;

	button {
		flex: 1;
		max-width: 50%;
		margin: 0;
	}
}
