.button {
	align-items: center;
	border-radius: var(--button-br);
	// color: var(--color-text-2);
	cursor: pointer;
	display: inline-flex;
	font-weight: bold;
	justify-content: center;
	outline-color: var(--color-primary-3);
	user-select: none;
	max-height: 30px;
	font-size: 16px;
	line-height: 1.2;
	white-space: nowrap;
}

.sm {
	width: 195px;
	max-width: 92px;
	max-height: 37px;
	padding: 10px 18px;
	font-size: var(--font-size-13);
}

.md {
	padding: 8px 20px;
	font-size: var(--font-size-15);
}

.lg {
	width: 160px;
	max-width: 210px;
	max-height: 30px;
	padding: 12px 22px;
	font-size: var(--font-size-15);
}

.fluid {
	@extend .lg;

	width: 100%;
	max-width: 100%;

	.buttonLabel {
		width: auto;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.primary {
	background: var(--button-primary);
	color: var(--constant-light);
	border: none;
	font-weight: 600;

	&:hover,
	&:active {
		background: var(--button-primary-hover);
	}
}

.danger {
	background: var(--button-alert);
	color: var(--constant-light);
	border: none;
	font-weight: 600;

	&:hover,
	&:active {
		background: var(--button-alert-hover);
	}
}

.outline {
	border: 1px solid var(--dark);
	color: var(--text-primary); /* //color-dark */
	background-color: var(--surface-primary); /* background-color-light */
	font-weight: normal;

	&:hover,
	&:active {
		border: 1px solid var(--dark);
		color: var(--light);
		background: var(--dark);
	}
}

// TODO: currently 'secondary' and 'outline' variants have the same style -> TBD with UI/UX team
.secondary {
	@extend .outline;
}

.disabled {
	background: var(--gray-10);
	color: var(--text-secondary);
	border: 1px solid var(--border-primary);

	&:hover,
	&:active {
		background: var(--gray-10);
		border: 1px solid var(--border-primary);
		color: var(--text-secondary);
	}
}

.outlineDisabled {
	background: transparent;
	border: 1px solid var(--color-neutral-7);
	color: var(--color-text-1);
	transition: none;

	&:hover,
	&:active {
		background: transparent;
		border: 1px solid var(--color-neutral-7);
		color: var(--color-text-1);
	}
}

.smallFont {
	font-size: 12px;
}

.fullWidth {
	width: 100%;
	max-width: none;
}
.fullHeight {
	max-height: 42px !important;
}

.buttonLabel {
	display: block;
	width: 100%;
	overflow-wrap: break-word;
}
