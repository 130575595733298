.mfaModal {
	.header {
		display: flex !important;
		flex-flow: row nowrap;
		justify-content: space-between;
		color: var(--text-primary); /* //color-dark */
		background-color: var(--surface-primary); /* background-color-light */
		padding: 12px;
		box-shadow: var(--box-shadow);
		z-index: 1;

		.title {
			font-weight: 500;
		}
	}

	.actions {
		z-index: 1;
		justify-content: center !important;
	}

	.content {
		position: relative;
		color: var(--text-primary); /* //color-dark */
		background-color: var(--surface-primary); /* background-color-light */
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		padding: 24px;

		.qrCode {
			border-radius: 4px;
			width: 120px;
			margin-bottom: 1rem;
		}

		.key {
			font-weight: 700;
			color: var(--text-accent); //color-topaz
		}

		.otpContainer {
			display: flex;
			flex-grow: 1;
			justify-content: space-between;
			width: 350px;
			margin: 24px 0 12px 0;

			input.otpInput {
				width: 48px !important;
				height: 48px;
				font-weight: 700;
				font-size: 24px;
				padding: 0;
				background-color: var(--surface-primary); /* background-color-gray-5 */
				color: var(--text-primary); /* //color-dark */
				border-color: var(--gray-30);

				appearance: textfield;
				-moz-appearance: textfield;

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					appearance: textfield;
					-webkit-appearance: none;
					margin: 0;
				}

				&:disabled {
					opacity: 0.45;
				}
			}
		}
	}
}
