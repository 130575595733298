@import 'src/css/common/_custom_scrollbar';
@import 'src/css/common/_vertically_center';

.dockedContainer {
	display: flex;
	height: 100%;
	flex-direction: column;
}

.undockedContainer {
	@extend %custom-scrollbar-element;
	max-height: 500px;
	overflow-y: auto;
	scrollbar-gutter: auto !important;
}

.closeButtonLocation {
	@include vertically-center;
	z-index: 20;
	left: -3px;
}

.lightmodalInfoPosition {
	display: flex;
	justify-content: center;
}

.fieldContainer {
	padding: 20px;
	background-color: var(--surface-primary) !important;
	border: 1px solid var(--border-secondary);
}

.lightconfirmFields {
	display: flex;
	justify-content: space-between;
	padding: 15px 5px 5px 5px;
	border-bottom: 1px solid #d3d3d3;

	font-size: var(--font-size-15);
}

.darkconfirmFields {
	@extend .lightconfirmFields;
	color: var(--text-secondary);
}

.lightmodalInfoPosition {
	display: flex;
	justify-content: center;
}

//Accordion Styling
.container {
	// full height - the panel header
	height: calc(100% - 121px);
	background-color: var(--surface-primary);
}

.containerChildWindow {
	height: calc(100vh - 130px);
}

.infoContainer {
	// background-color: rgba(28, 40, 46, 0.05);
	height: 100%;
}

.infoContainer > div > div > div > h2 > button {
	position: initial !important;
}

.elementContainer {
	overflow-y: auto;
	background-color: var(--surface-primary);
}

.marketInfoHeading {
	letter-spacing: -0.31px;
	font-size: 13px;
	padding: 0px;
	margin-left: 5px;
	background-color: var(--surface-primary);
}

.positionMsg {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 11px 13px;

	font-size: 13px;
	letter-spacing: -0.36px;
	height: 65px;
	background-color: var(--surface-primary); /* background-color-gray-5 */
}

.marginUsedMsg {
	display: flex;
	justify-content: space-between;
	margin-top: 5px;
}

.ordersMsg {
	@extend .positionMsg;
	height: 402px;
}

.emptyContainer {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	background-color: var(--surface-primary); /* background-color-gray-5 */
	justify-content: center;
	height: 100%;
	color: var(--text-secondary); //color--gray-10);
	padding: 12px;
	gap: 12px;

	
	.startTrading_Inactive,
	.startTrading_Active {
		color: var(--text-primary); /* //color-dark */
		text-decoration: none;
		cursor: pointer;
		max-width: none;
		display: flex;
		align-items: center;
		gap: 8px;
		border-radius: 8px;
		padding: 8px 11px;
		border: 1px solid var(--border-invert);
		font-size: var(--fs-15);
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: normal;

		svg {
			height: 18px;
		}
	}

	.startTrading_Active:hover,
	.startTrading_Inactive:hover {
		color: var(--text-primary); /* //color-dark */
		cursor: pointer;
		max-width: none;
	}

}

.chartIcon {
	color: var(--text-primary); //color--gray-10);
	margin-top: 20px;
	margin-bottom: 14px;
}

.chartMessage {
	font-size: var(--fs-13);
	letter-spacing: -0.31px;
	color: var(--text-secondary);
}

.icon {
	color: var(--icon-tertiary); //color--gray-200);
	font-size: 80px;
}

button[type='button'] {
	&:focus {
		z-index: 0;
	}

	&:hover {
		z-index: 0;
	}
}

.accordion {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	background-color: var(--surface-primary);
	color: var(--text-primary); /* //color-dark */

	.header {
		outline: 1px solid var(--border-primary);
		background-color: var(--surface-primary);
		padding: 8px 12px;
		cursor: pointer;
		flex-grow: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 13px;

		&:hover {
			background-color: var(--surface-tertiary);
		}

		&.active {
			font-weight: bold;
			z-index: 1;

			.icon {
				-webkit-transform: rotate(180deg);
				-ms-transform: rotate(180deg);
				transform: rotate(180deg);
			}
		}

		.icon {
			-webkit-transition: 0.4s;
			-o-transition: 0.4s;
			transition: 0.4s;
			margin: 0;
			color: var(--text-primary); /* //color-dark */
			font-size: 12px;
		}
	}

	.body {
		display: none;
		flex-grow: 0;
		background-color: var(--surface-primary); /* background-color-gray-5 */

		&.active {
			@extend %custom-scrollbar-element;
			display: block;
			flex-grow: 1;
			overflow-y: auto;
		}
	}
}

.formattedSwapInfo {
	.infoElementValue {
		direction: ltr;
	}
}

html[dir='rtl'] {
	.red,
	.pL,
	.date {
		direction: ltr;
	}

	.closeButtonLocation {
		left: unset;
		right: -3px;

		svg {
			transform: rotate(0deg)!important;
		}
	}
}
