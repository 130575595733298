.item {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 10px 0;

	&.alert {
		.icon {
			color: var(--watermelon);
			font-size: 14px;
		}
	}
	.iconContainer {
		display: flex;
		align-items: center;

		.icon {
			color: var(--text-primary); /* //color-dark */
			font-size: 16px;

			&.alert {
				color: var(--text-negative); /* color-negative*/
			}
		}
	}

	.content {
		padding-left: 10px;
	}
}

html[dir='rtl'] {
	.content {
		padding-left: 0px;
		padding-right: 10px;
	}
}
