.container {
	position: relative;
	display: flex;
	flex-direction: row;
	gap: 1px;
	width: 100%;
	height: 66px;
	justify-content: center;
	align-items: center;
	border-top: 1px solid var(--border-primary);
	border-bottom: 1px solid var(--border-primary);
	background: var(--border-primary);
	&:hover{
		border-top: 1px solid var(--surface-low);
		border-bottom: 1px solid var(--surface-low);
	}

	.tradeButtonContainer {
		height: 100%;
		width: 50%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: stretch;
		flex-grow: 1;
		padding: 8px;
		background-color: var(--surface-primary); /* background-color-light */
		overflow: hidden;

		&:hover {
			background-color: var(--surface-tertiary); /* background-color-gray-5 */
		}
	}

	.priceContainer {
		text-align: center;
		user-select: none;
	}

	.labelContainer {
		display: flex;
		flex-direction: row;
		height: 20px;

		font-size: 13px;
		color: var(--text-secondary);
		align-items: center;
		gap: 5px;
	}

	.sellLabelContainer {
		@extend .labelContainer;
		justify-content: flex-start;
	}

	.buyLabelContainer {
		@extend .labelContainer;
		justify-content: flex-end;
	}

	.upDownIcon {
		width: 9px;
		height: 12px;
	}

	.spread {
		position: absolute;
		top: 0px;
		height: 20px;
		width: fit-content;

		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.54;
		letter-spacing: normal;
		border-bottom: 1px solid var(--border-primary);
		border-left: 1px solid var(--border-primary);
		border-right: 1px solid var(--border-primary);
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;
		padding: 0px 8px;
		background-color: var(--surface-primary); /* background-color-light */
		color: var(--text-secondary);
	}
}
.containerSelected{
	@extend .container;
	border-top: 1px solid var(--surface-low);
	border-bottom: 1px solid var(--surface-low);
	background-color: var(--surface-low); /* background-color-light */

	.spread{
		border-bottom: 1px solid var(--surface-low);
		border-left: 1px solid var(--surface-low);
		border-right: 1px solid var(--surface-low);
	}

}

.tooltipTimestamp {
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: -0.3px;
	color: var(--text-secondary);
	margin: 5px;

	.tooltipTitle {
		display: flex;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: -0.36px;
		color: var(--text-primary); /* //color-dark */

		.closedMarketContainer {
			display: flex;
			align-content: center;
			margin-left: 5px;

			.closedMarket {
				margin-left: 5px;
				color: var(--watermelon);
			}
		}
	}
}
