.container {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	width: 100%;
	height: 100%;

	.leftPanel {
		display: flex;
		flex-flow: column nowrap;
		border-right: 3px solid var(--border-primary);
		height: 100%;
	}

	.rightPanel {
		display: flex;
		flex-flow: column nowrap;
		width: 100%;
		min-width: 1px;
		height: 100%;

		.rightTopPanel {
			display: flex;
			flex-flow: row nowrap;
			width: 100%;
			flex-grow: 1;
			height: 100%;
			min-height: 1px;
			position: relative;

			.positionsBottomPanel {
				position: absolute;
				left: 50%;
				z-index: 10;
			}

			.positionsBottomPanelOpen {
				transform: rotate(90deg);
				bottom: -20px;
			}

			.positionsBottomPanelClose {
				bottom: -12px;
				transform: rotate(90deg);

				svg {
					transform: rotate(0deg);
				}
			}

			.rightSideTrade {
				position: absolute;
				top: 50%;
				right: 45px;

				svg {
					transform: rotate(0deg);
				}
			}
		}

		
	}
}

.tradeLeftPanel {
	display: flex;
	align-items: center;
	margin-left: -8px;
}

.tradeLeftPanelOpen {
	transform: rotate(180deg);
	max-height: 32px;
	z-index: 100;
}

.tradeLeftPanelClose {
	transform: rotate(360deg);
	position: absolute;
}

.rightNav {
	width: 50px;
	border-left: 1px solid var(--border-primary);
}

.ordersPanel {
	height: 100%;
	border-top: 3px solid var(--border-primary);
}

.ticket {
	height: 100%;
	display: flex;
}

.showTicket {
	height: 100%;
	width: 100%;
	border-left: 3px solid var(--border-primary);
	display: flex;
	flex-flow: column nowrap;
	position: relative; // needed for the close button of modals
	z-index: 1;
}

.ticketPanel {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 1;
	background-color: white;
	overflow-x: hidden;
}

.flexLayout {
	position: absolute;
	height: 100%;
	left: 63px;
	top: 45px;
	width: 94.1%;

	@media screen and (max-width: 1280px) and (max-height: 610px) {
		width: 91%;
	}

	@media screen and (max-width: 1536px) and (min-width: 1300px) {
		width: 92.9%;
	}

	@media screen and (max-width: 3440px) and (max-height: 1440px) {
		width: 96.7%;
	}

	@media screen and (max-width: 1920px) and (max-height: 1200px) {
		width: 94.3%;
	}

	@media screen and (max-width: 3072px) and (min-height: 1920px) {
		width: 96%;
	}

	:global .flexlayout__tabset_header {
		background-color: #f7f7f7 !important;
	}
}

.updateFlexLayout {
	@extend .flexLayout;

	:global .flexlayout__tab_toolbar {
		margin-right: 410px;
	}
}

.flexLayoutNav {
	@extend .flexLayout;
	width: 54%;
}

// Themes
.background {
	background-color: var(--surface-primary);
}

.panelBackground {
	background-color: var(--surface-primary);
}

.chartPanel {
	overflow: hidden;
	display: flex;
	flex-grow: 1;
	z-index: 0;

	:global .flexlayout__tab {
		width: 707px !important;
	}
}

.Top {
	background-color: #f3f4f4;
}

.displayLoading {
	display: block;
}

.noLoading {
	display: none;
}

.popoutLayout {
	display: flex;
	justify-content: space-between;
	margin-right: 12px;
	margin-top: -4px;
}

.ticketGlow {
	box-shadow: 0 0 10px 1px var(--color-primary-1);
}

.watchlistPanel {
	height: 100%;
}

html[dir='rtl'] {
	.transition {
		margin: 0px 0px 0px 368px;
	}

	.accountPanel {
		right: 64px;
		left: initial;
	}

	.MainPanel {
		border-right: none;
		border-left: 1px solid var(--border-primary);
	}

	.rightNav {
		border-left: none;
		border-right: 1px solid var(--border-primary);
	}

	.showTicket {
		border-left: none;
		border-right: 3px solid var(--border-primary); /* border-right-gray-30 */
	}

	.transition {
		margin: 0px 0px 0px 368px;
	}

	.leftPanel {
		border-right: none;
		border-left: 3px solid var(--border-primary);
	}

	.rightSideTrade {
		left: 45px !important;
		right: unset !important;

		svg {
			transform: rotate(180deg)!important;
		}
	}

	.tradeLeftPanel {
		margin-left: unset;
		margin-right: -8px;
	}
	
	.tradeLeftPanelOpen {
		transform: rotate(360deg);
		z-index: 100;
	}
	
	.tradeLeftPanelClose {
		transform: rotate(180deg);
	}
}
