@import 'src/css/common/_modal_header_simple.scss';
@import 'src/css/common/_modal_footer.scss';

.modalDialog {
	max-width: 550px;
	color: var(--text-primary); /* //color-dark */

	.tierInfoContainer {
		padding: 8px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: var(--font-size-15);

		.tierInfo {
			font-weight: bold;
		}

		.viewDetails {
			color: var(--text-accent); //color-topaz
		}
	}

	.modalHeader {
		@extend %modalHeaderSimple;
	}

	.detailsModalHeader {
		align-self: center;
		font-size: var(--font-size-36);
		font-weight: bold;
		border-bottom: none;
		z-index: 1;
		width: 100%;
		justify-content: center;

		.wrapper {
			display: flex;
			align-items: center;
			width: 100%;
		}

		.title {
			flex-grow: 1;
			text-align: center;
		}

		.backButton {
			font-size: var(--font-size-24);
			color: var(--text-primary); /* //color-dark */
			cursor: pointer;
		}
	}

	.confirmModalHeader {
		@extend .detailsModalHeader;
		box-shadow: none;
	}

	.ctaText {
		padding: 20px 20px;
	}

	.modalBody {
		font-size: var(--font-size-13);
		padding: 0;

		&.scroll {
			height: 344px;
			overflow-y: scroll;
		}

		.instrumentGroupContainer {
			display: flex;
			flex-direction: column;
			line-height: 1.69;
			padding-bottom: 10px;

			&:last-child {
				padding-bottom: 0;
			}

			.groupName {
				font-weight: bold;
			}
		}
	}

	.confirmModalBody,
	.detailsModalBody {
		@extend .modalBody;
		padding: 20px;
	}

	.confirmModalBody {
		height: 274px;

		.confitmTextWrapper {
			display: flex;
			flex-direction: column;
		}

		.courseChangeTitle {
			font-weight: bold;
			margin-bottom: 20px;
		}
	}

	.tilesContainer {
		display: flex;
		gap: 4px;

		& > * {
			flex-grow: 1;
		}
	}

	.modalFooter {
		@extend %modalFooter;
	}
}
