td[role='gridcell'] {
	border-right-style: none !important;
	border-left-style: none !important;
}

.icon {
	color: var(--text-secondary);
}

.plusTheme {
	color: var(--text-primary); /* //color-dark */
	transition: all 0.15s ease-in;

	&:hover,
	&:active {
		color: white !important;
	}
}

.tpslCell {
	padding: 0 4px !important;
}

.closeAndMoreOptionsCell {
	padding: 0;
	width: 40px;
	div {
		display: flex;
		justify-content: space-around;
		width: 100%;
		svg {
			margin: 0 !important;
		}
	}
}

.plusButton {
	height: 25px;
	width: 100%;
	background-color: var(--surface-primary) !important;
	border: 1px solid var(--dark) !important;
	border-radius: 2px !important;
	transition: background 0.2s ease, color 0.2s ease;
	padding: 0 !important;
	margin: 0 !important;
	overflow: hidden;

	&:hover,
	&:active {
		border: 1px solid var(--dark) !important;
		background-color: var(--text-primary) !important; /* //color-dark */
		color: var(--light) !important;

		.iconContainer {
			.plusTheme,
			.profitValues {
				color: var(--light) !important;
			}
		}
	}
}

.HoverButton {
	width: 100px;
	height: 16px;
	border: 1px solid black !important;
	background-color: black !important;
	border-radius: 2px !important;
	transition: background 0.2s ease, color 0.2s ease;
}

.plusContainer {
	width: 100%;

	:global(tr.dx-data-row.dx-selection) &,
	:global(tr.dx-data-row.dx-state-hover) & {
		padding-top: 0;
		padding-bottom: 0;

		.plusButton {
			.iconContainer {
				.darkplusTheme {
					color: var(--constant-light);
				}
			}
		}
	}
}

.iconContainer {
	text-align: center;
	justify-content: center;
	align-items: center;
	vertical-align: center;
	width: 100%;
}

.treeListLayout {
	height: 100%;
	width: 100%;

	:global {
		.dx-treelist-borders > .dx-treelist-headers {
			border-top: none !important;
			border-left: none !important;
			border-right: none !important;
		}

		.dx-gridbase-container > .dx-treelist-rowsview {
			border-left: none !important;
			border-right: none !important;
		}
	}
}

:global {
	.dx-texteditor.dx-editor-outlined.dx-state-focused {
		border-color: var(--topaz-two) !important;
	}

	.dx-gridbase-container {
		height: 100% !important;
	}

	.dx-treelist-container .dx-treelist-rowsview .dx-freespace-row td.dx-pointer-events-none {
		border: none !important;
	}
	.dx-treelist-container .dx-treelist-content-fixed .dx-treelist-table .dx-col-fixed {
		background-color: initial;
	}
	.dx-treelist-nowrap .dx-header-row > td > .dx-treelist-text-content {
		min-width: 85%;
		background-color: transparent;
		margin: 0;
	}
	.dx-sort-up,
	.dx-sort-down {
		&::before {
			font-size: 8px;
		}
	}
	.dx-freespace-row {
		background-color: var(--surface-primary); /* background-color-gray-5 */
	}

	.dx-treelist-content .dx-treelist-table .dx-row > td {
		vertical-align: middle !important;
	}

	.dx-treelist-content .dx-treelist-table .dx-row > tr > td {
		vertical-align: middle !important;
	}

	.dx-treelist-column-chooser .dx-overlay-content {
		left: initial !important;
		transform: initial !important;
		right: 50px !important;
		top: initial !important;
		bottom: 30px;
	}

	html[dir='rtl'] {
		.dx-treelist-column-chooser .dx-overlay-content {
			left: 50px !important;
			right: initial !important;
		}
	}
}

.editTheme {
	color: var(--text-secondary);
	margin-top: 5px;
	margin-left: 19px;
}

.profitValues {
	color: var(--text-primary); /* //color-dark */
	font-size: 13px;
	font-weight: normal;
}

.highlightValues {
	color: white;
	font-size: 13px;
	font-weight: normal;
	margin-top: 5px;
	margin-left: 4px;
}

.lighteditClickTheme {
	color: black;
	margin-top: 5px;
	margin-left: 19px;
}

.darkeditClickTheme {
	color: black;
	margin-top: 5px;
	margin-left: 19px;
}

.Popup {
	top: 5px !important;
	left: 6px !important;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	width: 260px !important;
	height: auto;
	border-radius: 20px !important;
	box-shadow: var(--box-shadow-S);
	background-color: var(--menu-primary);
	cursor: default;
	padding: 12px !important;
}

.triggerContainer {
	cursor: pointer;
	width: 34px;
	display: flex;
	justify-content: center;

	:global(tr.dx-data-row.dx-selection) &,
	:global(tr.dx-data-row.dx-state-hover) & {
		.icon {
			color: var(--icon-alert);
		}

		.editTheme {
			color: var(--text-primary); /* //color-dark */
		}
	}
}

.PopupCog {
	max-width: 175px !important;
	top: -1px !important;
	left: 13px !important;
}

.PopupContainer {
	background-color: var(--menu-primary);
	height: 40px;
	display: flex;
	align-items: center;
	width: 100%;
	cursor: pointer;
	font-size: var(--font-size-13);
	color: var(--text-primary);
	border-radius: 8px;
	gap: 8px;
	padding: 0 8px;
	min-width: max-content !important;

	&:hover {
		background-color: var(--surface-tertiary); /* background-color-gray-5 */
	}

	.iconWrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		text-align: center;

		svg {
			font-size: 20px;
			align-self: center;
			color: var(--icon-primary);
		}
	}
}

.PopupContainerCog {
	@extend .PopupContainer;
	width: 187px;
	padding-left: 10px;
}

.PopupContainerCogOne {
	@extend .PopupContainerCog;
	padding-top: 0px;
}

.settingsContainer {
	float: right;
}

.lightsettingsPopup {
	position: absolute;
	z-index: 101;
	right: 90px;
	background-color: white;

	// top: 673px;
	// height: 115px;
	@media screen and (min-width: 1024px) and (max-height: 768px) {
		// top: 673px;
		// height: 115px;
	}

	width: 187px;
	border-radius: 5px;
	border: 1px solid var(--gray-50);
}

.darksettingsPopup {
	position: absolute;
	z-index: 101;
	right: 90px;
	top: 673px;
	height: 115px;
	width: 187px;
	border-radius: 5px;
	background-color: #121b1e;
	border: 1px solid lightgray;
}

.closeIcon {
	float: right;
	padding-right: 5px;
	margin-top: -5px;
}

.lightCheckbox {
	label {
		color: var(--color-neutral-6) !important;
	}
}

.darkCheckbox {
	label {
		color: var(--constant-light) !important;
	}
}

.columnChooser {
	font-size: var(--fs-15);
	cursor: pointer;
}

.cogContainer {
	width: 184px;
	padding-bottom: 5px;
	padding-top: 5px;
	font-size: 12px;
}

.greenCurrent {
	color: var(--text-positive);
}

.redCurrent {
	color: var(--text-negative);
}

// Highlight the cell when the sorting is active
.headerCell {
	color: var(--text-primary); /* //color-dark */
	background-color: var(--surface-primary);
	font-weight: bold;
}

.clearFilters {
	font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    padding: 8px 11px;
    border-radius: 8px;
    border: 1px solid var(--border-invert);
	color: var(--text-primary); /* //color-dark */
	cursor: pointer;
}

.startTrading_Inactive,
.startTrading_Active {
	color: var(--text-primary); /* //color-dark */
	text-decoration: none;
	cursor: pointer;
	max-width: none;
	display: flex;
	align-items: center;
	gap: 8px;
	border-radius: 8px;
	padding: 8px 11px;
	border: 1px solid var(--border-invert);
	font-size: var(--fs-15);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
	letter-spacing: normal;

	svg {
		height: 18px;
	}
}

.startTrading_Active:hover,
.startTrading_Inactive:hover {
	color: var(--text-primary); /* //color-dark */
	cursor: pointer;
	max-width: none;
}

.emptyStateMessage {
	color: var(--text-secondary);
	font-size: var(--fs-15);
}
a.emptyStateMessageLink {
	color: var(--text-primary);
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	text-decoration-line: underline;
	text-decoration-style: solid;
	text-decoration-skip-ink: none;
	text-decoration-thickness: auto;
	text-underline-offset: auto;
	text-underline-position: from-font;
	margin-left: 4px;
}

a.emptyStateMessageLink:hover {
	color: var(--text-primary);
	text-decoration-line: underline;
	cursor: pointer;
}

.emptyIcon {
	overflow: hidden !important;
	color: var(--icon-tertiary); //color --gray-300 / --gray-700
	font-size: 80px;

	@media screen and (max-height: 849px) {
		margin: 5px 0px;
	}
}

.searchIcon {
	color: rgb(190, 190, 190);
	margin: 20px 0px;
}

.noOrders {
	color: grey;
}

.noPositions {
	margin-top: 20px;
}

.positionDate {
	color: black;
	text-decoration: underline;
}

.emptyGridContainer {
	height: 100%;
	width: 100%;
	overflow-y: auto;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	cursor: initial;
	background-color: var(--surface-primary); /* background-color-gray-5 */
	gap: 12px;
	padding: 12px;
}

.headerCellInstrument {
	padding-left: 15px;
}

.modalRemoveOption > div {
	margin: auto;
	width: 370px;
	padding: 0;
}

.modalTopHeader {
	border: none;

	@media screen and (min-width: 1024px) and (max-height: 768px) {
		backdrop-filter: blur(0px);
	}

	@media screen and (min-width: 3440px) and (max-height: 1440px) {
		backdrop-filter: blur(0px);
	}
}

.modalTitle {
	width: 100%;
	text-align: center;
}

.modalItems {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.modifyButton {
	width: 100%;
	max-width: none;
	max-height: 40px;
	background-color: var(--surface-primary);
	color: var(--watermelon);
	border: 1px solid var(--watermelon);

	&:hover {
		background: var(--watermelon);
		color: white;
	}
}

.modifyFooter {
	padding: 12px;
	border: none;

	@media screen and (min-width: 1024px) and (max-height: 768px) {
		backdrop-filter: blur(0px);
	}

	@media screen and (min-width: 3440px) and (max-height: 1440px) {
		backdrop-filter: blur(0px);
	}
}

.removeMessage {
	font-size: 15px;
	letter-spacing: -0.41px;
	text-align: center;
}

.actionButtons {
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 12px;
}

//the div that wraps all of the dev extreme grid
.grid {
	height: 100%;
	width: 100%;

	:global {
		// Remove the table body padding
		.dx-scrollable-scrollbars-alwaysvisible.dx-scrollable-both
			> .dx-scrollable-wrapper
			> .dx-scrollable-container
			> .dx-scrollable-content {
			padding: 0;
		}

		// Remove the top border of the table body
		.dx-treelist-borders .dx-treelist-rowsview,
		.dx-treelist-headers + .dx-treelist-rowsview {
			border-top: none;
		}
		.dx-treelist-headers .dx-treelist-table .dx-row > td {
			padding: 4px;
		}
		// Searchbar
		.dx-texteditor.dx-editor-outlined {
			border: none;
		}

		.dx-texteditor.dx-editor-outlined {
			border-radius: 8px;
		}

		.dx-texteditor-container {
			border: 1px solid var(--border-secondary);
			border-radius: 8px;
		}

		.dx-texteditor-input-container {
			background-color: var(--surface-primary); /* background-color-light */
			caret-color: var(--icon-accent);
		}

		.dx-texteditor-input {
			&,
			&:focus,
			&:active {
				color: var(--text-primary); /* //color-dark */
			}
		}

		.dx-texteditor-buttons-container {
			background-color: var(--surface-primary); /* background-color-light */
		}

		//-----------------------------

		// Set the border color
		.dx-treelist-borders > .dx-treelist-filter-panel,
		.dx-treelist-borders > .dx-treelist-headers,
		.dx-treelist-borders > .dx-treelist-pager {
			border-color: var(--border-primary); //border--gray-10);
		}

		// Table container (not including the table header)
		.dx-gridbase-container > .dx-treelist-rowsview.dx-scrollable {
			margin: 0 !important;
			border-color: var(--border-primary); //border--gray-10);
			color: var(--text-primary); /* //color-dark */
			z-index: 1;
		}

		// Table header cells
		.dx-treelist-headers .dx-treelist-table .dx-row > td {
			color: var(--text-secondary);
			border-color: var(--gray-10) !important;
			background-color: var(--surface-primary); /* background-color-light */
			padding: 4px !important;

			&:last-child {
				border-right: none !important;
			}
		}

		// Table body - TR border
		.dx-treelist .dx-row-lines > td {
			border-color: var(--border-primary); //border--gray-10);
		}

		// Hover over the table header cell
		.dx-treelist-headers
			.dx-treelist-table
			.dx-row
			> td:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit):not(
				.dx-treelist-group-space
			) {
			background: var(--light) !important;
			color: var(--text-primary); /* //color-dark */
			font-weight: normal;
		}

		// Selected header cell arrow color
		.dx-treelist .dx-column-indicators .dx-sort.dx-sort {
			color: var(--text-primary); /* //color-dark */
		}

		// Table row hover
		.dx-treelist-table
			.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
			> td:not(.dx-focused) {
			background-color: var(--surface-primary); /* background-color-gray-5 */
			color: var(--text-primary); /* //color-dark */
		}

		// Table row selected
		.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td {
			background: var(--gray-10);
			color: var(--text-primary); /* //color-dark */
			border-bottom: none;
		}
	}
}

:global {
	// Column chooser

	// Background
	.dx-popup-wrapper .dx-state-focused.dx-overlay-content,
	.dx-treelist-column-chooser .dx-overlay-content {
		background-color: var(--surface-primary); /* background-color-gray-5 */
		box-shadow: var(--box-shadow-S);
		border: none;
	}

	.dx-treelist-column-chooser.dx-treelist-column-chooser-mode-drag .dx-popup-content {
		padding: 0px 20px;
	}

	// Header
	.dx-treelist-column-chooser .dx-overlay-content .dx-popup-title {
		color: var(--text-primary); /* //color-dark */
	}

	// X icon
	.dx-popup-title .dx-closebutton .dx-icon {
		color: var(--text-primary); /* //color-dark */
	}

	// Empty message
	.dx-treelist-column-chooser-list .dx-empty-message {
		color: var(--gray-30);
	}

	// Column
	.dx-datagrid-drop-highlight > td,
	.dx-treelist-drag-header,
	.dx-treelist-column-chooser .dx-overlay-content .dx-popup-content .dx-column-chooser-item {
		background-color: var(--surface-primary);
		color: var(--text-secondary);
		border: 1px solid var(--border-secondary);
	}
}
.sideTooltipWrapper {
	display: flex;

	.sideTooltipSquare {
		margin-right: 4px;
	}
	.sideTooltipSquareArabic {
		margin-left: 4px;
	}

	.sideTooltipSquareIcon {
		color: var(--text-secondary);
	}

	.tooltipWrapper {
		display: flex;
		flex-direction: row;
	}
}

html[dir='rtl'] {
	.plusTheme {
		margin-left: 0px;
	}

	.headerCellInstrument {
		padding-right: 15px;
		margin-left: 0;
	}

	:global .dx-treelist-headers .dx-treelist-table .dx-row > td:first-child {
		border-right: none !important;
	}
	.Popup {
		width: 300px !important;
	}
}
