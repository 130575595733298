.container {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 600;
	color: var(--text-primary);

	.backdrop {
		position: absolute;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0.5);
		top: 0;
		left: 0;
		z-index: 499;
	}
	.notificationModal {
		gap: 16px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		transition: all 0.3s ease-out;
		width: 360px;
		z-index: 500;
		background-color: var(--menu-primary);
		box-shadow: var(--box-shadow-S);
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		border-radius: var(--modal-dialog-content-br);
		padding: 16px;
		position: relative;

		.closeIconWrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			right: 8px;
			top: 8px;
			font-size: 24px;
			cursor: pointer;
			width: 36px;
			height: 36px;
			border-radius: 8px;
			svg {
				text-align: center;
			}
			&:hover {
				background-color: var(--surface-tertiary);
			}
		}

		.contentWrapper {
			display: flex;
			flex-direction: column;
			padding: 0 8px;

			gap: 12px;

			.header {
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 20px;
				font-weight: bold;
				text-align: center;
			}

			.content {
				font-size: var(--font-size-16);
				padding: 0 8px;
				text-align: center;
				color: var(--text-primary); /* //color-dark */
			}
		}
		.footer {
			display: flex;
			justify-content: space-between;
			// padding: 16px;
			gap: 12px;
		}
	}

	.dialog {
		background: var(--menu-primary);
	}

	.error {
		background: var(--color-danger);
	}

	.title {
		color: var(--text-primary);
		font-weight: 500;
	}
}

html[dir='rtl'] {
	.notificationModal {
		left: -30%;
	}
}
