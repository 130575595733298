.container {
	height: 32px;
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	user-select: none;
	gap: 4px;
	padding: 1px;
	border-radius: 8px;
	background-color: var(--input-primary);

	&.disabled {
		opacity: 0.5;
	}

	&.hasError {
		border: solid 1px var(--border-alert);
		input {
			color: var(--text-alert); /* color-negative*/
		}
	}

	.button {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		min-width: 32px;
		height: 32px;
		color: var(--text-primary); /* //color-dark */
		&:hover {
			background-color: var(--surface-tertiary);
			border-radius: 8px;
		}

		.icon {
			font-size: 18px;
		}
	}

	.input {
		width: 100%;
		font-size: 13px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		color: var(--text-primary); /* //color-dark */
		background-color: var(--surface-primary); /* background-color-light */
		border: none;
		outline: none;
	}
}
