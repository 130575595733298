@import 'src/css/common/_custom_scrollbar';

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.content {
	@extend %custom-scrollbar-element;
	width: 100%;
	padding: 20px 0;
	color: var(--text-primary); /* //color-dark */
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	.title {
		font-size: var(--font-size-24);
		font-weight: bold;
	}
}

.unlockIcon {
	font-size: 70px;
	margin: 20px 0;
	color: var(--gray-30);
}

.ctaButton {
	margin: 20px 0 0;
}
