@import 'src/css/common/_modal_header_simple.scss';

.modalDialog {
	.modalHeader {
		@extend %modalHeaderSimple;
	}

	.modalBody {
		overflow: auto;
		padding: 1px;
		max-height: calc(700px - 70px);
	}
}

.container {
	height: 100%;
	display: flex;
	flex-flow: column nowrap;

	.fieldContainer {
		background-color: var(--surface-primary); /* background-color-gray-5 */
		color: var(--text-primary); /* //color-dark */
		display: flex;
		flex-flow: column nowrap;
		flex-grow: 1;
		overflow-y: auto;
		overflow-y: overlay;
		padding: 12px;

		.confirmField {
			padding: 8px 0;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid var(--border-primary);

			&:first-child {
				padding-top: 0;
			}

			.label {
				color: var(--text-secondary);
			}
			.value {
				color: var(--text-primary);
			}
		}
	}

	.footer {
		display: flex;
		justify-content: space-between;
		padding: 8px 12px;
		gap: 8px;

		& > * {
			width: 100% !important;
			max-width: unset !important;
		}
	}
}
