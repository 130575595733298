.container {
	display: flex;
	flex-flow: row nowrap;
	color: var(--icon-primary);
	width: 100%;
	cursor: pointer;

	.iconContainer {
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
		flex-grow: 1;
		padding: 20px 0;

		&.itemDisabled {
			cursor: not-allowed !important;
		}

		&.itemLoading {
			cursor: wait !important;
		}

		.icon {
			font-size: 24px;

			&.iconDisabled {
				filter: brightness(0.5);
			}
			&.iconLoading {
				filter: brightness(0.5);
			}
		}

		span {
			padding-top: 4px;
			font-size: 11px;
		}
	}

	&:hover {
		background: var(--surface-low);

		.item {
			opacity: 1;
		}
	}

	&.active {
		.indicator {
			box-shadow: inset -3px 0 0 var(--border-accent), 1px 0 0 var(--border-accent);
		}
		background: var(--surface-low);

		.item {
			opacity: 1;

			span {
				font-weight: bold;
			}
		}
	}
}

html[dir='rtl'] {
	.container {
		&.active {
			.indicator {
				box-shadow: inset 3px 0 0 var(--border-accent), -1px 0 0 var(--border-accent);
			}
		}
	}
}
