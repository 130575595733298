.ttSessionInfoContainer {
	.ttSessionMarketStatusInfo {
		font-size: var(--font-size-13);

		// set text align to left for ltr and right for rtl
		text-align: left;

		// align text to right for rtl
		html[dir='rtl'] & {
			text-align: right;
		}

		&.open {
			color: var(--text-primary);
		}

		&.closed {
			color: var(--text-primary);
		}

		&.gray {
			color: var(--text-secondary);
		}
	}
}

.sessionIcon {
	margin-left: 5px;
	cursor: pointer;
	font-size: var(--font-size-13);
	color: var(--text-secondary);
}

html[dir='rtl'] {
	.sessionIcon {
		margin-right: 5px;
		margin-left: 0;
	}
}
