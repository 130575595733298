:global {
	// hackzorz - check if a div has a child which is an iframe with a title different from one of the other iframes we use... :D
	// works on all browsers since mid 2022; still no support in FF; our best chance though...
	html:not([dir='rtl'])
		body.auth
		> div:has(iframe:not([title='tp-iframe']):not([title='iframe']):not([title='No content'])) {
		// left: 80px !important;
		bottom: 15px !important;
	}
}

.liveChat {
	position: absolute;
	bottom: 24px;
	right: 24px;
}

.container {
	button {
		border: none;
	}

	padding: 0 0 10px 0;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column nowrap;

	a {
		cursor: pointer;
	}

	.zendesk {
		display: flex;
		justify-content: center;
		background: var(--surface-secondary);
		color: var(--icon-primary);
		border-radius: 0 0 20px 20px;
		width: 100%;

		.icon {
			font-size: 18px;
			margin: 8px 0;
		}
		.iconLight {
			color: var(--constant-light);
		}

		&:hover {
			background: var(--surface-tertiary) !important;
		}
	}

	.expandCollapse {
		@extend .zendesk;
		border-radius: 20px 20px 0 0;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		margin-bottom: 1px;

		.icon {
			margin: 4px 0;
			font-size: 14px;
		}

		&:hover {
			background: var(--surface-tertiary) !important;
		}
	}

	.channels {
		background: var(--surface-secondary);
		width: 100%;
		max-height: 0px;
		overflow: hidden;
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		transition: max-height 5s ease-out;

		&.isOpen {
			max-height: unset;
			margin-bottom: 1px;
			display: flex;
			align-items: center;
		}

		a {
			display: flex;
			align-items: center;
			color: var(--icon-primary);
			padding: 8px 0;
			&:hover {
				background: var(--surface-tertiary);
			}
			.icon {
				cursor: pointer;
				margin: 0 8px;

				font-size: 20px;
			}
		}
	}
}

.horizontal {
	button {
		border: none;
		background: none;
	}

	position: relative;

	.buttons {
		z-index: 0;
		height: 64px;
		padding: 16px 64px 16px 16px;
		border-radius: 37px;
		box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
		border: 1px solid var(--border-primary);
		background-color: var(--surface-primary);
		color: var(--text-primary); /* //color-dark */

		width: 0px;
		opacity: 0;
		overflow: hidden;
		display: flex;
		align-items: center;
		flex-flow: row nowrap;
		justify-content: space-between;
		transition: all 0.5s ease-out;

		&.isOpen {
			opacity: 1;
			width: 350px;
			transition: all 0.5s ease-out;
		}

		.zendesk {
			display: flex;
			align-items: center;

			.icon {
				margin: 0 12px;
			}
			.iconLight {
				color: var(--constant-light);
			}
		}

		a {
			display: flex;
			color: var(--button-primary);
		}

		.icon {
			cursor: pointer;
			font-size: 24px;
			margin: 0 8px;
			// height: auto;
		}
	}

	.triggerIcon {
		z-index: 999;
		position: absolute;
		top: 2px;
		right: 2px;
		cursor: pointer;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		color: white;
		font-size: 25px;
		background: var(--login-page-links);
		display: flex;

		justify-content: center;
		align-items: center;
		transition: 0.2s;

		&:hover {
			background: var(--login-page-links-reverse);
			transform: scale(1.2);
			transition: 0.2s;
		}
	}
}

html[dir='rtl'] {
	:global body:not(.auth) > div:has(iframe:not([title='tp-iframe']):not([title='iframe']):not([title='No content'])) {
		//left: 15px !important;
		bottom: 15px !important;
		right: unset !important;
	}

	:global body.auth > div:has(iframe:not([title='tp-iframe']):not([title='iframe']):not([title='No content'])) {
		left: unset !important;
		bottom: 15px !important;
		right: 80px !important;
	}

	.liveChat {
		position: fixed;
		bottom: 24px;
		right: unset;
		left: 24px;
	}

	.horizontal {
		.triggerIcon {
			right: unset;
			left: 0;
		}

		.zendesk {
			border-right: 1px solid var(--border-primary);
			border-left: none;
		}

		.buttons {
			padding: 16px 16px 16px 64px;
		}
	}
}
