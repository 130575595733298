.modalActivate > div {
	margin: auto;
	width: 370px !important;
	min-height: 194px;
	padding: 24px 20px;
	border-radius: 4px;
	background-color: var(--surface-primary);
}

.modalMessage {
	font-size: var(--font-size-13);
	text-align: left;
	letter-spacing: -0.41px;
	// margin-top: 25px;
	text-align: center;

	h1 {
		font-size: 20px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: var(--text-primary); /* //color-dark */
	}
	ul,
	p {
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: normal;
		text-align: left;
		color: var(--text-primary); /* //color-dark */
		margin: 0;
	}
}

.modalFooter {
	padding: 12px;
	border: none;
	box-shadow: var(--box-shadow-reversed);

	@media screen and (min-width: 1024px) and (max-height: 768px) {
		backdrop-filter: blur(0px);
	}

	@media screen and (min-width: 3440px) and (max-height: 1440px) {
		backdrop-filter: blur(0px);
	}
}

.modalActionButtons {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 1rem;
}

.modalButton {
	width: 45%;
}

.buttonsContainer {
	display: flex;
	flex-flow: row nowrap;
	gap: 12px;
	justify-content: space-between;
	width: 100%;
}
