.iconContainer {
  display: flex;
  height: 28px;
  width: 28px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  color: var(--dark);
  &:hover {
    background-color: var(--surface-tertiary);
    border-radius: 50%;
  }
}