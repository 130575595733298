@import 'src/css/common/_custom_scrollbar';

.container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.header {
	z-index: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px 12px;
	color: var(--text-primary); /* //color-dark */
	background-color: var(--surface-primary);
	border-bottom: 1px solid var(--border-primary);
	height: 40px;

	.actions {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}

.contents {
	//@extend %custom-scrollbar-element;
	width: 100%;
	height: calc(100% - 40px);
	display: flex;
	flex-flow: row nowrap;
}

.title {
	letter-spacing: -0.27px;
	font-weight: bold;
	font-size: var(--fs-13);
	height: 40px;
	display: flex;
	align-items: center;
}

.span {
	cursor: pointer;
	font-size: var(--fs-13);
	color: var(--text-secondary);
	letter-spacing: -0.27px;
	display: flex;
	align-items: center;
	gap: 4px;
}

// Individual Article Styling
.wrapper {
	height: 100%;
	width: 100%;
}

.halfWidth {
	display: flex;
	width: 100%;

	.width50 {
		width: 50%;
	}
}

.articleDetails {
	display: flex;
	flex-direction: column;
	gap: 12px;
	height: calc(100% - 40px);
	width: 50%;
	color: var(--text-primary); /* //color-dark */
	padding: 20px 20px 0;
}

.articleContainer {
	background-color: var(--surface-primary); /* background-color-light */
	border-bottom: 1px solid var(--border-primary);
	display: flex;
	flex-direction: row;
	width: 100%;
	margin: 0px;
	padding: 8px 12px;
	cursor: pointer;
	direction: ltr;

	&.selected,
	&:hover {
		background-color: var(--surface-low); /* background-color-gray-5 */
	}
	&.selected {
		border: 1px solid var(--border-accent);
	}
}

.articleText {
	display: flex;
	flex-direction: column;
	justify-content: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.headline {
	font-size: var(--fs-15);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--text-primary); /* //color-dark */
	direction: ltr; //TODO: DELETE ME
}

.time {
	color: var(--text-secondary);
	font-size: var(--fs-13);
}

//News Popup styling
.modalContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	direction: ltr;
}

.iconContainer {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.textContainer {
	@extend %custom-scrollbar-element;
	overflow-y: auto;
	height: 100%;
}

.cancel {
	margin-top: 20px;
	margin-right: 20px;
	font-size: 20px;
	cursor: pointer;
}

.heading {
	font-size: var(--fs-15);
	text-align: center;
	line-height: 22px;
	letter-spacing: -0.39px;
	font-weight: bold;
}

.bodyText {
	font-size: 16px;
	letter-spacing: -0.44px;
	line-height: 1.5;
}

.contentsAndDisclaimer {
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	height: 100%;
	width: 100%;

	.newsWrapper {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		align-items: center;
		width: 100%;
		height: 100%;
		@extend %custom-scrollbar-element;
	}

	.jpNewsWrapper {
		flex-grow: 1;
		flex-basis: 0;
		border-right: 1px solid var(--border-primary);
		position: relative;
		@extend %custom-scrollbar-element;

		.disclaimerMessageWrapper {
			flex-shrink: 1;
			position: fixed;
			bottom: 0;
			width: 50%;
			background-color: var(--surface-primary);
		}
	}
}

//Empty state
.emptyContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: var(--surface-primary); /* background-color-light */
	padding: 0 20px;
	gap: 12px;
	svg {
		color: var(--icon-tertiary);
	}

	.emptyButtonContainer {
		display: flex;

		.clearFiltersButton {
			border-radius: 8px;
			border: 1px solid var(--border-invert);
			padding: 8px 12px;
			font-size: var(--fs-15);
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}

		.clearFiltersButton:hover {
			cursor: pointer;
		}
	}
}

.emptyIcon {
	font-size: 100px;
	color: var(--text-primary); //color--gray-10);
}

.message {
	font-size: 15px;
	color: var(--text-secondary);
	text-align: center;
}

.dropdown {
	background-color: transparent;
	color: var(--text-primary) !important; /* //color-dark */

	:global .menu {
		left: unset !important;
		right: 0;
	}

	i {
		color: var(--text-primary); /* //color-dark */
	}
}

:global {
	.ui.dropdown .menu {
		padding: 12px;
		background-color: var(--menu-primary);
		max-height: 224px;
		z-index: 999999;
		border-radius: 20px !important;
		box-shadow: var(--box-shadow-S) !important;
	}

	.ui.dropdown .menu > .item {
		width: 100%;
		padding: 7px 0 7px 10px;
		border-radius: 8px; /* background-color-gray-5 */

		&:hover {
			background-color: var(--surface-tertiary);
		}
	}
}

html[dir='rtl'] {
	.articleDetails {
		direction: rtl;
	}

	.bodyText {
		direction: ltr;
	}

	.articleText {
		margin-right: 14px;
	}

	.dropdown {
		:global .menu {
			right: unset !important;
			left: 0 !important;
		}
	}

	.wrapper {
		border-right: none;
	}
}
