@import 'src/css/common/_custom_scrollbar';

.marketPageTableWrapper,
.positionPageTableWrapper {
	width: 100%;
	overflow: hidden;
	height: auto;

	.tableContainerVirtualStyles {
		overflow: auto;

		position: relative;
		display: flex;
		height: calc(100vh - 80px);
	}

	.tableContainerDetach {
		height: 100%;
	}

	.table {
		position: relative;
		flex: 1;

		.tr {
			display: flex;
		}

		tr,
		.tr {
			height: 30px;
			user-select: none;
			cursor: pointer;
		}

		th,
		.th,
		td,
		.td {
			padding: 0.25rem;
		}

		th,
		.th {
			padding: 2px 4px;
			position: relative;
			text-align: center;
			height: 30px;
		}

		th {
			font-weight: normal;
		}

		td {
			display: inline-block;
		}

		.td {
			display: inline-block;
		}

		.resizer {
			position: absolute;
			top: 0;
			height: 100%;
			width: 5px;
			background: var(--surface-primary);
			cursor: col-resize;
			user-select: none;
			touch-action: none;
			opacity: 0;

			&:hover {
				opacity: 1;
			}
		}

		.ltr {
			right: 0;
		}

		.rtl {
			left: 0;
		}

		.icon {
			color: var(--text-secondary);
		}

		.iconTheme {
			color: var(--text-secondary);
		}

		.triggerContainer {
			cursor: pointer;
			display: flex;
			justify-content: center;
		}

		.isResizing {
			background: var(--border-accent);
			opacity: 1;
		}

		.stickyHeader {
			position: sticky;
			top: 0;
			z-index: 0;
			border-bottom: 1px solid var(--border-primary);
			font-weight: normal;
		}

		tbody {
			overflow-y: auto !important;
		}

		.tableHeader {
			position: sticky;
			border-right: 1px solid var(--border-primary) !important;
			background-color: var(--surface-primary);
			color: var(--text-secondary);
			vertical-align: middle;
			height: inherit;
			padding: 8px;
			line-height: 1.2;
			font-size: 13px;
			overflow: hidden;
			text-overflow: ellipsis;
			cursor: pointer;

			&:last-child {
				text-align: center;
			}
		}

		.tableRow {
			background-color: var(--surface-primary);
			color: var(--text-primary); /* //color-dark */
			border-bottom: 1px solid var(--border-primary);

			&:hover {
				background-color: var(--surface-low); /* background-color-gray-5 */

				.icon {
					color: var(--icon-alert);
				}

				.iconTheme {
					color: var(--icon-primary); /* //color-dark */
				}

				.editTheme {
					color: var(--icon-primary); /* //color-dark */
				}
			}
		}

		.tableRowTransparent {
			background: var(--surface-low) !important;
			outline: 1px solid var(--border-accent) !important;
			z-index: 1;
			border-left: 1px solid var(--border-accent);
			border-right: 1px solid var(--border-accent);
			.icon {
				color: var(--icon-alert);
			}

			.iconTheme {
				color: var(--icon-primary); /* //color-dark */
			}
		}

		.tableData {
			height: inherit;
			padding: 8px;
			line-height: 1.2;
			font-size: 13px;
		}

		.verticalCenterAlign {
			display: flex;
			align-items: center;
			height: 100%;
		}

		.horizontalCenterAlign {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}

		.alignRight {
			display: flex;
			align-items: center;
			justify-content: end;
			height: 100%;
		}

		.symbolCell {
			display: flex;
			align-items: center;
			height: 100%;
			.symbolText {
				margin-left: 4px;
			}
		}
	}
}

.marketPageTableWrapper {
	position: relative;
}

.headerWrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	span {
		z-index: 2;
		position: relative;
	}

	.dragColumnButton {
		cursor: grab;
		position: absolute;
		width: 100%;
		height: 20px;
		z-index: 1;
		svg {
			display: none;
		}
	}
}

.positionPageTableWrapper {
	.headerWrapper {
		font-weight: normal;
	}
	.tableContainerVirtualStyles {
		overflow: inherit;
		overflow-y: scroll;
	}
}

.loadingContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.greenCurrent {
	color: var(--text-positive);
}

.redCurrent {
	color: var(--text-negative);
}

html[dir='rtl'] {
	.symbolText {
		margin-right: 4px;
		margin-left: 0;
	}
}
//NEW STYLES END

//OLD STYLES
@import 'src/css/common/_input';

.marketsList {
	display: flex;
	flex-flow: column nowrap;
}

.header {
	height: 40px;
	background-color: var(--surface-primary); /* background-color-gray-5 */
	color: var(--text-primary); /* //color-dark */
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	position: sticky;
	top: 0;
	z-index: 999999;
	user-select: none;
	border-bottom: 1px solid var(--border-primary);
	padding: 0 12px;

	.label {
		font-weight: bold;
		font-size: var(--fs-13);
	}

	.filterOptionGroupWrapper {
		display: flex;
		gap: 20px;

		.filterOptionGroup {
			display: flex;
			flex-flow: row nowrap;
			gap: 4px;

			.filterLabel {
				color: var(--text-secondary);
				font-size: var(--fs-13);
			}

			.filterControl {
				font-size: var(--fs-13);
				font-weight: bold;
				cursor: pointer;
			}
		}
	}
}
.dataGrid {
	height: 100%;

	:global .dx-freespace-row,
	:global .dx-datagrid-content {
		background-color: #f3f4f4;
	}

	:global .dx-datagrid-table {
		background-color: #fff;
	}

	:global .dx-rtl.dx-scrollable .dx-scrollable-container {
		direction: rtl !important;
	}

	:global .dx-scrollable-wrapper {
		background-color: var(--surface-primary);
	}
}

.DataGrid > div {
	cursor: pointer;
}

.content {
	display: flex;
	align-items: center;
}

.badgeWrapper {
	min-height: 22px;
	min-width: 22px;
}

.contentSymbol {
	margin-left: 4px;
}

.button {
	width: 100%;
	color: var(--constant-light);
	border-radius: 5px;
	float: right;
	cursor: pointer;
	padding: 4px 0;
	text-align: center;
}

.typeButton {
	@extend .button;
	background: var(--button-negative-secondary); /* background-color-positive*/
	color: var(--button-negative);
	&:hover {
		background: var(--button-negative-hover); /* background-color-positive*/
		color: var(--constant-light);
	}
}

.typeValue {
	font-size: 13px;
}

.testButton {
	@extend .button;
	background: var(--button-positive-secondary); /* background-color-positive*/
	color: var(--button-positive);

	&:hover {
		background: var(--button-positive-hover); /* background-color-positive*/
		color: var(--constant-light);
	}
}

.testValue {
	font-size: 13px;
}

.watchlistsListContainer {
	background-color: var(--surface-primary); /* background-color-gray-5 */
	height: 230px;
	width: 300px;
	border-radius: 2px;
	display: flex;
	flex-direction: column;
}

.titleContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 20px;
	padding: 20px;
	box-shadow: var(--box-shadow);
	background-color: var(--surface-primary);

	font-size: 13px;
	font-weight: bold;
}

.watchlistContainer {
	display: flex;
	flex-direction: column;
	max-height: 151px;
	margin-right: 4px;
	overflow-y: auto;
	scroll-behavior: smooth;
	height: 100%;
}

.watchlistContainer::-webkit-scrollbar-track-piece {
	background-color: white;
}

.watchlistContainer::-webkit-scrollbar {
	width: 4px;
}

.createWatchlist {
	display: flex;
	width: 100%;
	height: 40px;
	align-items: center;
	box-shadow: var(--box-shadow-reversed);
}

.watchlistContainer {
	display: flex;
	flex-direction: column;
	max-height: 151px;
	margin-right: 4px;
	// margin: auto;
	overflow-y: auto;
	scroll-behavior: smooth;
	// cursor: pointer;
	height: 100%;
}

.watchlistContainer::-webkit-scrollbar-track-piece {
	background-color: white;
}

.watchlistContainer::-webkit-scrollbar {
	width: 4px;
}

.createWatchlist {
	display: flex;
	width: 100%;
	height: 35px;
	align-items: center;
}

.icon {
	font-size: 1.3em;
	cursor: pointer;
	margin-left: 10px;
	color: var(--text-primary); /* //color-dark */
}

.existsInWatchlistIcon {
	color: var(--icon-accent); /* color-mint */
}

.existsInWatchlistIcon,
.addToWatchlistIcon,
.addIcon {
	@extend .icon;

	path {
		pointer-events: none;
	}
}

.errorAddIcon {
	@extend .icon;

	cursor: default !important;
	opacity: 0.3;
}

.create {
	margin-left: 4px;
	cursor: pointer;
}

.errorCreateText {
	@extend .create;
	opacity: 0.5;
	cursor: text;
}

.textContainer {
	display: flex;
	flex-direction: column;
}

.createErrorMessage {
	opacity: 0.5;
	font-size: var(--fs-13);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.27px;
	margin-left: 4px;
}

.createWLContainer {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: center;
	width: 300px;
	height: 230px;
	// box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
	background-color: var(--surface-primary);
}

.createWatchlistItems {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 20px;
	padding: 20px;
	border-bottom: 1px solid var(--border-primary);
	background-color: var(--surface-primary);
	font-size: 13px;
	font-weight: bold;
}

.inputContainer {
	width: 100%;
	padding: 0px 20px;
	background-color: var(--surface-primary);
}

.inputTitle {
	width: 100%;
	height: 13px;
	margin: 8px auto;

	font-size: var(--fs-13);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: var(--text-secondary);
}

.inputContainerText {
	text-align: right;
	width: 100%;
	height: 16px;
	margin: 0 0px 4.3px 0px;
	padding-right: 10px;

	font-size: 13px;
	font-weight: normal;
	text-align: right;
	color: #babec0;
}

.popupFooter {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 50px;
	padding: 11px 14px 9px;
	border-radius: 3px;
	box-shadow: var(--box-shadow-reversed);
	background-color: var(--surface-primary);

	button {
		width: 45%;
	}
}

.lightticketButton {
	background: var(--constant-light);
	color: black;
	border: 1px solid var(--color-black);
	max-height: 37px;
	max-width: 92px;
	padding: 10px 43px 11px;
	transition: all 0.15s ease-in;
	width: 195px;

	&:hover,
	&:active {
		border: 1px solid var(--color-neutral-5);
		background: var(--color-neutral-5);
		color: var(--constant-light);
	}
}

.darkticketButton {
	background: var(--color-neutral-5);
	border: 1px solid var(--constant-light);
	color: var(--constant-light);
	max-height: 37px;
	max-width: 92px;
	padding: 10px 43px 11px;
	transition: all 0.15s ease-in;
	width: 195px;

	&:hover,
	&:active {
		border: 1px solid var(--constant-light);
		background: var(--constant-light);
		color: var(--color-black);
		outline-color: var(--color-primary-1);
		outline-style: none !important;
		outline-width: 0px !important;
	}
}

.messageRow {
	width: 100%;
	margin-top: 2px;
	display: flex;
	justify-content: flex-end;
}

.inputCount {
	font-size: 13px;
	color: var(--color-grey-6);
	margin-left: 150px;
}

.disableButton {
	pointer-events: none;
	color: #ccc;
}

.lightCostInput {
	background-color: #f3f4f4 !important;
	width: 100%;
	height: 30px;
	border-radius: 12px;
	box-shadow: inset 2px 2px 4px 0 #d0dbe7, inset -2px -2px 4px 0 rgba(250, 251, 255, 0.75);
	border: solid 1px var(--border-accent);
}

.lightCostInput > input {
	color: var(--color-black);
	background-color: #f3f4f4 !important;
}

.errorInput {
	@extend .lightCostInput;
	border: solid 1px #f74760;
}

.lightErrorInput {
	@extend .lightCostInput;
	border: 1px solid #f74760;
}

.lightErrorInput > input {
	color: var(--color-black);
	background-color: #f3f4f4 !important;
}

.errorMessage {
	font-size: 12px;
	color: #f74760;

	position: absolute;
	left: 24px;
	width: 76%;
}

.costInput {
	@extend %cost-input;

	width: 100%;
	max-width: none;
	margin: 0 0 12px;
}

.btnCancel {
	background-color: white;
	color: black;

	border-radius: 4px;
	margin-right: 10px;
	border: 1px solid black;
	padding: 6px 17px 5px 18px;
	font-size: 16px;
	font-weight: 500;
	width: 45%;

	&:hover,
	&:active {
		border: 1px solid var(--color-neutral-5);
		background: var(--color-neutral-5);
		color: var(--constant-light) !important;
	}
}

.btnSave {
	color: white;

	padding: 6px 24px 5px;
	font-size: 16px;
	font-weight: 500;
	width: 45%;
}

.resizedCancelBtn {
	@extend .btnCancel;
	font-size: 12px;
}

.resizedSaveBtn {
	@extend .btnSave;
	font-size: 12px;
}

.hidden {
	display: none;
}

.Popup {
	height: auto;
	z-index: 99 !important;
	box-shadow: var(--box-shadow-S);
	background-color: var(--white);
	max-width: 300px !important;
	width: 300px;
	margin-right: -12px !important;
}

.filterListWrapper {
	padding: 12px;
	background-color: var(--menu-primary);
	max-height: 224px;
	z-index: 999999;
	border-radius: 20px;
	box-shadow: var(--box-shadow-S);
}
.filterList {
	overflow-y: auto;
	max-height: 200px;
	@extend %custom-scrollbar-element;
	scrollbar-gutter: auto;
}

.listContainer {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 8px 12px;
	height: 40px;
	color: var(--text-primary);
	border-radius: 8px; /* background-color-gray-5 */
	&:hover {
		background-color: var(--surface-low); /* background-color-gray-5 */
		cursor: pointer;
	}

	.itemContainer {
		white-space: nowrap;
		cursor: pointer;
	}

	.item {
		overflow-x: hidden;
		text-overflow: ellipsis;
		overflow-y: hidden;
	}
}
.bold {
	border: 1px solid var(--border-accent);
	background-color: var(--surface-low);
	border-radius: 8px; /* background-color-gray-5 */
}

.progressBarContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	.progressBar {
		height: 5px !important;
		background-color: var(--surface-tertiary);
		//box-shadow: inset 2px 2px 4px 0 #d0dbe7, inset -2px -2px 4px 0 rgba(250, 251, 255, 0.75);
		width: 100%;
	}
}

.positive {
	color: var(--text-positive);
}

.negative {
	color: var(--text-negative);
}

.tooltipTimestamp {
	font-size: var(--fs-13);
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: -0.3px;
	color: var(--text-secondary);
	margin: 5px;
}

.tooltipTitle {
	font-size: 13px;
	font-weight: bold;
	letter-spacing: -0.36px;
	color: var(--text-primary); /* //color-dark */
}

.grid {
	height: calc(100% - 30px);

	.padSm {
		padding: 4px 8px !important;
	}

	:global {
		.dx-datagrid .dx-row > td {
			height: inherit;
			padding: 8px;
			line-height: 1.2;
			font-size: 13px;
		}

		//Remove unnecessary table borders
		.dx-datagrid-borders .dx-datagrid-rowsview,
		.dx-datagrid-headers + .dx-datagrid-rowsview,
		.dx-datagrid-borders > .dx-datagrid-headers {
			border-top: none !important;
			border-left: none !important;
			border-right: none !important;
			border-bottom: 1px solid var(--border-primary);
			background-color: var(--surface-primary); /* background-color-light */
		}

		.dx-datagrid-borders > .dx-datagrid-rowsview {
			border-bottom: none !important;
		}

		// Header cell
		.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
			border-right: 1px solid var(--border-primary) !important;
			border-bottom: 1px solid var(--border-primary);
			background-color: var(--surface-primary);
			color: var(--text-secondary);
			vertical-align: middle;

			&:last-child {
				border-right: none !important;
				text-align: center;
			}
		}

		.dx-datagrid-rowsview .dx-row.dx-row-lines > td {
			border-color: var(--border-primary); //border--gray-10);
			vertical-align: middle;

			div {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		// Table row
		.dx-datagrid-rowsview .dx-row {
			height: 1px;
			background-color: var(--surface-primary);
			color: var(--text-primary); /* //color-dark */
			border-color: var(--border-primary); //border--gray-10);

			&:hover {
				background-color: var(--surface-primary); /* background-color-gray-5 */
			}
		}

		.dx-datagrid-content {
			background-color: var(--surface-primary); /* background-color-light */
		}

		.dx-datagrid-nodata {
			//color: var(--text-secondary);
			display: none;
		}

		.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td {
			color: var(--text-primary); //color--gray-10);
			color: var(--text-primary); /* //color-dark */
			border-bottom: none;
		}
	}
}

:global {
	// Column chooser

	// Background
	.dx-popup-wrapper .dx-state-focused.dx-overlay-content,
	.dx-datagrid-column-chooser .dx-overlay-content {
		background-color: var(--surface-primary); /* background-color-gray-5 */
		box-shadow: var(--box-shadow-S);
		border: none;
	}

	.dx-datagrid-column-chooser.dx-datagrid-column-chooser-mode-drag .dx-popup-content {
		padding: 0px 20px;
	}

	// Header
	.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-title {
		color: var(--text-primary); /* //color-dark */
	}

	// X icon
	.dx-popup-title .dx-closebutton .dx-icon {
		color: var(--text-primary); /* //color-dark */
	}

	// Empty message
	.dx-datagrid-column-chooser-list .dx-empty-message {
		color: var(--text-secondary);
	}

	// Column
	.dx-datagrid-drop-highlight > td,
	.dx-datagrid-drag-header,
	.dx-datagrid-column-chooser .dx-overlay-content .dx-popup-content .dx-column-chooser-item {
		background-color: var(--surface-primary);
		color: var(--text-secondary);
		border: 1px solid var(--border-secondary);
	}
}

html[dir='rtl'] {
	.header {
		padding-right: 8px;
	}

	.contentSymbol {
		margin-left: 0px;
		margin-right: 4px;
	}

	.filterOptionGroup {
		margin: 0px 0px 0px 20px;
	}

	.filterLabel {
		margin: 0px 0px 0px 5px;
	}

	.item {
		padding: 2px 8px 2px 2px;
		text-align: right;
	}

	.addIcon {
		margin-right: 10px;
	}

	.inputCount {
		float: left;
		margin: 0 0 0 23px;
	}

	.btnCancel {
		margin: 0 0 0 10px;
	}

	.star {
		padding-right: 4px;
	}

	.errorMessage {
		right: 24px;
		left: initial;
	}

	.timeUpdate {
		direction: ltr;
	}
}

.emptyGridContainer {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
	gap: 12px;
	padding: 12px;
	height: 100%;
	width: 100%;

	svg.emptyIcon {
		color: var(--icon-tertiary);
		font-size: 80px;
		overflow: hidden;
	}

	.emptyStateMessage {
		color: var(--text-secondary);
		font-size: var(--fs-15);
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		a {
			color: var(--text-primary);
			font-size: 15px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-decoration-line: underline;
			text-decoration-style: solid;
			text-decoration-skip-ink: none;
			text-decoration-thickness: auto;
			text-underline-offset: auto;
			text-underline-position: from-font;
			margin-left: 4px;
		}
		
		a:hover {
			color: var(--text-primary);
			text-decoration-line: underline;
			cursor: pointer;
		}
	}
}