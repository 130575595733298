// ============ Custom scrollbars ============

body ::-webkit-scrollbar-thumb {
	cursor: pointer;
	border-radius: 5px;
	background: var(--surface-primary);
	-webkit-transition: color 0.2s ease;
	transition: color 0.2s ease;
}

::-webkit-scrollbar-corner {
	color: var(--surface-primary); //backgro: var(-- gray- 10) var(--light);
}

%custom-scrollbar-element {
	overflow-y: auto;
	overflow-y: overlay;
	scrollbar-gutter: stable; // as-per-spec way to create scrollbars above the content, OS-dependent
	// only for Firefox
	scrollbar-width: thin;
	scrollbar-color: var(--surface-secondary) var(--surface-primary);

	&:hover {
		scrollbar-color: var(--surface-tertiary) var(--surface-primary);
		&::-webkit-scrollbar-thumb {
			background: var(--surface-tertiary);
		}
	}

	&::-webkit-scrollbar {
		background-color: transparent;
		width: 7px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: var(--surface-tertiary); // hover on the scroll
	}

	&::-webkit-scrollbar-thumb:active {
		background-color: var(--surface-tertiary); //when you click on the scroll
	}

	&::-webkit-scrollbar-thumb:focus {
		background-color: var(--surface-tertiary); //when you click on the scroll
	}

	&::-webkit-scrollbar-track {
		background-color: var(--surface-primary); //rgba(0,0,0,.25);
	}
}

// ===========================================
