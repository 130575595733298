@mixin divider {
	border-right: 2px solid var(--menu-primary);
	border-left: 2px solid var(--menu-primary);
	background: var(--border-tertiary);
	top: 0;
	width: 5px;
	position: absolute;
	transform: translate(-50%, 0px);
	background-clip: padding-box;
}

.wrapper {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	padding: 3px 12px;
	position: relative;
	background-color: var(--surface-secondary);
	cursor: pointer;
	height: 36px;
	white-space: nowrap;
	line-height: 15px;
	transition: all ease 0.2s;

	&:hover,
	&.isOpen {
		background-color: var(--surface-tertiary);
	}

	&:not(:first-child) {
	}

	&:last-child {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	&.green {
		.statusIndicator {
			background-color: var(--surface-positive);
		}
	}

	&.orange {
		.statusIndicator {
			background-color: var(--orange);
		}
	}

	&.red {
		.statusIndicator {
			background-color: var(--surface-negative); /* background-color-negative */
		}
	}

	&.disabled {
		.statusIndicator {
			background-color: var(--gray-30);
		}
	}
}

.gymPresenter {
	background-color: var(--surface-primary);
	padding: 0;
	height: auto;
}

.statsLabel {
	font-size: var(--font-size-11);
	color: var(--text-secondary);

	line-height: 13px;
}

.marginPercent {
	font-size: var(--font-size-13);
	margin: 0 4px;
}

.valueContainer {
	display: flex;
	align-items: baseline;
	color: var(--text-primary);
}

.statusIndicator {
	height: 8px;
	width: 8px;
	border-radius: 50px;
	background-color: var(--surface-positive);
}

.toggleIcon {
	height: 11px;
}

.popup {
	background-color: var(--menu-primary);
	width: 304px;
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 50%;
	transform: translate(-50%, 0);
	border-radius: 20px;
	box-shadow: var(--box-shadow-S);
	padding: 12px;

	.contentWrapper {
		overflow-y: hidden;

		.progressBarContainer {
			display: flex;
			flex-direction: column;
			align-items: center;

			&.green {
				.progressPercent {
					color: var(--text-positive);
				}

				:global {
					.ui.progress .bar {
						background: var(--surface-positive);
					}
				}
			}

			&.orange {
				.progressPercent {
					color: var(--surface-indifferent);
				}

				:global {
					.ui.progress .bar {
						background: var(--surface-indifferent);
					}
				}
			}

			&.red {
				.progressPercent {
					color: var(--text-negative);
				}

				:global {
					.ui.progress .bar {
						background: var(--surface-negative);
					}
				}
			}

			&.disabled {
				.progressPercent {
					color: var(--text-tertiary);
				}

				:global {
					.ui.progress .bar {
						background: var(--surface-tertiary);
					}
				}
			}

			.progressPercent {
				font-size: 15px;
				font-weight: bold;
				margin-bottom: 6px;
			}

			.progressWrapper {
				width: 100%;
				position: relative;

				:global {
					.ui.progress {
						height: auto;
						margin: 0;
						width: 100%;
						background: var(--surface-tertiary);

						.bar {
							min-width: 5px;
							height: 4px;
						}
					}
				}

				.shortSection {
					width: 60px;
				}

				.longSection {
					width: 114px;
				}

				.dividerShort .text,
				.dividerLong .text {
					color: var(--text-secondary);
					text-decoration: none;
					position: absolute;
					font-size: var(--font-size-13);
					top: 100%;
					transform: translate(-50%, 0);
					width: 170px;
					left: 20px;
					white-space: pre-line;
				}

				.dividerShort .text {
					transform: translate(-50%, 0);

					&.japanText {
						transform: translate(-80%, 0);
					}
				}

				.dividerShort {
					@include divider();
					height: 20px;
					left: 25%;

					&.japanPositioningShortDiv {
						left: 50%;
					}
				}

				.dividerLong {
					@include divider();
					height: 180px;

					left: 50%;

					&.japanPositioningLongDiv {
						left: 54%;
					}
				}
			}
		}

		.marginCallStopOut {
			display: flex;
			flex-direction: column;
			gap: 4px;

			.marginCall {
				width: calc(50% - 4px);
				background-color: var(--menu-primary);
				margin-top: 8px;
				padding: 4px 8px;
				z-index: 10;
			}

			.marginCallJapan {
				width: 50%;
				background-color: var(--menu-primary);
				margin-top: 8px;
				padding: 4px 0;
				text-align: right;
				z-index: 10;
			}

			.stopOut {
				width: 100%;
				background-color: var(--menu-primary);
				display: flex;
				justify-content: center;
				padding: 4px 8px;
				z-index: 10;
			}
		}
	}

	.contentContainer {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid var(--border-primary);
		padding: 12px 8px;
		color: var(--text-secondary);

		.values {
			font-weight: bold;
			color: var(--text-primary);
		}

		&:last-of-type {
			border-bottom: none;
		}

		&:last-child {
			padding-bottom: 0;
		}
	}
}

html[dir='rtl'] {
	.wrapper {
		&:last-child {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
		}
	}

	.values {
		direction: ltr;
	}

	.dividerShort {
		left: 0;
		right: 25%;
	}

	.dividerShort .text,
	.dividerLong .text {
		direction: ltr;
		left: 40px !important;
	}

	.popup {
		transform: translate(-40%, 0);
	}
}
