@import 'src/css/common/_btn_styled_as_link.scss';

.detailsTable {
	width: 100%;

	tr:not(:last-child) {
		border-bottom: 1px solid var(--gray-10);
	}

	td:first-child {
		text-align: left;
	}

	td:last-child {
		text-align: right;
	}

	td {
		padding: 5px 0;
		word-break: break-all;
		vertical-align: top;
	}

	.btnViewCurrencyDetails {
		@extend %buttonStyledAsLink;

		margin-left: 10px;
	}
}
