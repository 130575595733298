.dark {
	--light-blue: #1fc0d74d;

	--topaz: #1fc0d7;
	--topaz-light: #1c3f47;
	--topaz-hover: #1da9bd;
	--topaz-gradient: linear-gradient(147deg, #4c9ad7 0%, #1fc0d7 100%);

	--mint: #13c0a0;
	--mint-hover: #058c73;

	--orange: #fc8b05;
	--light-orange: #3c3b33;

	--watermelon: #f74760;
	--watermelon-hover: #de324b;
	--login-error: #f74545;
	--login-error-backrgound: #3d2d36;

	--light: #1c282e;
	--dark: #ffffff;

	--light-widget: #ffffff;
	--light-widget--hover: #e5e8ed;
	--light-widget--hover-reverse: #2f3947;
	--light-widget--disabled: #f1f3f6;
	--light-widget-reverse: #1c242e;
	--light-widget--background: #262e39;
	--light-widget--text: #b3bcc7;
	--light-widget--bullet: #4c5a6b;

	--login-page-sumbit-text: #0e1116;
	--login-page-links: #24b502;
	--login-page-links-reverse: #299310;

	--surface-surface-tertialy: #384250;
	--icon-icon-primary: #F8F9FB;

	--const-dark: #1c282e; // When this variable is being uset it wont be changed in the dark theme
	--const-light: #ffffff; // When this variable is being uset it wont be changed in the dark theme
	--inverted-dark: #ffffff; // This variable should be used when you want the color to be dark in the light theme and light in the dark theme

	//Light theme grays
	--gray-5: #273238;
	--gray-10: #333d43;
	--gray-30: #60686d;
	--gray-50: #8d9396; //generic gray
	--gray-90: #e8e9ea;
	--selected-gray: #e6e6e6;
	--shadow: var(--transparent-dark-60);

	--box-shadow-S: 0 0 18px var(--shadow);

	--background-1: rgba(28, 40, 46, 0.02);
	--background-2: #f3f4f4;
	--background-3: rgba(255, 255, 255, 0.2);

	--box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	--box-shadow-reversed: 0 -2px 4px 0 rgba(0, 0, 0, 0.5); // from bottom to top
	--box-shadow-S: 0 2px 4px 0 rgba(0, 0, 0, 0.4), 0 1px 10px 0 rgba(0, 0, 0, 0.32), 0 4px 5px 0 rgba(0, 0, 0, 0.34),
		0 2px 20px 0 rgba(0, 0, 0, 0.8);
	--input-field-box-shadow: inset 2px 2px 4px 0 #000, inset -2px -2px 4px 0 #28353b;
	--bs-info-rgb: 76, 132, 238;
	--dark-blue-grey: #00484f;
	// This is used for the search bar instrument icons because they are based on brightness to change colors
	--brightness-icon: brightness(1.5);

	--theme-toggle-color: #333d43;

	// ------------------ OLD VARIABLES ------------------
	// These variables are still here because some of them are being used in the charts
	// Primary colors (blue)
	--color-primary-1: #1fc0d7;
	--color-primary-2: #11b0c7;
	--color-primary-3: #14acbf;
	--color-primary-4: #0c4c56;
	--color-primary-5: #216ba5;
	--color-primary-6: #00c3da;
	--color-primary-7: #158696;

	// Neutral colors
	--color-neutral-1: #f3f4f4;
	--color-neutral-2: #e8e9ea;
	--color-neutral-3: #939b9f;
	--color-neutral-4: #3c4b53;
	--color-neutral-5: #273840;
	--color-neutral-6: #121b1e;
	--color-neutral-7: #121212;
	--color-neutral-8: #1c282e;

	// White & Black Colors
	--constant-light: #ffffff;
	--color-black: #000000;

	// Red colors
	--color-red-1: #f74760;
	--color-red-2: #de324b;

	// Green colors
	--color-green-1: #13c0a0;
	--color-green-2: #058c73;
	--color-green-3: #8fc250;

	// Grey colors
	--color-grey-1: #939b9f;
	--color-grey-2: #939b9f;
	--color-grey-3: #f3f4f4;
	--color-grey-4: #8d9396;
	--color-grey-5: #e8e9ea;
	--color-grey-6: #babec0;
	--color-grey-7: #60686d;
	--color-grey-8: #d3d3d3;
	--color-grey-9: #505050;
	--color-grey-10: #d4d7d9;
	--color-grey-11: #d0dbe7;
	--color-grey-12: #cccccc;
	--color-grey-13: #8e9599;
	--color-grey-14: #eeeeee;
	--color-grey-15: #b6c6d4;

	// Live account color
	--live-account-color: #13c0a0;

	// Demo account color
	--demo-account-color: #1fc0d7;

	// Box-shadow colors
	--box-shadow-1: rgba(0, 0, 0, 0.16);
	--box-shadow-2: rgba(250, 251, 255, 0.75);
	--box-shadow-3: rgba(147, 155, 159, 0.2);
	--box-shadow-4: #b6c6d4;
	--box-shadow-5: rgba(0, 0, 0, 0.2);
	--box-shadow-6: rgba(0, 0, 0, 0.12);
	--box-shadow-7: rgba(0, 0, 0, 0.14);
	--box-shadow-8: rgba(28, 40, 46, 0.4);

	// Background colors
	--background-1: rgba(28, 40, 46, 0.02);
	--background-2: rgba(239, 241, 243, 0.993);

	// Border colors
	--br-color-1: rgba(39, 56, 64, 0.1);
	--br-color-2: rgba(34, 36, 38, 0.15);

	// Transparent colors
	--transparent-color-1: rgba(0, 0, 0, 0.1);

	// Font colors
	--font-color-1: rgba(28, 40, 46, 0.5);

	--nav-background: var(--nav-background-color);
	--nav-box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.5);

	// Watermark colors
	--watermark-font-color: rgba(255, 255, 255, 0.2);

	//Primative colors
	--blue-50: #eff6ff;
	--blue-500: #4c84ee;
	--blue-600: #3266e3;
	--blue-700: #2652d0;
	--blue-950: #1a253d;
	--gray-0: #ffffff;
	--gray-100: #f2f5f8;
	--gray-150: #eaeef2;
	--gray-200: #e4e8ee;
	--gray-250: #d9dee6;
	--gray-300: #ced5dd;
	--gray-350: #b3bcc8;
	--gray-400: #99a3b2;
	--gray-450: #818c9d;
	--gray-50: #f8f9fb;
	--gray-500: #6a7585;
	--gray-550: #5b6676;
	--gray-600: #4b5666;
	--gray-650: #424c5b;
	--gray-700: #384250;
	--gray-750: #2d3643;
	--gray-800: #222a36;
	--gray-850: #19202d;
	--gray-900: #131926;
	--gray-950: #050814;
	--green-50: #e9f7ea;
	--green-500: #29c903;
	--green-600: #24b502;
	--green-700: #299310;
	--green-950: #1b2c2a;
	--orange-500: #fc8b05;
	--orange-600: #ee880f;
	--red-50: #feeded;
	--red-500: #f86363;
	--red-600: #f74747;
	--red-700: #d33d43;
	--red-950: #2f2430;
	--transparent-dark-30: #19202d4c;
	--transparent-dark-40: #05081466;
	--transparent-dark-60: #05081499;
	--transparent-light-30: #ffffff4c;
	--transparent-light-40: #ffffff66;
	--transparent-light-60: #ffffff99;

	//Dark colors
	--border-accent: var(--blue-600);
	--border-accent-secondary: var(--blue-950);
	--border-alert: var(--red-500);
	--border-invert: var(--gray-150);
	--border-negative: var(--red-500);
	--border-positive: var(--green-500);
	--border-primary: var(--gray-800);
	--border-quaternary: var(--gray-250);
	--border-secondary: var(--gray-700);
	--border-success: var(--green-500);
	--border-tertiary: var(--gray-600);
	--button-negative: var(--red-600);
	--button-negative-hover: var(--red-500);
	--button-negative-secondary: var(--red-950);
	--button-neutral: var(--gray-50);
	--button-neutral-hover: var(--gray-150);
	--button-positive: var(--green-600);
	--button-positive-hover: var(--green-500);
	--button-positive-secondary: var(--green-950);
	--button-primary: var(--blue-600);
	--button-primary-hover: var(--blue-500);

	--constant-dark: var(--gray-850);
	--constant-light: var(--gray-50);

	--icon-accent: var(--blue-500);
	--icon-alert: var(--red-500);
	--icon-demo: var(--blue-500);
	--icon-invert: var(--gray-850);
	--icon-live: var(--green-500);
	--icon-negative: var(--red-500);
	--icon-positive: var(--green-500);
	--icon-primary: var(--gray-50);
	--icon-secondary: var(--gray-500);
	--icon-success: var(--green-500);
	--icon-tertiary: var(--gray-700);
	--input-alert: var(--red-950);
	--input-primary: var(--gray-800);
	--input-secondary: var(--gray-750);
	--input-tertiary: var(--gray-550);
	--input-low: var(--gray-900);

	--menu-primary: var(--gray-800);

	--surface-sufrace-accent: var(--blue-600);
	--surface-accent-secondary: var(--blue-950);
	--surface-alert: var(--red-500);
	--surface-alert-secondary: var(--red-950);
	--surface-indifferent: var(--orange-500);
	--surface-invert: var(--gray-0);
	--surface-low: var(--gray-900);
	--surface-negative: var(--red-500);
	--surface-overlay: var(--transparent-dark-60);
	--surface-positive: var(--green-500);
	--surface-primary: var(--gray-850);
	--surface-quaternary: var(--gray-550);
	--surface-secondary: var(--gray-800);
	--surface-succes-secondary: var(--green-950);
	--surface-success: var(--green-500);
	--surface-tertiary: var(--gray-700);
	--surface-transparent: var(--transparent-dark-30);

	--text-accent: var(--blue-500);
	--text-alert: var(--red-500);
	--text-demo: var(--blue-500);
	--text-indifferent: var(--orange-500);
	--text-invert: var(--gray-850);
	--text-live: var(--green-500);
	--text-negative: var(--red-600);
	--text-positive: var(--green-500);
	--text-primary: var(--gray-50);
	--text-secondary: var(--gray-350);
	--text-success: var(--green-500);
	--text-tertiary: var(--gray-500);
}

html[dir='rtl'] .dark {
	--nav-box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.5);
}
