.container {
	display: flex;
	align-items: center;
	background-color: var(--surface-secondary);
	color: var(--text-primary);
	height: 100%;
	padding: 0 12px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	font-size: var(--font-size-13);
	line-height: 15px;
	cursor: pointer;
	transition: all ease 0.2s;

	&:hover,
	&.isOpen {
		background-color: var(--surface-tertiary);
	}

	.currencyIcon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 28px;
		height: 28px;
		border-radius: 50%;
		margin-right: 6px;
		padding: 2px;
		position: relative;
		overflow: hidden;

		&.live {
			border: 1px solid var(--icon-live);
		}

		&.demo {
			border: 1px solid var(--icon-demo);
		}

		.exclamationIcon {
			font-size: 22px;
			color: var(--icon-tertiary);
		}

		.accountTypeBadge {
			position: absolute;
			//bottom: 0;
			top: 30px;
			background-color: transparent;
			color: var(--constant-light);
			border-radius: 3px;
			font-size: 7px;
			display: flex;
			justify-content: center;
			line-height: 9px;
			padding: 0 2px 2px;
			width: calc(100% - 4px);
			transition: all ease-in-out 0.3s;
			font-weight: 600;
			text-align: center;

			&.live {
				background-color: var(--icon-live);
				top: 17px;
			}

			&.demo {
				background-color: var(--icon-demo);
				top: 17px;
			}
		}
	}

	.stats {
		display: flex;
		flex-direction: column;

		.accountIdAndType {
			.accountTradingMode {
				white-space: nowrap;
				&.live {
					color: var(--text-live);
				}

				&.demo {
					color: var(--text-demo);
				}
			}
		}

		.accountBaseCurrency {
			font-size: 12px;
			color: var(--text-secondary);
			white-space: nowrap;
		}
	}

	.toggleIcon {
		margin-left: 6px;
		margin-right: 0;
	}
}

.popup {
	background-color: var(--menu-primary);
	width: 304px;
	position: absolute;
	z-index: 1;
	left: 22px;
	top: 100%;
	transform: translate(-50%, 0);
	border-radius: 20px;
	box-shadow: var(--box-shadow-S);
	color: var(--text-primary);

	.menuHeader {
		display: flex;
		justify-content: center;
		box-shadow: inset 0px -1px 0px 0px var(--border-primary);
		padding: 8px 12px 0;
		position: relative;

		.menuItem {
			font-size: var(--font-size-13);
			height: 36px;
			position: relative;
			cursor: pointer;
			flex: 1;
			justify-content: center;
			align-items: center;
			display: flex;
			transition: all linear 0.1s;

			&.active,
			&:hover {
				font-weight: bold;
			}

			&:nth-child(1).active ~ .activeLineIndicator {
				left: 12px;
			}

			&:nth-child(2).active ~ .activeLineIndicator {
				left: 50%;
			}
		}

		.activeLineIndicator {
			position: absolute;
			height: 3px;
			width: calc(50% - 12px);
			background: var(--border-accent);
			border-radius: 4px;
			left: 24px;
			bottom: 0;
			transition: all ease 0.3s;
		}
	}
}

html[dir='rtl'] {
	.popup {
		transform: translate(100%, 0);
		right: 0;
	}

	.container {
		border-radius: 4px 0 0 4px;
	}

	.toggleIcon {
		margin-left: 0;
		margin-right: 6px;
	}
}
