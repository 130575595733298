.modal > div {
	height: 538px !important;
	width: 463px !important;
}

.modalHeader {
	width: 100%;
	border-bottom: none;
	box-shadow: var(--box-shadow);
}

.modalTitle {
	width: 100%;
	text-align: center;

	font-size: 16px;
	font-weight: bold;
}

.modalBody {
	padding: 0px !important;
	background-color: var(--surface-primary); /* background-color-gray-5 */
}

.modalBody > div {
	padding: 11px 28px;
}

.modalBody > div > div {
	margin-top: 5px;
}

.closeIcon {
	color: var(--text-primary); /* //color-dark */
	font-size: 22px;
	cursor: pointer;
}

html[dir='rtl'] {
	.pL {
		direction: rtl !important;
	}

	.date {
		direction: ltr;
	}
}
