$container-padding: 30px;

@mixin side-spacing() {
	padding-right: $container-padding;
	padding-left: $container-padding;
}

//  &#{&} is SaaS trick to increase selector specificity and override default modal styles
.reconnectModal {
	&#{&} {
		height: 370px;
		width: 416px;
	}

	// TODO: this should be removed when all the modals be reworked by design
	&:global {
		&.modal-content {
			padding: 0 !important;
			border-radius: 0.3rem !important;
		}
	}
}

.modalHeader {
	&#{&} {
		@include side-spacing();

		width: 100%;
		height: 52px;
		justify-content: center;
		text-align: center;

		font-size: 16px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.39px;
		box-shadow: var(--box-shadow);
		border-bottom: none;
	}
}

.modalBody {
	&#{&} {
		@include side-spacing();

		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

.modalText {
	margin-top: 32px;

	font-size: 15px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: -0.41px;
	color: var(--text-primary); /* //color-dark */
}

.modalFooter {
	&#{&} {
		@include side-spacing();
	}

	height: 75px;
	justify-content: center !important;
	border-top: none !important;
	box-shadow: var(--box-shadow-reversed);

	.button {
		width: 172px;
		height: 40px;
		max-height: none;
		padding: 12px 30px;
		margin: 0;
		font-size: 16px;
		font-weight: 600;

		& + .button {
			margin-left: auto;
		}

		&:first-child:last-child {
			width: auto;
		}
	}
}

.iconContainer {
	align-items: center;
	flex-direction: column;
	display: flex;
	justify-content: center;
	height: fit-content;
}

.loadingIcon {
	height: 150px;
	width: 150px;
}

.failedIcon {
	color: var(--color-neutral-2);
}

html[dir='rtl'] {
	.button {
		& + .button {
			margin-right: auto;
			margin-left: initial;
		}
	}
}
