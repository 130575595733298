@import 'src/css/common/_custom_scrollbar';

.container {
	height: 100%;
	background-color: var(--surface-primary);

	.watchlistContainer {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.watchlistContainerDetach {
		height: calc(100% - 32px);
	}

	.marketTickerContainer {
		@extend %custom-scrollbar-element;
		display: flex;
		flex-flow: column nowrap;
		height: calc(100% - 32px);
		overflow-y: auto;
		overflow-y: overlay;
		overflow-x: hidden;
		background-color: var(--surface-primary); /* background-color-gray-5 */
		// only for Firefox
		scrollbar-width: thin;
		background-color: var(--border-primary);

		&::-webkit-scrollbar-track {
			background-color: var(--surface-primary); /* background-color-gray-5 */
		}
	}

	.windowMarketTickerContainer {
		height: 100%;
	}

	.noScrollBar {
		scrollbar-width: none;
	}

	.instrumentsContainer {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		gap: 3px;
		height: auto;
	}

	.addInstrumentContainer {
		flex-grow: 0;
		width: 100%;
	}

	.addInstrument {
		text-align: center;
		padding: 14px 8px;
		display: flex;
		align-items: center;
		padding-left: 15px;
	}

	.centeredText {
		@extend .addInstrument;
		justify-content: center;
	}

	.emptyContainer {
		height: 260px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 12px;
		padding: 40px 20px 20px;
	}

	.iconC {
		color: var(--icon-tertiary);
		cursor: default;
		font-size: 100px;
	}

	.emptyTitle {
		padding: 2px 20px;
		font-size: 14px;
		text-align: center;
		color: var(--text-secondary);
		max-width: 261px;
	}

	.addInstrumentIcon {
		cursor: pointer;
		color: var(--text-primary); /* //color-dark */
		font-size: 1.3em;
	}

	.addInstrumentTitle {
		cursor: pointer;
		color: var(--text-primary); /* //color-dark */

		font-size: 13px;
		font-weight: bold;
		padding: 0 5px;
		margin-bottom: 0;
	}
}
