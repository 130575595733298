@import 'src/css/common/_modal_header_simple.scss';
@import 'src/css/common/_modal_footer.scss';

.modalDialog {
	max-width: 550px;
	color: var(--text-primary); /* //color-dark */
	letter-spacing: -0.36px;

	.modalContent {
		overflow: hidden;
		border-bottom-left-radius: calc(0.3rem - 1px);
		border-bottom-right-radius: calc(0.3rem - 1px);
	}

	.tierInfoContainer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-size: var(--font-size-15);

		.tierInfo {
			font-weight: bold;
		}

		.viewDetails {
			color: var(--text-accent); //color-topaz
		}
	}

	.modalHeader {
		@extend %modalHeaderSimple;

		box-shadow: none;
	}

	.detailsModalHeader {
		align-self: center;
		font-size: var(--font-size-36);
		font-weight: bold;
		border-bottom: none;
		z-index: 1;
		width: 100%;

		.wrapper {
			display: flex;
			align-items: center;
			width: 100%;
		}

		.title {
			flex-grow: 1;
			text-align: center;
			font-size: 20px;
		}

		.backButton {
			font-size: var(--font-size-24);
			color: var(--text-primary); /* //color-dark */
			cursor: pointer;
		}
	}

	.confirmModalHeader {
		align-self: center;
		font-size: var(--font-size-36);
		font-weight: bold;
		box-shadow: none;
		border-bottom: none;
	}

	.modalBody {
		background-color: var(--surface-primary); /* background-color-gray-5 */
		padding: 0;

		&.scroll {
			height: 362px;
			overflow-y: scroll;
		}

		.instrumentGroupContainer {
			display: flex;
			flex-direction: column;
			font-size: var(--font-size-13);
			line-height: 1.69;
			padding-bottom: 10px;

			&:last-child {
				padding-bottom: 0;
			}

			.groupName {
				font-weight: bold;
			}
		}
	}

	.detailsModalBody {
		@extend .modalBody;
		padding: 0 20px 20px;
	}

	.modalContent {
		height: 450px;
	}

	.tilesContainer {
		display: flex;

		& > * {
			flex-grow: 1;
		}
	}

	.tableContainer {
		table {
			table-layout: fixed;
			position: relative;
			width: 100%;
			border-collapse: collapse;

			th {
				position: sticky;
				z-index: 1;
				top: 0;
				padding: 7px 15px;
				box-shadow: rgba(black, 0.4) 0 4px 4px;
				background-color: var(--surface-primary);
				font-weight: normal;

				&:first-child::before,
				&:last-child::before {
					content: '';
					position: absolute;
					background-color: var(--surface-primary);
					top: 0;
					width: 20px;
					height: 100%;
				}

				&:first-child::before {
					right: 100%;
				}

				&:last-child::before {
					left: 100%;
				}

				&:first-child::after,
				&:last-child::after {
					content: '';
					position: absolute;
					width: calc(100% + 20px);
					height: 1px;
					bottom: 0;
					z-index: 1;
					box-shadow: var(--box-shadow);
				}

				&:first-child::after {
					right: 0;
				}

				&:last-child::after {
					z-index: 1;
					left: 0;
				}

				&:not(:first-child):not(:last-child)::after {
					content: '';
					position: absolute;
					width: 100%;
					height: 1px;
					bottom: 0;
					z-index: 1;
					left: 0;
					box-shadow: var(--box-shadow);
				}
			}

			td {
				padding: 7px 15px;
				border-bottom: 1px solid var(--gray-10);
				vertical-align: top;
			}

			th:not(:first-child),
			tbody tr:first-child td:not(:first-child),
			tbody tr:not(:first-child) td {
				border-left: 1px solid var(--gray-10);
			}

			th:last-child,
			tbody tr td:last-child {
				text-align: right;
			}

			th,
			tbody:first-of-type tr:first-child td {
				border-top: 1px solid var(--gray-10);
			}

			tbody tr:first-child td:first-child {
				font-weight: 600;
			}
		}
	}

	.modalFooter {
		@extend %modalFooter;
	}
}
