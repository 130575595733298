.container {
	width: 100%;
	height: 100%;

	.displayNone {
		display: none;
	}

	.loader {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
