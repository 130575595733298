.section {
	color: var(--text-primary);
	padding: 12px;

	.header {
		font-size: 11px;
		color: var(--text-secondary);
		padding: 0 20px;
	}

	.item {
		.demo {
			color: var(--text-accent); //color-topaz
			text-transform: uppercase;
		}

		.live {
			color: var(--text-positive); /* color-mint */
			text-transform: uppercase;
		}

		.icon {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			color: var(--text-primary); /* //color-dark */
			font-size: 20px;
			padding-right: 8px;

			&.live {
				border-color: var(--border-positive); /* border-color-mint */
				font-size: 16px;
			}

			&.demo {
				border-color: var(--border-accent); //border-topaz
				font-size: 16px;
			}

			&.red {
				color: var(--watermelon);
			}
		}

		.iconContainerWrapper {
			display: flex;
			height: 40px;
			align-items: center;
			width: 100%;
			cursor: pointer;
			font-size: var(--font-size-13);
			color: var(--text-primary);
			border-radius: 8px;
			gap: 8px;
			padding: 0 8px;

			&:hover {
				background-color: var(--surface-tertiary); /* background-color-gray-5 */
			}
			.iconContainer {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 32px;
				height: 32px;

				.settingsIcon {
					margin: 0 auto;
					font-size: 20px;

					&.red {
						color: var(--watermelon);
					}
				}
			}
		}

		.statsContainer {
			display: flex;
			flex-direction: column;

			.stats {
				&.selected {
					font-weight: bold;
				}

				&.red {
					color: var(--watermelon);
				}
			}

			.baseCurrency {
				font-size: var(--font-size-12);
				color: var(--text-secondary);
			}
		}

		.addAccountContainer {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 26px;
			height: 26px;
			border-radius: 50%;
			padding: 10px;

			&.live {
				border: 1px solid var(--mint);
			}

			&.demo {
				border: 1px solid var(--border-accent); //border-topaz
			}

			.plusIcon {
				color: var(--text-primary); /* //color-dark */
				font-size: 14px;
			}
		}

		.addAccountText {
			padding: 0 8px;
		}

		.itemNoBorder {
			border-bottom: none;
		}
	}

	.disabledOptionChildren {
		@extend .item;
		cursor: not-allowed;

		& > * {
			opacity: 0.25;
		}

		&:hover {
			background-color: var(--surface-primary); /* background-color-light */
		}
	}

	.manageSubscriptionIcon {
		width: 0.9em;
		height: 0.9em;
	}

	.itemCancel {
		&,
		& .icon {
			color: var(--watermelon);
		}
	}
	.currencyStatsUsers {
		padding: 8px 12px;
	}
}

html[dir='rtl'] {
	.iconContainer {
		padding-right: 0px !important;
		margin-right: 12px;
	}

	.icon {
		padding-left: 8px;
		padding-right: 0 !important;
	}
}
