:global {
	.ui.active.button,
	.ui.button {
		font-size: var(--font-size-13);
		font-weight: bold;
		color: var(--text-primary); /* //color-dark */
		background: transparent;
		padding: 12px;
	}

	.ui.button:focus,
	.ui.active.button:hover,
	.ui.button:hover {
		color: var(--gray-90);
		background-color: transparent;
	}

	.ui.dropdown .menu {
		background-color: var(--surface-primary); /* background-color-light */
		font-size: var(--font-size-13);
		box-shadow: var(--box-shadow);
		border: 1px solid var(--border-secondary);
		border-radius: 4px;
	}

	.ui.dropdown .menu > .item {
		font-size: var(--font-size-13);
		color: var(--text-primary); /* //color-dark */
	}

	.ui.dropdown .menu > .item:hover {
		color: var(--text-primary); /* //color-dark */
	}

	.ui.selection.dropdown {
		border-radius: 4px;
		font-weight: normal;
		margin: 0;
		background-color: var(--surface-primary);
		text-align: center;
	}

	.activeIcon {
		display: none;
	}

	.ui.selection.dropdown.loginDropdownMenu {
		border: none !important;
		background-color: transparent !important;
		padding-left: 34px !important;
		padding-right: 8px !important;
		border: 2px solid transparent !important;
		border-radius: 10px !important;
		color: var(--light-widget) !important;
		font-size: 15px;
		display: flex !important;
		min-width: 8em;
		z-index: 2;
		box-shadow: none !important;

		.visible.menu {
			background-color: var(--login-page-color) !important;
			border: none;
			border-radius: 8px !important;
			max-height: max-content;
			top: 50px;
			padding: 4px;
			left: inherit;
			right: 0;
			min-width: 195px;
			.selected.item,
			.ui.dropdown.selected {
				background-color: #262e39 !important;
			}
			.item {
				background-color: var(--login-page-color) !important;
				border: none;
				color: #f1f3f6 !important;
				font-size: 15px !important;
				font-weight: normal !important;
				border-radius: 4px;
				margin: 2px;
				.activeIcon {
					display: block;
				}
				&:hover {
					background-color: #262e39 !important;
				}
			}
		}
	}

	.ui.selection.dropdown.visible.loginDropdownMenu {
		background-color: var(--light-widget--hover-reverse) !important;
		z-index: 0;
	}
}

html[dir='rtl'] {
	:global {
		.ui.selection.dropdown.loginDropdownMenu {
			padding-left: 8px !important;
			padding-right: 34px !important;
			.visible.menu {
				left: 0;
				right: inherit;
			}
		}
	}
}
