.container {
	padding-bottom: var(--p-8);

	.checkbox {
		display: none;
	}

	&.horizontal {
		transform: rotate(90deg);
	}

	.toggle {
		cursor: pointer;

		color: var(--text-primary); //color--gray-10);
		border-radius: 60px;
		box-sizing: content-box;
		background: var(--surface-low);
		padding: 3px;

		&.active {
			background: var(--surface-accent);
			color: var(--icon-primary);
		}

		&.sm {
			width: 20px;
			height: 42px;
		}

		&.md {
			width: 28px;
			height: 56px;
		}
	}

	.icon {
		font-size: 1.5em;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		color: var(--icon-primary);
		transition: margin-top 0.1s ease-in-out;
		&:hover {
			background-color: var(--surface-tertiary) !important;
		}

		&.start {
			margin-top: 0px;
			background: var(--surface-primary);
			color: var(--icon-primary);
		}

		&.end {
			background: var(--surface-primary);
			color: var(--icon-primary);
		}

		&.sm {
			width: 20px;
			height: 20px;

			&.end {
				margin-top: 22px;
			}
		}

		&.md {
			width: 28px;
			height: 28px;

			&.end {
				margin-top: 28px;
			}
		}

		.faIcon {
			&.horizontal {
				transform: rotate(-90deg);
			}

			&.sm {
				font-size: 12px;
			}

			&.md {
				font-size: 18px;
			}
		}
	}
}

.modalActivate > div {
	margin: auto;
	width: 350px;
	height: 200px;
}

.modalMessage {
	font-size: 20px;
	letter-spacing: -0.41px;
	margin-top: 25px;
	text-align: center;
}

.modalFooter {
	padding: 12px;
	border: none;
	box-shadow: var(--box-shadow-reversed);

	@media screen and (min-width: 1024px) and (max-height: 768px) {
		backdrop-filter: blur(0px);
	}

	@media screen and (min-width: 3440px) and (max-height: 1440px) {
		backdrop-filter: blur(0px);
	}
}

.modalActionButtons {
	display: flex;
	justify-content: space-between;
	width: 100%;
}
