@import 'src/css/common/_input';

.costInput {
	@extend %cost-input;

	width: 100%;
	max-width: none;
	margin-bottom: 5px;
}

.errorInput {
	@extend .costInput;
	border: 1px solid var(--watermelon);
}

.headerContainer {
	padding: 0px 8px;
	height: 40px;
	border-bottom: 1px solid var(--border-primary);
	align-items: center;
	display: flex;
	justify-content: space-between;
	background-color: var(--surface-primary) !important;
	color: var(--text-primary); /* //color-dark */

	.title {
		cursor: pointer;

		font-size: 13px;
		font-weight: bold;
	}

	// Container for the edit icon after refactoring
	.editWatchlistIcon {
		width: 18px;
		height: 18px;
	}

	.addToWatchlistIcon {
		width: 18px;
		height: 18px;
		cursor: pointer;
	}
}

.headerContainerCenter {
	justify-content: center;
}

.watchlistsPopup {
	max-width: 210px !important;
	min-width: 250px !important;
	height: auto;
	z-index: 999 !important;

	&:global(.ui.popup)::before {
		z-index: -1;
	}

	.watchlists {
		overflow-y: auto;
	}

	.listContainer {
		width: 100%;
		border-bottom: 1px solid var(--gray-10);
		padding: 6px 12px;

		&:hover {
			background-color: var(--surface-primary); /* background-color-gray-5 */
		}
	}

	.itemContainer {
		display: flex;
		white-space: nowrap;
		cursor: pointer;
		justify-content: space-between;
	}

	.watchlistItem {
		width: 85%;
		overflow-x: hidden;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;

		&:hover {
			.icon {
				color: var(--text-primary); /* //color-dark */
			}
		}

		.icon {
			font-size: 1.3em;
			color: var(--gray-30);

			&.active {
				color: var(--text-primary); /* //color-dark */
			}
		}

		.watchlistName {
			cursor: pointer;
			color: var(--text-primary); /* //color-dark */
			padding-left: 8px;
		}
	}
}

.createWatchlistContainer {
	display: contents;
	justify-content: space-between;
	width: 206px;
	height: 260px;
	padding: 16px 0 0;
	box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
	background-color: #fff;

	.createWatchlistItems {
		text-align: center;
		padding: 5px 5px;
		z-index: 1;
		border-bottom: 1px solid var(--gray-10);

		.createWatchlistTitle {
			font-size: 13px;
			font-weight: bold;
			color: var(--text-primary); /* //color-dark */
			margin-left: 3px;
		}
	}

	.inputContainer {
		width: 100%;
		height: 171px;
		padding: 10px 30px 0;
		background-color: var(--surface-primary);

		.inputTitle {
			width: 100%;
			height: 13px;
			margin: 10px 0;

			font-size: 11px;
			color: var(--text-secondary);
		}

		.inputBox {
			text-align: center;
		}

		.messageRow {
			width: 100%;
			display: flex;
			justify-content: space-between;

			.errorMessage {
				width: 100%;
				font-size: 12px;
				color: var(--watermelon);
			}

			.inputContainerText {
				text-align: right;
				height: 16px;
				margin-left: auto;

				font-size: 13px;
				font-weight: normal;
				text-align: right;
				color: var(--gray-30);
			}
		}
	}

	.createWatchlistFooter {
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: 50px;
		padding: 11px 14px 9px;
		border-radius: 3px;
		box-shadow: var(--box-shadow-reversed);
		background-color: var(--surface-primary);

		button {
			width: 45%;
			max-width: none;
		}
	}
}

.headerButtonsWrapper {
	display: flex;
	align-items: center;
}

.watchlistSearchContainer {
	width: 420px;
	max-height: 300px;
	border-radius: 8px !important;
	padding: 10px !important;

	&,
	&:before {
		background-color: var(--gray-5) !important;
	}

	.hidden {
		display: none;
	}

	.searchContainer {
		display: flex;
		flex-direction: column;
	}

	.searchTitle {
		font-size: var(--fs-13);
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: -0.36px;
		margin-bottom: 10px;
		color: var(--text-primary); /* //color-dark */
	}

	.searchInputContainer {
		position: relative;
	}

	.searchInput {
		width: 416px;
		height: 34px;
		border-radius: 12px;
		border: solid 1px var(--gray-30);
		caret-color: var(--icon-accent);
		padding-left: 35px;
		background-color: var(--surface-primary); /* background-color-light */

		&:focus {
			border: solid 1px var(--topaz);
			outline: none;
		}
	}

	.openSearchInput {
		@extend .searchInput;
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		background-color: var(--surface-primary); /* background-color-light */
		color: var(--text-primary); /* //color-dark */
	}

	.inputIcon {
		position: absolute;
		top: calc(50% - 0.45em);
		font-size: 20px;
		color: var(--color-grey-6);
		left: 10px;
	}

	.deleteIcon {
		@extend .inputIcon;
		color: var(--color-grey-7);
		font-size: 13px;
		cursor: pointer;
		left: initial;
		right: 10px;
	}

	.searchResults {
		max-height: 200px;
		overflow-y: auto;
		overflow-y: overlay;
		overflow-x: hidden;
		scrollbar-width: thin; //Firefox scrollbar width

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-track {
			background: none;
		}
	}

	.hiddenScroll {
		@extend .searchResults;
		overflow: hidden;
	}

	.boxSearch {
		@extend .searchResults;
		border-bottom-right-radius: 12px;
		border-bottom-left-radius: 12px;
	}
}

.modalWatchlist > div {
	margin: auto;
	width: 350px;
	height: 350px;

	// TODO: this should be removed when all the modals be reworked by design
	&:global {
		&.modal-content {
			padding: 0 !important;
			border-radius: 0.3rem !important;
		}
	}

	.modalTopHeader {
		border: none;

		@media screen and (min-width: 1024px) and (max-height: 768px) {
			backdrop-filter: blur(0px);
		}

		@media screen and (min-width: 3440px) and (max-height: 1440px) {
			backdrop-filter: blur(0px);
		}

		.modalTitle {
			width: 100%;
			text-align: center;
		}
	}

	.modifyWatchlistModalBody {
		padding: 16px;

		.modifyWatchlist {
			width: 100%;
		}

		.modifyInputContainer {
			width: 100%;
		}

		.modifyInput {
			@extend .costInput;
			max-width: 100%;
			width: 100%;
			height: 38px;
			padding-left: 10px;
		}

		.modifyErrorInput {
			@extend .modifyInput;
			border: 1px solid var(--watermelon);
		}

		.inputLimit {
			color: var(--gray-30);
			display: flex;
			justify-content: flex-end;
		}

		.modifyErrorMessage {
			@extend .errorMessage;
			top: 100px;
			left: 20px;
		}

		.buttonDiv {
			display: flex;
			justify-content: center;
			margin-top: 25px;
		}

		.disableBtn {
			pointer-events: none;
			color: var(--text-secondary);
			opacity: 0.3;
		}

		.modifyButton {
			width: 100%;
			max-width: none;
			max-height: 40px;
			background-color: var(--surface-primary);
			color: var(--watermelon);
			border: 1px solid var(--watermelon);

			&:hover {
				background: var(--watermelon);
				color: white;
			}
		}
	}

	.modifyFooter {
		padding: 12px;
		border: none;

		@media screen and (min-width: 1024px) and (max-height: 768px) {
			backdrop-filter: blur(0px);
		}

		@media screen and (min-width: 3440px) and (max-height: 1440px) {
			backdrop-filter: blur(0px);
		}

		.actionButtons {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		.saveButton,
		.cancelButton {
			width: 45%;
		}
	}
}
