:global {
	.ui.top.popup:before,
	.ui.bottom.center.popup:before,
	.ui.right.center.popup:before,
	.ui.left.center.popup:before,
	.ui.bottom.right.popup:before,
	.ui.bottom.popup:before {
		background: var(--menu-primary);
		box-shadow: none !important;
	}

	.ui.popup {
		background: var(--menu-primary);
		border: none;
		padding: 0;
		border-radius: 20px;
		box-shadow: var(--box-shadow-S);
		color: var(--text-secondary);
		font-size: var(--fs-13);
	}
}
