.tickerActions {
	display: flex;
	justify-content: center;
	align-items: stretch;
	position: relative;
	cursor: pointer !important;

	& .OrderButton {
		position: relative;
		z-index: 0;
	}
}

.tickerActionsModify {
	@extend .tickerActions;
	opacity: 0.25;
	pointer-events: none;
}

.tickerLow {
	color: var(--text-tertiary);
	font-size: var(--fs-11);

	@media screen and (max-width: 1600px) {
		font-size: var(--font-size-9);
	}
}

.tickerHigh {
	color: var(--text-tertiary);
	font-size: var(--fs-11);

	float: right;

	@media screen and (max-width: 1600px) {
		font-size: var(--font-size-9);
	}
}

.tickerLowLong {
	@extend .tickerLow;
	font-size: var(--fs-9);
}

.tickerHighLong {
	@extend .tickerHigh;
	font-size: var(--fs-9);
}

.colorRed {
	color: var(--text-negative);

	&:hover {
		background-color: var(--button-negative-hover);
	}
}

.colorGreen {
	color: var(--text-positive);

	&:hover {
		background-color: var(--button-positive-hover);
	}
}

.instrumentButton {
	border-radius: 3px;
	color: var(--constant-light);
	cursor: pointer;

	font-weight: 700;
	padding: 6px 20px;
	text-align: center;
	height: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.windowInstrumentButton {
	@extend .instrumentButton;
	width: 90px;
}

.gridChartInstrumentButton {
	@extend .instrumentButton;
	width: auto;
	padding: 2px 2px;
	height: 30px;
}

.resizedButton {
	@extend .instrumentButton;
	height: 32px;
	padding: 6px 8px;
}

.orderButton {
	background-color: transparent;
	border-top: solid 1px var(--border-primary);
	color: var(--constant-light);
	text-align: center;
	min-width: 120px;
	display: flex;
	justify-content: center;
	height: 56px;
	font-size: var(--fs-15);
	font-weight: normal;
}

.orderButtonModal {
	@extend .orderButton;
	min-width: 207px;
	justify-content: center;
	padding-left: 0px;
}

.borderButton {
	padding-top: 1px !important;
}

.instrumentGreen {
	background: var(--button-positive-secondary);
	color: var(--text-positive);
	&:hover {
		background: var(--button-positive-hover);
		color: var(--constant-light);
	}
}
.instrumentGreenHighlighted {
	background: var(--button-positive);
	color: var(--constant-light);
	&:hover {
		background: var(--button-positive-hover);
		color: var(--constant-light);
	}
}

.instrumentRed {
	background: var(--button-negative-secondary);
	color: var(--text-negative);

	&:hover {
		background: var(--button-negative-hover);
		color: var(--constant-light);
	}
}
.instrumentRedHighlighted {
	background: var(--button-negative);
	color: var(--constant-light);

	&:hover {
		background: var(--button-negative-hover);
		color: var(--constant-light);
	}
}

.instrumentGrey {
	background: var(--surface-secondary);
	color: var(--text-secondary);

	&:hover {
		background: var(--surface-low);
		color: var(--text-secondary);
	}
}

.orderGreen {
	color: var(--text-positive); /* color-positive*/
	border: 1px solid var(--border-primary);
	&:hover {
		background-color: var(--surface-low);
	}
}

.orderRed {
	color: var(--text-negative); /* color-negative*/
	border: 1px solid var(--border-primary);
	&:hover {
		background-color: var(--surface-low);
	}
}

.orderGrey {
	color: var(--text-secondary);
	border: 1px solid var(--border-primary);
}

.spread {
	font-size: var(--font-size-9);

	position: absolute;
	height: 13px;
	line-height: 1.7;
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;

	color: var(--text-secondary);
	background-color: var(--surface-primary);
	min-width: 30px;
	text-align: center;
	z-index: 2;
}

.instrumentPosition {
	left: 50%;
	top: 6px;
	transform: translate(-48%, -50%);
}

//Class to place the spread value in the middle of the two buttons on the right hand ticket panel buy/sell buttons
.rightHandSpread {
	@extend .spread;
	padding: 0px 5px;
	display: flex;
	justify-content: center;
}

.sellNumber {
	line-height: 24px;
	span {
		display: inline-block;
		line-height: 24px;
	}
}

.headerTickerButtons {
	@extend .spread;
	left: 50%;
	top: 6px;
	z-index: 2;
	transform: translate(-50%, -50%);
}

.label {
	margin-right: 4px;
	margin-top: 4px;
}

.label + div {
	margin-top: 1px;
}

.windowInstrumentPosition {
	@extend .instrumentPosition;
	left: 79px;
}

.orderPosition {
	padding: 0 10px 0 10px;
	top: 0px;
	height: 15px;
}

.orderPositionModal {
	padding: 0 10px 0 10px;
	top: 0;
	height: 15px;
}

.spreadButton {
	z-index: 1;
	border: 1px solid var(--border-primary);
	border-top: none;
}

.highlightSpread {
	color: var(--text-secondary);
	background-color: var(--surface-low);
	min-width: 30px;
	text-align: center;
}

.windowSpread {
	color: var(--color-neutral-5);
	background-color: var(--surface-primary);
}

.tickerContainer {
	cursor: pointer;
	width: 50%;
	position: relative;

	&:last-of-type {
		.orderButton {
			display: flex;
			justify-content: end;
			border-radius: 0 12px 12px 0;
			padding: 0 12px;
		}
	}
	&:first-of-type {
		.orderButton {
			display: flex;
			justify-content: start;
			border-radius: 12px 0 0 12px;
			padding: 0 12px;
		}
	}
}

.notOrder {
	width: 100%;
}

.notOrder .tickerActions .tickerContainer {
	@extend .tickerContainer;

	&:first-child {
		padding-right: 2px;
	}

	&:last-child {
		padding-left: 2px;
	}

	.showPrice {
		display: block;
	}

	.checkboxPrice {
		display: none;
	}

	.label ~ .showPrice {
		margin-top: 1px;
	}
}

.showPrice {
	display: none;
}

.checkboxPrice {
	display: flex;
	gap: 8px;
}

.container {
	display: flex;
	position: relative;
	align-items: center;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer !important; // overwritten somewhere........
}

.checkboxPriceBuy {
	flex-direction: row-reverse;
	text-align: right;
	justify-content: end;
	align-items: center;
	border-radius: 0 12px 12px 0;
}
.checkboxPriceSell {
	text-align: left;
	justify-content: start;
	align-items: center;
	border-radius: 12px 0 0 12px;
}

/* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkbox {
	position: absolute;
	top: 0;
	left: 0;
	height: 28px;
	width: 28px;
	background-color: var(--surface-primary);
	border: 1px solid var(--border-secondary);
	border-radius: 15px;
	cursor: pointer;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkbox {
	background-color: var(--surface-primary); /* background-color-gray-5 */
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkbox {
	background-color: var(--constant-light);
	border: 1px solid var(--constant-light);
}

/* Create the checkbox/indicator (hidden when not checked) */
.checkbox:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkbox when checked */
.container input:checked ~ .checkbox:after {
	display: block;
}

.instrumentGreen {
	// For some reason extracting the common styles outside of Instrument* doesn't work :(
	.container .checkbox:after {
		border: solid var(--border-positive);
		left: 7px;
		top: 3px;
		width: 10px;
		height: 15px;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

.instrumentRed {
	// For some reason extracting the common styles outside of Instrument* doesn't work
	.container .checkbox:after {
		border: solid var(--border-negative);
		left: 7px;
		top: 3px;
		width: 10px;
		height: 15px;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

.sellLabel {
	font-size: var(--fs-15);
	font-weight: normal;
}

.sellLabelModal {
	@extend .sellLabel;
	margin-right: 45px;
}

.tooltipTimestamp {
	font-size: 11px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.36;
	letter-spacing: -0.3px;
	color: var(--text-secondary);
	margin: 5px;

	.tooltipTitle {
		display: flex;
		font-size: 13px;
		font-weight: bold;
		letter-spacing: -0.36px;
		color: var(--text-primary); /* //color-dark */

		.closedMarketContainer {
			display: flex;
			align-content: center;
			margin-left: 5px;

			.closedMarket {
				margin-left: 5px;
				color: var(--watermelon);
			}
		}
	}
}

html[dir='rtl'] {
	.borderButtonSell {
		border-top-right-radius: 14px;
		border-bottom-right-radius: 14px;
		border-top-left-radius: initial;
		border-bottom-left-radius: initial;
	}

	.borderButtonBuy {
		border-top-left-radius: 14px;
		border-bottom-left-radius: 14px;
		border-top-right-radius: initial;
		border-bottom-right-radius: initial;
	}

	.tickerContainerBuy {
		padding-right: 5px;
	}

	.timeUpdate {
		direction: ltr;
	}

	.notOrder .tickerActions .tickerContainer {
		&:first-child {
			padding-left: 2px;
		}

		&:last-child {
			padding-right: 2px;
		}
	}

	.sellNumber {
		div {
			display: flex;
			flex-direction: row-reverse;
		}
	}
}
