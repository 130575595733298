.tier {
	width: 18px;
	height: 18px;
}

.groupIcon {
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: center;
	background-size: auto;
	display: inline-block;
	-webkit-background-size: auto;
	-moz-background-size: auto;
	-ms-background-size: auto;
	-o-background-size: auto;
	cursor: pointer;
	margin-right: 4px;

	&.commodities {
		background-image: url('../../../images/commodities.svg');
	}

	&.crypto {
		background-image: url('../../../images/crypto.svg');
	}

	&.cfd {
		background-image: url('../../../images/et-fs.svg');
	}

	&.etf {
		background-image: url('../../../images/et-fs.svg');
	}

	&.energy {
		background-image: url('../../../images/energy.svg');
	}

	&.forex {
		background-image: url('../../../images/forex.svg');
	}

	&.indices {
		background-image: url('../../../images/indices.svg');
	}

	&.metals {
		background-image: url('../../../images/metals.svg');
	}

	&.stocks,
	&.equities {
		background-image: url('../../../images/stocks.svg');
	}

	// Tier 1
	&.tier1 {
		background-image: url('../../../images/tiers/tier1.svg');
	}

	// Tier 2
	&.tier2 {
		background-image: url('../../../images/tiers/tier2.svg');
	}

	// Tier 3
	&.tier3 {
		background-image: url('../../../images/tiers/tier3.svg');
	}
}

html[dir='rtl'] {
	.groupIcon {
		margin-right: 0;
	}
}
