.disclaimerMessage {
	display: flex;
	align-items: center;
	min-height: 32px;
	width: 100%;
	padding-left: 12px;
	color: var(--text-primary); //color--gray-10);
	font-size: var(--font-size-11);
	color: var(--text-primary); /* //color-dark */
	&:hover {
		cursor: pointer;
	}
}
