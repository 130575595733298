.popupContainer {
	padding: 20px;

	z-index: 99;

	.title {
		font-size: var(--font-size-20);
		font-weight: bold;
	}

	.content {
		font-size: var(--font-size-13);
		padding: 12px 0;
	}

	.buttonsContainer {
		display: flex;
		flex-flow: row nowrap;
		gap: 12px;
		justify-content: space-between;
	}
}
