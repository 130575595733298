.containter {
	// display: flex;
	// flex-direction: column;
	font-size: var(--font-size-13);
	color: var(--text-primary); /* //color-dark */

	.notificationHeader {
		font-weight: bold;
	}
}

.completeAppBtn {
	width: unset !important;
	max-width: unset !important;
}

.marginRight {
	margin-right: 7px !important;
}

.marginRightSafari {
	position: inherit !important;
	right: -40px !important;
}
