$slider-color: var(--surface-accent);
$slider-handle-border: var(--gray-10);
$slider-handle-color: var(--constant-light) var(--slider-handle-gradient);

:global {
	.rc-slider {
		position: relative;
		width: 100%;
		height: 14px;
		padding: 5px 0;
		border-radius: 6px;
		touch-action: none;
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.rc-slider * {
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.rc-slider-rail {
		position: absolute;
		width: 100%;
		height: 4px;
		background-color: #e9e9e9;
		border-radius: 6px;
	}

	.rc-slider-track {
		position: absolute;
		height: 4px;
		background-color: $slider-color;
		border-radius: 8px;
		z-index: 1;
	}

	.rc-slider-handle {
		position: absolute;
		width: 20px;
		height: 20px;
		margin-top: -8px;
		box-shadow: var(--box-shadow);
		background: $slider-handle-color;
		border: solid 1px $slider-handle-border;
		border-radius: 50%;
		cursor: pointer;
		cursor: -webkit-grab;
		cursor: grab;
		z-index: 1;
		touch-action: pan-x;
	}

	.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
		border-color: var(--border-accent); //border-topaz
		box-shadow: 0 0 0 5px var(--light-blue);
	}

	.rc-slider-handle:focus {
		outline: none;
	}

	.rc-slider-handle:focus-visible {
		border-color: var(--border-accent); //border-topaz
		box-shadow: 0 0 0 3px var(--light-blue);
	}

	.rc-slider-handle-click-focused:focus {
		border-color: var(--border-accent); //border-topaz
		box-shadow: unset;
	}

	.rc-slider-handle:hover {
		border-color: var(--border-accent); //border-topaz
	}

	.rc-slider-handle:active {
		border-color: var(--light-blue);
		box-shadow: 0 0 5px var(--light-blue);
		cursor: -webkit-grabbing;
		cursor: grabbing;
	}

	.rc-slider-mark {
		position: absolute;
		top: 18px;
		left: 0;
		width: 100%;
		font-size: 12px;
	}

	.rc-slider-mark-text {
		position: absolute;
		display: inline-block;
		color: var(--gray-30);
		text-align: center;
		vertical-align: middle;
		cursor: pointer;
		white-space: nowrap;
	}

	.rc-slider-mark-text-active {
		color: var(--text-primary); /* //color-dark */
	}

	.rc-slider-step {
		position: absolute;
		width: 100%;
		height: 4px;
		background: var(--gray-30);
		border-radius: 8px;
	}

	.rc-slider-dot {
		position: absolute;
		bottom: -2px;
		width: 8px;
		height: 8px;
		vertical-align: middle;
		background-color: var(--gray-30);
		border: 2px solid var(--gray-30);
		border-radius: 50%;
		cursor: pointer;
	}

	.rc-slider-dot-active {
		background-color: var(--surface-accent);
		border-color: var(--border-accent);
	}

	.rc-slider-dot-reverse {
		margin-right: -4px;
	}

	.rc-slider-disabled {
		background-color: #e9e9e9;
	}

	.rc-slider-disabled .rc-slider-track {
		background-color: #ccc;
	}

	.rc-slider-disabled .rc-slider-handle,
	.rc-slider-disabled .rc-slider-dot {
		background-color: #fff;
		border-color: #ccc;
		box-shadow: none;
		cursor: not-allowed;
	}

	.rc-slider-disabled .rc-slider-mark-text,
	.rc-slider-disabled .rc-slider-dot {
		cursor: not-allowed !important;
	}

	.rc-slider-vertical {
		width: 14px;
		height: 100%;
		padding: 0 5px;
	}

	.rc-slider-vertical .rc-slider-rail {
		width: 4px;
		height: 100%;
	}

	.rc-slider-vertical .rc-slider-track {
		bottom: 0;
		left: 5px;
		width: 4px;
	}

	.rc-slider-vertical .rc-slider-handle {
		margin-top: 0;
		margin-left: -5px;
		touch-action: pan-y;
	}

	.rc-slider-vertical .rc-slider-mark {
		top: 0;
		left: 18px;
		height: 100%;
	}

	.rc-slider-vertical .rc-slider-step {
		width: 4px;
		height: 100%;
	}

	.rc-slider-vertical .rc-slider-dot {
		margin-left: -2px;
	}

	.rc-slider-tooltip-zoom-down-enter,
	.rc-slider-tooltip-zoom-down-appear {
		display: block !important;
		animation-duration: 0.3s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}

	.rc-slider-tooltip-zoom-down-leave {
		display: block !important;
		animation-duration: 0.3s;
		animation-fill-mode: both;
		animation-play-state: paused;
	}

	.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
	.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
		animation-name: rcSliderTooltipZoomDownIn;
		animation-play-state: running;
	}

	.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
		animation-name: rcSliderTooltipZoomDownOut;
		animation-play-state: running;
	}

	.rc-slider-tooltip-zoom-down-enter,
	.rc-slider-tooltip-zoom-down-appear {
		transform: scale(0, 0);
		animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	}

	.rc-slider-tooltip-zoom-down-leave {
		animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	}

	@keyframes rcSliderTooltipZoomDownIn {
		0% {
			transform: scale(0, 0);
			transform-origin: 50% 100%;
			opacity: 0;
		}
		100% {
			transform: scale(1, 1);
			transform-origin: 50% 100%;
		}
	}

	@keyframes rcSliderTooltipZoomDownOut {
		0% {
			transform: scale(1, 1);
			transform-origin: 50% 100%;
		}
		100% {
			transform: scale(0, 0);
			transform-origin: 50% 100%;
			opacity: 0;
		}
	}

	.rc-slider-tooltip {
		position: absolute;
		top: -9999px;
		left: -9999px;
		visibility: visible;
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.rc-slider-tooltip * {
		box-sizing: border-box;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.rc-slider-tooltip-hidden {
		display: none;
	}

	.rc-slider-tooltip-placement-top {
		padding: 4px 0 8px 0;
	}

	.rc-slider-tooltip-inner {
		min-width: 24px;
		height: 24px;
		padding: 6px 2px;
		color: #fff;
		font-size: 12px;
		line-height: 1;
		text-align: center;
		text-decoration: none;
		background-color: #6c6c6c;
		border-radius: 6px;
		box-shadow: 0 0 4px #d9d9d9;
	}

	.rc-slider-tooltip-arrow {
		position: absolute;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}

	.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
		bottom: 4px;
		left: 50%;
		margin-left: -4px;
		border-width: 4px 4px 0;
		border-top-color: #6c6c6c;
	}
}
