$view-details-link-height: 25px;

.container {
	display: flex;
	flex-direction: column;
	align-items: center;

	.details {
		height: $view-details-link-height;
	}

	.viewDetails {
		height: $view-details-link-height;
		color: var(--text-accent); //color-topaz
		font-size: var(--font-size-15);
		cursor: pointer;
	}

	.noDetailsTopMargin {
		margin-top: $view-details-link-height;
	}

	.caroucel {
		align-self: center;
		display: flex;
		justify-content: space-evenly;
		width: 111px;
		padding: 16px 0 12px 0;

		.carouselDot {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			color: var(--icon-secondary); //color--gray-10);
			background-color: var(--icon-tertiary);
			cursor: pointer;
			-webkit-transition: background-color 250ms ease-out;
			-ms-transition: background-color 250ms ease-out;
			transition: background-color 250ms ease-out;

			&.active {
				-webkit-transition: background-color 250ms ease-in;
				-ms-transition: background-color 250ms ease-in;
				transition: background-color 250ms ease-in;

				background-color: var(--icon-secondary);
			}
		}
	}
}
