$animated-container-height: 255px;

.container {
	display: flex;
	flex-direction: column;
	padding-bottom: 12px;
	width: 100%;
	font-size: var(--font-size-13);
	color: var(--text-primary); /* //color-dark */
	background-color: var(--surface-primary);
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */

	&.easeInAnimation {
		animation: inAnimation 260ms ease-in;
	}

	&.easeOutAnimation {
		animation: outAnimation 270ms ease-out;
	}

	.label {
		color: var(--text-secondary);

		&.padded {
			padding: 12px 0 4px 0;
		}
	}

	.slider {
		padding: 18px 10px;

		:global {
			.rc-slider-mark-text {
				font-size: var(--font-size-11);

				&:first-of-type {
					// important is used to overwrite the original inline styling
					transform: translateX(-20%) !important;
				}

				&:last-of-type {
					// important is used to overwrite the original inline styling
					transform: translateX(-80%) !important;
				}
			}
		}
	}
	:global {
		.ui.selection.dropdown {
			border-radius: 4px;
			font-weight: normal;
			margin: 0;
			background-color: var(--surface-primary);
			text-align: center;
			padding: 10px;
		}
	}
}

@keyframes inAnimation {
	0% {
		height: 0;
		opacity: 0;
		padding-top: 0px;
		padding-bottom: 0px;
	}
}

@keyframes outAnimation {
	0% {
		opacity: 1;
		padding-top: 20px;
		padding-bottom: 20px;
		height: $animated-container-height;
	}
	100% {
		padding-top: 0px;
		padding-bottom: 0px;

		opacity: 0;
		height: 0;
	}
}

html[dir='rtl'] {
	.container {
		left: unset;
		right: 0;
		.slider {
			padding: 18px 5px;

			:global {
				.rc-slider-mark-text {
					font-size: var(--font-size-11);
					&:first-of-type {
						// important is used to overwrite the original inline styling
						transform: translateX(50%) !important;
					}

					&:last-of-type {
						// important is used to overwrite the original inline styling
						transform: translateX(50%) !important;
					}
				}
			}
		}
	}
}
