@import 'src/css/common/_custom_scrollbar';

.dropdown {
	width: 100%;
	height: 40px;
	display: flex;
    flex-wrap: wrap;
    position: relative;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;
	color: var(--text-primary);
	background-color: var(--surface-primary); /* background-color-light */
	border-radius: 8px;
	&:hover {
		background-color: var(--surface-tertiary);
	}

	.dropdownIconWrapper {
		position: absolute;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 12px;
		transition: 0.5s;
		width: 40px;
		height: 40px;
	}
}

.dropdownError {
	@extend .dropdown;
	border: 1px solid var(--watermelon);
}

.dropdownIconWrapper.active {
	transform: rotate(-180deg);
	background-color: var(--surface-primary); /* background-color-gray-5 */
}

.dropdown input {
	cursor: pointer;
	font-size: 15px;
	display: flex;
	align-items: center;
	width: 100%;
	text-align: center;
	color: var(--text-primary); /* //color-dark */
	background-color: transparent; /* background-color-light */
	border: none;
	outline: none;
	padding: 12px 20px;
	border-radius: 8px;
}

.inputSkeleton {
	position: absolute;
	top: 2px;
	height: 25px;
	width: 125px;
}

.dropdown.active input {
	background-color: var(--surface-primary); /* background-color-gray-5 */
}

.options {
	@extend %custom-scrollbar-element;
	position: absolute;
	z-index: 1001;
	top: 100%;
	width: 100%;
	max-height: 165px;

	font-size: 15px;
	text-align: center;
	color: var(--text-primary); /* //color-dark */
	background-color: var(--menu-primary); /* background-color-light */
	overflow-x: hidden;
	overflow-y: auto;
	display: none;
	padding: 12px;
	border-radius: 12px;
    box-shadow: var(--box-shadow-S);
}

.dropdown.active .options {
	display: block;
}

.option {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 12px;
	cursor: pointer;
	height: 40px;
	border-radius: 8px;
	text-align: center;

	&:hover {
		background-color: var(--surface-low); /* background-color-gray-5 */
	}
}
.optionSelected{
	@extend .option;
	background-color: var(--surface-low);
	border: 1px solid var(--border-accent);
}

.pound {
	margin-right: 4px;
}
.points {
	font-size: 11px;
	color: var(--text-secondary);
	margin-left: 4px;
}
