%input {
	border-radius: 12px;
	background-color: var(--surface-primary) !important;
	box-shadow: var(--input-field-box-shadow);
	color: var(--text-primary); /* //color-dark */
	border: 1px solid var(--border-primary) !important;

	&:focus,
	&:active {
		border: 1px solid var(--border-accent) !important;
		caret-color: var(--text-accent);
		outline: none;
	}
}

%cost-input {
	@extend %input;

	max-width: 79%;
	width: 178px;
	height: 30px;
}
