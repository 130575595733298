@import 'src/css/common/_btn_styled_as_link.scss';

.wrapper {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	padding: 3px 16px;
	position: relative;
	background-color: var(--surface-primary);
	cursor: pointer;
	white-space: nowrap;
	line-height: 15px;
	transition: all ease 0.2s;
	border-right: 1px solid var(--border-primary);
	height: 36px;

	&:hover,
	&.isOpen {
		color: var(--text-primary); //color--gray-10);
	}

	&.green {
		.statusIndicator {
			background-color: var(--surface-positive); /* background-color-positive */
		}
	}

	&.orange {
		.statusIndicator {
			background-color: var(--orange);
		}
	}

	&.red {
		.statusIndicator {
			background-color: var(--surface-negative); /* background-color-negative */
		}
	}

	&.disabled {
		.statusIndicator {
			background-color: var(--gray-30);
		}
	}
}

.statsLabel {
	font-size: var(--font-size-11);
	color: var(--text-secondary);

	line-height: 13px;
}

.marginPercent {
	font-size: var(--font-size-13);
	margin: 0 4px;
}

.valueContainer {
	display: flex;
	align-items: baseline;
	color: var(--text-primary); /* //color-dark */
}

.statusIndicator {
	height: 8px;
	width: 8px;
	border-radius: 50px;
	background-color: var(--surface-positive); /* background-color-positive */
}

.toggleIcon {
	height: 11px;
}

.popup {
	background-color: var(--surface-primary);
	width: 285px;
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 50%;
	transform: translate(-50%, 0);
	border-radius: 4px;
	box-shadow: var(--box-shadow-S);
	padding: 15px 20px 20px 20px;
	display: flex;
	flex-direction: column;

	cursor: default;

	.progressBarContainer {
		display: flex;
		flex-direction: column;
		align-items: center;

		&.green {
			.progressPercent {
				color: var(--text-positive); /* color-positive*/
			}

			:global {
				.ui.progress .bar {
					background: var(--surface-positive); /* background-color-positive*/
				}
			}
		}

		&.orange {
			.progressPercent {
				color: var(--orange);
			}

			:global {
				.ui.progress .bar {
					background: var(--orange);
				}
			}
		}

		&.red {
			.progressPercent {
				color: var(--text-negative); /* color-negative*/
			}

			:global {
				.ui.progress .bar {
					background: var(--button-negative-secondary);
				}
			}
		}

		&.disabled {
			.progressPercent {
				color: var(--gray-30);
			}

			:global {
				.ui.progress .bar {
					background: var(--gray-30);
				}
			}
		}

		.progressPercent {
			font-size: 15px;
			font-weight: bold;
			margin-bottom: 6px;
		}

		.progressWrapper {
			width: 100%;

			:global {
				.ui.progress {
					height: auto;
					margin: 0;
					width: 100%;
					background: var(--gray-30);

					.bar {
						min-width: 5px;
					}
				}
			}
		}
	}

	.viewDetailsContainer {
		padding: 12px 0;
		text-align: center;

		.tieName {
			color: var(--text-secondary);
		}

		.btnViewDetails {
			@extend %buttonStyledAsLink;

			margin-left: 10px;
		}
	}

	.contentContainer {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid var(--gray-10);
		padding: 12px 0;

		.remainingVolume {
			font-size: var(--font-size-15);
			font-weight: bold;

			&.green {
				color: var(--text-positive); /* color-positive*/
			}

			&.orange {
				color: var(--orange);
			}

			&.red {
				color: var(--text-negative); /* color-negative*/
			}
		}

		.values {
			font-weight: bold;
		}

		&:last-of-type {
			border-bottom: none;
		}
	}
}

html[dir='rtl'] {
	.wrapper {
		&:not(:first-child) {
			border-left: none;
			border-right: 1px solid var(--border-primary);
		}

		&:last-child {
			border-radius: 4px 0 0 4px;
		}
	}

	.values {
		direction: ltr;
	}

	.dividerShort {
		left: 0;
		right: 25%;
	}
}
