.wrapper {
	width: 100%;
	background-color: var(--surface-primary);
	display: flex;
	flex-direction: column;
	z-index: 99;
	gap: 8px;
	padding: 8px 12px;
	border-bottom: 1px solid var(--border-primary);
	.bidAskSpreadButtons {
		width: 100%;
		max-width: unset;
	}

	&.undocked {
		padding: 16px 16px 8px;
		border-radius: 20px 20px 0 0;
	}
}

.resizedWrapper {
	@extend .wrapper;
	padding: 8px 12px;
}

.headerContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.title {
	display: flex;
	justify-content: center;
	font-size: var(--fs-15);
	gap: 4px;
	font-weight: bold;
	align-items: center;
	color: var(--text-primary); /* //color-dark */
}

.instrumentFullName {
	font-weight: normal;
	letter-spacing: -0.27px;
	font-size: var(--font-size-11);
	max-width: 285px;
	color: var(--text-secondary);
	text-align: left;
}

.iconContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	height: 28px;
	width: 28px;

	&:hover {
		background-color: var(--surface-tertiary);
		border-radius: 50%;
	}
}

.infoIcon {
	cursor: pointer;
	font-size: var(--fs-20);
	height: 28px;
	width: 28px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: var(--surface-tertiary);
		border-radius: 20px;
		transition: all 0.15s ease-in;
	}
}

// .badge {
// 	width: 24px;
// 	height: 24px;
// }

.iconGroup {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 24px;
	height: 24px;

	&:hover {
		background-color: var(--surface-tertiary);
		border-radius: 50%;
	}
}

.group {
	font-size: 20px;
	margin-right: 10px;
	color: black;
	cursor: pointer;
}

.changes {
	font-size: 13px;
	color: var(--text-secondary);
	letter-spacing: -0.31px;
	margin: 7px 12px;
	display: flex;
	flex-direction: row;
}

.text {
	color: #13c0a0;

	font-size: 13px;
	letter-spacing: -0.31px;
}

.textTitleHigh {
	color: var(--text-secondary);
	font-size: 13px;
	letter-spacing: -0.31px;
}

.textTitleLow {
	@extend .textTitleHigh;
}

.icon {
	color: var(--text-primary); /* //color-dark */
	cursor: pointer;
	transition: all 0.15s ease-in;
	position: relative;
	font-size: 16px;

	&:hover,
	&:active {
		color: var(--text-primary) !important; /* //color-dark */
	}
}

.iconDetached {
	font-size: 20px;
}

.iconAtached {
	font-size: 18px;
}

.darkiconTheme {
	color: var(--constant-light) !important;
	font-size: 15px;
	font-weight: bold;
	cursor: pointer;
	transition: all 0.15s ease-in;
	position: relative;

	&:hover,
	&:active {
		filter: brightness(0.5);
	}
}

.addToWatchlistIcon {
	cursor: pointer;
}

.watchlistsListContainer {
	background-color: var(--surface-primary); /* background-color-gray-5 */
	height: 230px;
	width: 300px;
	border-radius: 2px;
	display: flex;
	flex-direction: column;
}

.addIcon {
	margin-left: 10px;
	cursor: pointer;
	color: var(--text-primary); /* //color-dark */
}

.disabledAddIcon {
	@extend .addIcon;
	opacity: 0.3;
	cursor: text;
}

.create {
	cursor: pointer;
	margin-left: 6px;
}

.createErrorText {
	@extend .create;
	opacity: 0.5;
	cursor: text;
}

//Styling for Create watchlist popup
.createContainer {
	position: absolute;
	top: 40px;
	right: -40px;
	width: 300px;
	height: 230px;
	background-color: var(--surface-primary);
	box-shadow: 0 2px 4px -1px var(--box-shadow-5), 0 1px 10px 0 var(--box-shadow-6), 0 4px 5px 0 var(--box-shadow-7);
	z-index: 3;
	display: flex;
	flex-flow: column nowrap;
}

.createHeader {
	height: 38px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	color: var(--text-primary); /* //color-dark */
	font-size: 12px;
	font-weight: bold;
	letter-spacing: -0.31px;
	border-bottom: 1px solid var(--gray-10);
	flex-grow: 0;
}

.inputContainer {
	padding: 10px 20px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	flex-grow: 1;
}

.inputTitle {
	color: #8d9396;
	font-size: 11px;
}

.input {
	height: 30px;
	border-radius: 12px;
	border: solid 1px var(--gray-10);
	background-color: var(--surface-primary);
	color: var(--text-primary); /* //color-dark */
	box-shadow: var(--input-field-box-shadow);
	caret-color: var(--icon-accent);

	&:active,
	&:focus {
		background-color: var(--surface-primary);
		color: var(--text-primary); /* //color-dark */
		border: solid 1px var(--topaz);
		outline: none;
	}
}

.errorInput {
	@extend .input;
	border: solid 1px #f74760;
}

.inputCount {
	font-size: 13px;
	color: var(--color-grey-6);
}

.messageRow {
	width: 100%;
	margin-top: 2px;
	display: flex;
	flex-flow: row-reverse nowrap;
}

.errorMessage {
	font-size: 12px;

	position: absolute;
	left: 10px;
	color: #f74760;
	width: 78%;
}

.buttons {
	display: flex;
	justify-content: center;
	width: 100%;
}

.buttonContainer {
	padding: 9px;
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	border-radius: 3px;
	box-shadow: var(--box-shadow-reversed);
	flex-grow: 0;

	button {
		width: 45%;
		max-width: none;
	}
}

.highLowBox {
	width: 100%;
	display: flex;
	justify-content: space-between;
	pointer-events: none;

	.lowValue {
		color: var(--text-negative); /* color-negative*/
	}

	.highValue {
		color: var(--text-positive); /* color-positive*/
	}
}

.topIcon {
	width: 18px;
	height: 18px;
	cursor: pointer;
	color: var(--text-primary); /* //color-dark */
}

.watchlistItem {
	width: 10rem;
	height: 100%;
	align-items: center;
	overflow-x: hidden;
	text-overflow: ellipsis;
	cursor: default;
}

.watchlistIcon {
	font-size: 1.3em !important;
	cursor: pointer;
}

.existsInWatchlistIcon {
	@extend .watchlistIcon;
	color: var(--icon-accent); /* color-mint */
	cursor: pointer;
}

.addToWatchlistIcon {
	@extend .watchlistIcon;
	color: var(--text-primary); /* //color-dark */
}

.instrumentStats {
	font-size: var(--font-size-13);
	display: flex;
	align-items: center;

	.sessionIcon {
		margin-left: 5px;
		cursor: pointer;
		font-size: var(--font-size-12);
		color: var(--text-secondary);
	}
}

html[dir='rtl'] {
	.iconGroup {
		margin: 16px 0 0 11px;
	}

	.textTitleHigh {
		margin: 0 0 0 10px !important;
	}

	.textTitleLow {
		margin: 0 10px 0 0;
	}

	.buttons {
		margin: 0 0 0 7px;
	}

	.highLowBox {
		width: 97%;
		margin-right: 10px;
	}

	.watchlistItem {
		margin-right: 10px;
	}

	.addIcon {
		margin: 0 7px;
	}

	.inputCount {
		float: left;
		margin-left: 10px;
	}

	.buttonContainer {
		justify-content: space-between;
	}

	.createContainer {
		left: 10px;
		right: initial;
	}

	.errorMessage {
		right: 10px;
		left: initial;
	}
}
