.small {
	font-size: 24px;
	cursor: pointer;
	color: var(--icon-primary);
}

.large {
	font-size: 32px;
	color: var(--text-primary); /* //color-dark */
}
