.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	border-left: 3px solid var(--border-primary);
}

.header {
	padding: 0px 12px;
	border-bottom: 1px solid var(--border-primary);
	font-size: var(--fs-13);
	font-weight: bold;
	color: var(--text-primary); /* //color-dark */
	background-color: var(--surface-primary);
	height: 40px;
	display: flex;
	align-items: center;
}

.gateContainer {
	position: relative;
	width: 100%;
	height: 100%;
}

.fakeList,
.gate {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.fakeList {
	filter: blur(4px);
	opacity: 0.3;
	transform: translate3d(0, 0, 0);
}

.gate {
	z-index: 2;
}

html[dir='rtl'] {
	.container {
		border-right: 3px solid var(--border-primary); /* border-right-gray-30 */
		border-right: 0;
	}
}
