.divider {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	.text {
		font-family: var(--font-family-all-languages);
		color: var(--text-secondary);
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: 0 10px;
		user-select: none;
		text-transform: capitalize;
		white-space: nowrap;
	}

	.line {
		background: var(--gray-10);
		height: 1px;
		width: 100%;
	}
}
