body.isJapanese .dark {
	//Dark Japan colors
	--border-accent: var(--blue-600);
	--border-accent-secondary: var(--blue-950);
	--border-alert: var(--red-500);
	--border-invert: var(--gray-150);
	--border-negative: var(--green-500);
	--border-positive: var(--red-500);
	--border-primary: var(--gray-750);
	--border-quaternary: var(--gray-200);
	--border-secondary: var(--gray-650);
	--border-success: var(--green-500);
	--border-tertiary: var(--gray-550);
	--button-negative: var(--green-600);
	--button-negative-hover: var(--green-500);
	--button-negative-secondary: var(--green-950);
	--button-neutral: var(--gray-50);
	--button-neutral-hover: var(--gray-150);
	--button-positive: var(--red-600);
	--button-positive-hover: var(--red-500);
	--button-positive-secondary: var(--red-950);
	--button-primary: var(--blue-600);
	--button-primary-hover: var(--blue-500);
	--constant-dark: var(--gray-100);
	--constant-light: var(--gray-0);

	--icon-accent: var(--blue-500);
	--icon-alert: var(--red-500);
	--icon-demo: var(--blue-500);
	--icon-invert: var(--gray-850);
	--icon-live: var(--green-500);
	--icon-negative: var(--green-500);
	--icon-positive: var(--red-500);
	--icon-primary: var(--gray-50);
	--icon-secondary: var(--gray-500);
	--icon-success: var(--green-500);
	--icon-tertiary: var(--gray-700);
	--input-alert: var(--red-950);
	--input-primary: var(--gray-800);
	--input-secondary: var(--gray-750);
	--input-tertiary: var(--gray-550);
	--input-low: var(--gray-900);

	--menu-primary: var(--gray-800);

	--surface-sufrace-accent: var(--blue-600);
	--surface-accent-secondary: var(--blue-950);
	--surface-alert: var(--red-500);
	--surface-alert-secondary: var(--red-950);
	--surface-indifferent: var(--orange-500);
	--surface-invert: var(--gray-0);
	--surface-low: var(--gray-900);
	--surface-negative: var(--green-500);
	--surface-overlay: var(--transparent-dark-60);
	--surface-positive: var(--red-500);
	--surface-primary: var(--gray-850);
	--surface-quaternary: var(--gray-550);
	--surface-secondary: var(--gray-800);
	--surface-succes-secondary: var(--green-950);
	--surface-success: var(--green-500);
	--surface-tertiary: var(--gray-700);
	--surface-transparent: var(--transparent-dark-30);

	--text-accent: var(--blue-500);
	--text-alert: var(--red-500);
	--text-demo: var(--blue-500);
	--text-indifferent: var(--orange-500);
	--text-invert: var(--gray-850);
	--text-live: var(--green-500);
	--text-negative: var(--green-600);
	--text-positive: var(--red-500);
	--text-primary: var(--gray-50);
	--text-secondary: var(--gray-350);
	--text-success: var(--green-500);
	--text-tertiary: var(--gray-500);

	--bs-info-rgb: 76, 132, 238;
}

body.isJapanese {
	//Light Japan colors
	--border-accent: var(--blue-600);
	--border-accent-secondary: var(--blue-50);
	--border-alert: var(--red-600);
	--border-invert: var(--gray-800);
	--border-negative: var(--green-700);
	--border-positive: var(--red-600);
	--border-primary: var(--gray-150);
	--border-quaternary: var(--gray-700);
	--border-secondary: var(--gray-250);
	--border-success: var(--green-600);
	--border-tertiary: var(--gray-350);
	--button-negative: var(--green-700);
	--button-negative-hover: var(--green-600);
	--button-negative-secondary: var(--green-50);
	--button-neutral: var(--gray-850);
	--button-neutral-hover: var(--gray-700);
	--button-positive: var(--red-700);
	--button-positive-hover: var(--red-600);
	--button-positive-secondary: var(--red-50);
	--button-primary: var(--blue-700);
	--button-primary-hover: var(--blue-600);

	--constant-dark: var(--gray-850);
	--constant-light: var(--gray-50);

	--icon-accent: var(--blue-600);
	--icon-alert: var(--red-600);
	--icon-demo: var(--blue-600);
	--icon-invert: var(--gray-50);
	--icon-live: var(--green-600);
	--icon-negative: var(--green-600);
	--icon-positive: var(--red-600);
	--icon-primary: var(--gray-850);
	--icon-secondary: var(--gray-500);
	--icon-success: var(--green-600);
	--icon-tertiary: var(--gray-300);

	--input-alert: var(--red-50);
	--input-primary: var(--gray-100);
	--input-secondary: var(--gray-150);
	--input-tertiary: var(--gray-350);
	--input-low: var(--gray-100);

	--menu-primary: var(--gray-0);

	--surface-accent: var(--blue-600);
	--surface-accent-secondary: var(--blue-50);
	--surface-alert: var(--red-600);
	--surface-alert-secondary: var(--red-50);
	--surface-indifferent: var(--orange-600);
	--surface-invert: var(--gray-850);
	--surface-low: var(--gray-100);
	--surface-negative: var(--green-600);
	--surface-overlay: var(--transparent-dark-40);
	--surface-positive: var(--red-600);
	--surface-primary: var(--gray-0);
	--surface-quaternary: var(--gray-350);
	--surface-secondary: var(--gray-50);
	--surface-succes-secondary: var(--green-50);
	--surface-success: var(--green-600);
	--surface-tertiary: var(--gray-200);
	--surface-transparent: var(--transparent-light-30);

	--text-accent: var(--blue-600);
	--text-alert: var(--red-600);
	--text-demo: var(--blue-600);
	--text-indifferent: var(--orange-600);
	--text-invert: var(--gray-50);
	--text-live: var(--green-600);
	--text-negative: var(--green-700);
	--text-positive: var(--red-600);
	--text-primary: var(--gray-850);
	--text-secondary: var(--gray-550);
	--text-success: var(--green-600);
	--text-tertiary: var(--gray-400);
}
