.container {
	padding: 12px;
	flex: 0 0 100%;

	&:hover,
	&.showActions {
		.TickerIcons {
			display: block;
		}

		.equityField {
			display: none;
		}
	}

	&.instrumentHeader {
		padding: 6px 1px 6px 5px;
	}
}

.rightHandContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.windowContainer {
	padding: 11px;
	flex: 0 0 100%;

	@media screen and (max-width: 1024px) {
		flex: 0 0 99%;
	}

	@media screen and (max-width: 665px) {
		flex: 0 0 100%;
	}
}

.invisible {
	opacity: 0;
}

.nonHighlightSelected {
	background-color: var(--surface-primary);
	border-bottom: 1px solid var(--border-primary);

	&:hover {
		background-color: var(--surface-low); /* background-color-gray-5 */
	}
}

.highlightSelected {
	color: var(--text-primary); //color--gray-10);
	border: 1px solid var(--border-accent);
	background-color: var(--surface-low); /* background-color-gray-5 */
}

.darkthighlightSelected {
	background-color: black;
}

.lightContainer:not(:last-of-type) {
	border-bottom: 1px solid var(--color-neutral-2);
}

.darkContainer:not(:last-of-type) {
	border-bottom: 1px solid var(--color-neutral-5);
}

.lighthighlightSelected:not(:last-of-type) {
	border-bottom: 1px solid var(--color-neutral-2);
}

.darkthighlightSelected:not(:last-of-type) {
	border-bottom: 1px solid var(--color-neutral-5);
}

.TickerInfo {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.windowTickerInfo {
	display: flex;

	@media screen and (max-width: 1023px) {
		justify-content: space-between;
	}

	@media screen and (min-width: 1024px) {
		justify-content: space-between;
		margin-right: 10px;
	}
}

.tickerContainer {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
	margin-right: 4px;
	flex-grow: 1;
	justify-content: center;
	min-width: 85px;
}

.iconContainer {
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
}

.infoIcon {
	cursor: pointer;
}

.badge {
	padding: 0 0 3px 0;

	& > img {
		margin-right: 4px;
	}
}

.DotContainer {
	@extend .tickerContainer;
	padding-left: 4%;
}

.TickerActions {
	display: flex;
}

.InstrumentButton {
	border-radius: 2px;
	color: var(--constant-light);
	cursor: pointer;
	font-weight: 700;
	padding: 8px 10px;
	text-align: center;
	width: 60px;
	display: flex;
	justify-content: center;
}

.InstrumentGreen {
	background: var(--color-green-1);

	&:hover {
		background: var(--color-green-2);
	}
}

.InstrumentRed {
	background: var(--color-red-1);

	&:hover {
		background: var(--color-red-2);
	}
}

.InstrumentGrey {
	background: var(--color-grey-1);

	&:hover {
		background: var(--color-grey-2);
	}
}

.instrumentCodeAndSessionIconContainer {
	display: flex;
	align-items: center;
}

.Symbol {
	transition: all 0.15s ease-in;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	font-size: var(--fs-13);
	font-weight: bold;
	color: var(--text-primary); /* //color-dark */
}

.darkSymbol {
	color: var(--constant-light);

	&:hover {
		color: var(--color-neutral-3);
	}
}

.Spread {
	font-size: var(--fs-11);
	margin: 8px 5px;
	min-width: 30px;
	display: flex;
	justify-content: center;
}

.lightSpread {
	color: var(--color-neutral-5);
}

.darkSpread {
	color: var(--constant-light);
}

.tickerChart {
	min-width: 40px;
}

.symbolDescription {
	line-height: 1.25;
	font-size: var(--font-size-11);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: var(--text-secondary);
}

.darkTime {
	color: var(--color-neutral-3);
}

.TickerLowHigh {
	color: var(--color-neutral-3);
	font-size: var(--fs-12);
}

.ColorRed {
	color: var(--color-red-1);
}

.ColorGreen {
	color: var(--color-green-1);
}

.TickerFooter {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
}

.TickerIcons {
	display: none;

	& > * {
		cursor: pointer;
		margin-left: var(--m-16);
	}
}

.lighticonTheme {
	color: var(--color-neutral-3);
	transition: all 0.1s ease-in;

	&:hover {
		color: var(--color-neutral-5);
	}
}

.darkiconTheme {
	color: var(--color-neutral-5);
	transition: all 0.1s ease-in;

	&:hover {
		color: var(--color-neutral-2);
	}
}

.iconClicked {
	color: var(--icon-accent);
}

.informationContainer {
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
	height: 432px;
	left: 365px;
	padding: 20px;
	position: absolute;
	top: 85px;
	width: 320px;
	z-index: 1000;

	@media only screen and (max-width: 1024px) {
		bottom: 0;
		left: 0;
		margin: auto;
		right: 0;
		top: 0;
	}
}

.lightinformationContainer {
	background-color: var(--constant-light);
}

.darkinformationContainer {
	background-color: var(--color-neutral-5);
}

.detailHeader {
	color: white;
	padding-bottom: 7px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	display: flex;
	justify-content: space-between;
}

.closeIcon {
	margin-top: -10px;
}

.detailContainer {
	margin-top: 10px;
	padding: 10px;
}

.detailFieldName {
	padding: 8px 0 8px 0;
	color: #939b9f;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	display: flex;
	justify-content: space-between;
}

.fieldValue {
	color: #ffffff;
}

.equityField {
	font-size: 12px;
	color: #939b9f;
}

.equityTickerFooter {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	width: 225px;
}

.changeLabel {
	color: #06a688;
}

.selectedDot {
	margin-top: 10px;
}

.Dot {
	color: #13c0a0;
}

.instrumentInfo {
	display: flex;
	align-items: center;
}

.bidAskSpreadButtons {
	min-width: 185px;
	max-width: 185px;
}

html[dir='rtl'] {
	.lighthighlightSelected {
		background-color: var(--surface-primary); /* background-color-gray-5 */
		margin-right: 0;
	}

	.tickerContainer {
		&.watchlist {
			margin-right: 3%;
		}
	}

	.badge {
		& > img {
			margin-right: 0;
		}
	}
}
