$checkbox-size: 22px;

.container {
	position: relative;
	display: flex;
	align-items: center;
	align-self: center;
	margin-top: 15px;

	.text {
		order: 3;
		padding-left: 1rem;

		a {
			color: var(--text-accent); //color-topaz
			text-decoration: none;
		}
	}

	.label {
		order: 3;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;

		&.constrainedLabel {
			width: $checkbox-size;
		}
	}

	.icon,
	svg.icon {
		order: 1;
		align-self: flex-start;
		font-size: $checkbox-size;
		color: var(--text-accent); //color-topaz
	}

	.input {
		order: 4;
		width: 0;
		height: 0;
		opacity: 0;
		border: none;

		&:checked {
			~ .unCheckedIcon {
				display: none;
			}
			~ .checkedIcon {
				display: initial;
			}
		}

		&:not(:checked) {
			~ .unCheckedIcon {
				display: initial;
			}
			~ .checkedIcon {
				display: none;
			}
		}
	}

	.unCheckedIcon {
		color: var(--gray-30);
	}

	.checkedIcon {
		color: var(--text-accent); //color-topaz
	}
}

html[dir='rtl'] {
	.container {
		.text {
			padding-right: 1rem;
			padding-left: 0;
		}
	}
}
