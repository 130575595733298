.errorMessage {
	position: relative;
	color: var(--login-error);
	min-height: 1.5em;
	margin: 12px 0 0;
	display: none;
	text-align: left;
	font-size: 14px;
	line-height: 16.8px;
}

.inputBoxErrors {
	margin-top: 12px;
	justify-content: center;
	color: var(--login-error);
	text-align: center;
}
