.navContainer {
	background: var(--surface-primary);
	height: 100%;

	.navMenuItemsContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;

		.mainMenu {
			display: 'flex';
			flex-flow: 'column nowrap';
		}
	}
	.toggles {
		width: 100%;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
	}
}
