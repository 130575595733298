.infoElement {
	display: flex;
	justify-content: space-between;
	padding: 8px 0px;
	font-size: var(--fs-13);
	margin: 0 12px;
	border-bottom: solid 1px var(--border-primary);
}

.infoElementUndock {
	display: flex;
	justify-content: space-between;
	padding: 8px 0px;
	font-size: var(--fs-15);
	margin: 0 4px 0 16px;
	border-bottom: solid 1px var(--border-primary);
}

.infoGroup {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.label {
	// font-size: 13px;
	letter-spacing: -0.36px;
	color: var(--text-secondary);
}
.value {
	// font-size: 13px;
	letter-spacing: -0.36px;
}

.red {
	@extend .value;
	color: var(--text-negative); /* color-negative*/
}
.green {
	@extend .value;
	color: var(--text-positive); /* color-positive*/
}

.popupText {
	// font-size: 13px;
	letter-spacing: -0.31px;
	color: #939b9f;
}

.icon {
	position: relative;
	left: 100px;
	top: 1px;
	color: #939b9f;
	font-size: 16px;
	cursor: pointer;
}
