.watchlistIcon {
	font-size: 18px;
	cursor: pointer;
}

.existsInWatchlistIcon {
	@extend .watchlistIcon;
	color: var(--icon-accent); /* color-mint */
}

.addToWatchlistIcon {
	@extend .watchlistIcon;
	color: var(--icon-accent); /* color-mint */
}
