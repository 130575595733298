.dailyPercentage {
	font-weight: normal;
	margin-left: 5px;

	&.positive {
		color: var(--text-positive); /* color-positive*/
	}

	&.negative {
		color: var(--text-negative); /* color-negative*/
	}
}

html[dir='rtl'] {
	.dailyPercentage {
		margin-left: 0;
		margin-right: 5px;
	}
}
