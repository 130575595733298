.modalDialog {
	width: 463px;
	height: 433px;

	.modalBody {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 20px;
	}

	.footerContainer {
		justify-content: space-between !important;
		border: none !important;
		box-shadow: var(--box-shadow-reversed);
		padding: 20px;

		& > button {
			margin: 0;
		}
	}
}

.feedbackHeader {
	font-size: 16px;
	font-weight: bold;
	padding: 2rem;
	border: none !important;
	box-shadow: var(--box-shadow);
}

.button {
	width: 45%;
	margin: 0;
}

.ratingContainer {
	display: flex;
	flex-direction: column;
	margin-bottom: 35px;
}

.feedbackContainer {
	@extend .ratingContainer;
	margin-bottom: 15px;
}

.questionText {
	font-size: 13px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #8d9396;
	margin-bottom: 10px;
}

.textArea {
	resize: none;
	height: 180px;
	outline: none;
	padding: 15px;
	border-radius: 4px;
	border: solid 1px var(--gray-10);
	background-color: var(--surface-primary); /* background-color-light */
	box-shadow: var(--input-field-box-shadow);
	color: var(--text-primary); /* //color-dark */

	&:active,
	&:focus {
		border: 1px solid var(--border-accent); //border-topaz
	}
}

.submittedContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.thankYouMessage {
	font-size: 22px;
	font-weight: 300;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.28px;
	text-align: center;
	color: var(--text-primary); /* //color-dark */
	margin-top: 20px;
	margin-bottom: 35px;
}

.appreciateMessage {
	@extend .thankYouMessage;
	font-size: 16px;
	letter-spacing: -0.38px;
	margin-top: 30px;
	margin-bottom: 80px;
}

.starContainer {
	color: var(--text-accent); //color-topaz
}

:global {
	.modal.modal-static .modal-dialog {
		transform: none !important;
	}

	.modal-lg,
	.modal-xl {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.feedbackModalTitle {
	width: 100%;
	margin: 0;
	font-size: 16px;
	font-weight: 600;
	text-align: center;
}

.closeIcon {
	position: absolute;
	right: 0;
	cursor: pointer;
	font-size: 19px;
	padding: 0.5rem;
	margin-right: 14px;
	color: var(--text-primary); /* //color-dark */

	box-sizing: border-box;
	width: 2rem;
	height: 2rem;
	padding: 0.25rem;
	// color: #000;
	// background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
	background-color: transparent;
	border: 0;
	border-radius: 0.375rem;
	// opacity: .5;
	outline: none;

	svg {
		height: 100%;
	}
}

.starsImage {
	position: absolute;
	pointer-events: none;
}
