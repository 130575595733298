.searchInputContainer {
	padding: 0.5em;
	white-space: nowrap;
	cursor: text;
}

.searchInput {
	background: transparent;
	border-style: none;
	outline-style: none;
	display: inline-block;
	width: 95%;
	height: 1.5rem;
	padding: 10px 0px;
	caret-color: var(--text-accent);
	color: var(--text-primary);

	&::placeholder {
		color: var(--text-tertiary);
	}

	&:hover,
	&:focus,
	&:active {
		background-color: transparent;
		border-style: none;
	}
}

.searchIcon {
	color: var(--icon-tertiary);
	margin: 0px 5px;
}

.closeIcon {
	color: var(--icon-secondary);
	cursor: pointer;
}
