:global {
	.my-tooltip > .tooltip-inner {
		background: var(--menu-primary) !important;
		color: var(--text-primary);
		box-shadow: var(--box-shadow-S);
	}

	.bs-tooltip-start .tooltip-arrow::before {
		border-left-color: var(--border-primary) !important;
	}

	.bs-tooltip-top .tooltip-arrow::before {
		border-top-color: var(--border-primary) !important;
	}

	.bs-tooltip-bottom .tooltip-arrow::before {
		border-bottom-color: var(--border-primary) !important;
	}
}
