.input {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: var(--text-primary); /* //color-dark */
	background: none; /* background-color-light */
	border: none;
	outline: none;
	caret-color: var(--icon-accent);

	&:hover {
		background-color: var(--surface-tertiary);
	}
}
