.headerText {
	justify-content: center !important;

	font-size: 14px;
	font-weight: bold;
	letter-spacing: -0.31px;
	border: none !important;
}

.modalBody {
	min-height: 250px;
	padding: 0 !important;
}

.optionContainer {
	width: 100%;
	padding: 5px 0 5px 18px;
	border-bottom: 1px solid var(--gray-10);

	&:last-child {
		border-bottom: none;
	}

	.option {
		padding: 10px;
		display: flex;
		align-items: center;
	}
	.icon {
		font-size: 18px;
		color: var(--text-primary); /* //color-dark */
		margin: 0 10px;
	}

	.location {
		font-size: 14px;
		font-weight: bold;
		letter-spacing: -0.31px;
		margin-right: 10px;
		min-width: 100px;
	}
}

.email {
	cursor: pointer;
	text-decoration: underline;
}

.mailLink {
	color: var(--text-primary); /* //color-dark */

	&:hover {
		color: var(--text-primary); /* //color-dark */
	}
}
