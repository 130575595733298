.infoModalWrapper {
	width: 380px;
	background-color: var(--menu-primary);
	position: absolute;
	box-shadow: var(--box-shadow-S);
	border-radius: 24px;
	padding: 16px;
	color: var(--text-primary);
	z-index: 400;
	display: flex;
	flex-direction: column;
	gap: 16px;

	.contentWrapper {
		display: flex;
		flex-direction: column;
		gap: 12px;
		padding: 0 8px;
	}
}

.left {
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-right: 15px solid var(--menu-primary);
	position: absolute;
	left: -1.8%;
	top: 45%;
}

.right {
	width: 0;
	height: 0;
	border-top: 15px solid transparent;
	border-bottom: 15px solid transparent;
	border-left: 15px solid var(--menu-primary);
	position: absolute;
	right: -1.8%;
	top: 45%;
}

.top {
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-bottom: 15px solid var(--menu-primary);
	position: absolute;
	top: -15px;
	left: 45%;
}

.bottom {
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid var(--menu-primary);
	position: absolute;
	left: 45%;
	bottom: -15px;
}

.header {
	font-size: var(--font-size-13);
	text-align: left;
	letter-spacing: -0.41px;
	// margin-top: 25px;
	text-align: center;

	h1 {
		font-size: 20px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: var(--text-primary);
	}
	ul,
	p {
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.31;
		letter-spacing: normal;
		text-align: left;
		color: var(--text-primary);
		margin: 0;
	}
	.content {
		padding: 0 8px;
	}
}

html[dir='rtl'] {
	.right {
		top: 40%;
	}
}
