.modalSize {
  min-width: 70%;
  min-height: 700px !important;

  :global {
    .modal-content {
      width: 100% !important;
      padding: 0 !important;
    }
  }
}

.modalBody {
  padding: 0 !important;
  margin-bottom: -100px;
}
