.icon {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	color: var(--icon-primary);
	font-size: 20px;
	border: 1px solid transparent;
	padding: 2px;
	transition: all ease 0.2s;
	position: relative;
	height: 28px;
	width: 28px;

	&.live {
		border-color: var(--icon-live);
	}

	&.demo {
		border-color: var(--icon-demo);
	}
}

.accountsList {
	padding: 12px;
	display: flex;
	flex-direction: column;
	gap: 4px;
	max-height: 80vh;
	overflow: auto;

	.accountsListItem {
		display: flex;
		cursor: pointer;
		color: var(--text-primary);
		font-size: var(--font-size-13);
		white-space: nowrap;
		line-height: 17px;
		height: 40px;
		flex: 1;
		padding: 8px 12px;
		flex-direction: column;
		transition: all ease-in-out 0.2s;
		border-radius: 8px;
		border: 1px solid transparent;

		&.noPadding {
			padding: 0;
		}

		&:hover {
			background: var(--surface-tertiary);
		}

		&.active {
			background: var(--surface-low);
			border: 1px solid var(--border-accent);
		}

		.accountInfo {
			display: flex;
			flex: 1;
			align-items: center;
			justify-content: flex-start;
			height: 40px;
			padding: 8px 12px;
			gap: 4px;

			.demo {
				color: var(--text-demo);
				text-transform: uppercase;
			}

			.live {
				color: var(--text-live);
				text-transform: uppercase;
			}
		}

		.statsContainer {
			display: flex;
			flex-direction: column;

			.stats {
				&.selected {
					font-weight: 700;
				}
			}

			.baseCurrency {
				font-size: var(--font-size-12);
				color: var(--text-secondary);
			}
		}
	}

	.addAccountContainer {
		@extend .accountsListItem;

		flex-direction: row;
		gap: 4px;
		align-items: center;
		justify-content: flex-start;

		.plusIcon {
			@extend .icon;

			width: 14px;
			height: 14px;
			font-weight: bold;
			font-size: 14px;
			padding: 6px;
		}

		.accountName {
			font-weight: bold;
		}

		.accountType {
			&.demo {
				color: var(--text-demo);
				text-transform: uppercase;
			}

			&.live {
				color: var(--text-live);
				text-transform: uppercase;
			}
		}
	}
}
