.modal > div {
	width: 500px !important;
}

.modalHeader {
	z-index: 1;
	width: 100%;
	padding: 16px 16px 0;
	display: flex;
	flex-direction: row;
	gap: 12px;
	border-bottom: none !important;
	align-items: start;
}

.modalTitle {
	width: 100%;
	font-size: var(--fs-20);
}

.noPadding {
	padding: 0 !important;
}

.modalBody {
	padding: 8px 16px 12px;
}

.iconContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;

	.icon {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		height: 28px;
		width: 28px;
		font-size: var(--fs-20);

		&:hover {
			background-color: var(--surface-tertiary);
			border-radius: 50%;
		}
	}
}

.modalFooter {
	display: flex;
	justify-content: space-between;
	padding: 12px 16px 16px;
	border-top: 1px solid var(--border-primary);
	z-index: 1;
	gap: 12px;

	& > * {
		width: 100% !important;
		max-width: unset !important;
	}
}

.modalButtons {
	width: 100%;
	display: flex;
}

.button {
	max-width: 50%;
	padding: 20px;

	&:first-of-type {
		margin-right: 12px;
	}
}

.cancelButton {
	@extend .button;
	transition: all 0.15s ease-in;
}

.noBody {
	.modalHeader {
		box-shadow: none;
	}
}

html[dir='rtl'] {
	.modalHeader {
		padding: 2rem 2rem 1rem 3rem;
	}
}

:global {
	.modal-footer > * {
		margin: 0;
	}

	.modal-footer {
		flex-wrap: nowrap;
	}
	.modal-title {
		padding: 0;
	}
}
