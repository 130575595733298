body {
  font-family: var(--font-family-all-languages) !important;

  button,
  input,
  h2,
  h4,
  div,
  .header,
  .dx-widget {
    font-family: var(--font-family-all-languages) !important;
  }

  &.isVietnamese {
    font-family: var(--font-family-vietnamese) !important;

    button,
    input,
    h2,
    h4,
    div,
    .header,
    .dx-widget {
      font-family: var(--font-family-vietnamese) !important;
    }
  }

  &.isJapanese {
    font-family: var(--font-family-japanese) !important;

    button,
    input,
    h2,
    h4,
    div,
    .header,
    .dx-widget {
      font-family: var(--font-family-japanese) !important;
    }
  }

  &.isThai {
    font-family: var(--font-family-thai) !important;

    button,
    input,
    h2,
    h4,
    div,
    .header,
    .dx-widget {
      font-family: var(--font-family-thai) !important;
    }
  }

  &.isArab {
    font-family: var(--font-family-arab) !important;

    button,
    input,
    h2,
    h4,
    div,
    .header,
    .dx-widget {
      font-family: var(--font-family-arab) !important;
    }
  }

  &.isTraditionalChinese {
    font-family: var(--font-family-traditional-chinese) !important;

    button,
    input,
    h2,
    h4,
    div,
    .header,
    .dx-widget {
      font-family: var(--font-family-traditional-chinese) !important;
    }
  }

  &.isSimplifiedChinese {
    font-family: var(--font-family-simplified-chinese) !important;

    button,
    input,
    h2,
    h4,
    div,
    .header,
    .dx-widget {
      font-family: var(--font-family-simplified-chinese) !important;
    }
  }

  .isLatin {
    font-family: var(--font-family-all-languages) !important;
  }

  .isVietnamese {
    font-family: var(--font-family-vietnamese) !important;
  }

  .isJapanese {
    font-family: var(--font-family-japanese) !important;
  }

  .isThai {
    font-family: var(--font-family-thai) !important;
  }

  .isArab {
    font-family: var(--font-family-arab) !important;
  }

  .isTraditionalChinese {
    font-family: var(--font-family-traditional-chinese) !important;
  }

  .isSimplifiedChinese {
    font-family: var(--font-family-simplified-chinese) !important;
  }
}
