.container {
	display: flex;
	flex-direction: column;
	align-items: center;

	&.containerHorizontalLayout {
		flex-direction: row;

		.signalBars {
			margin-right: 4px;
		}
	}

	.percent {
		font-size: var(--font-size-11);
		line-height: 1.5;
	}

	.signalBars .bar {
		width: 4px;
		margin-left: 2px;
		min-height: 20%;
		display: inline-block;
		border-radius: 4px;
		background-color: var(--surface-accent); /* background-color-positive */
	}

	.sizingBox {
		height: 20px;
	}

	.signalBars .bar.firstBar {
		height: 20%;
	}

	.signalBars .bar.secondBar {
		height: 40%;
	}

	.signalBars .bar.thirdBar {
		height: 60%;
	}

	.signalBars .bar.fourthBar {
		height: 80%;
	}

	.signalBars .bar.fifthBar {
		height: 99%;
	}

	.fourBars .bar.fifthBar,
	.threeBars .bar.fifthBar,
	.threeBars .bar.fourthBar,
	.oneBar .bar:not(.firstBar),
	.twoBars .bar:not(.firstBar):not(.secondBar) {
		background-color: var(--gray-30);
		border: thin solid var(--gray-30);
	}
}
