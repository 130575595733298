@import 'src/css/common/_custom_scrollbar';

$groups-column-width: 250px;

.containerWrapper {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
}

.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.marketsWrapper {
	min-height: 1px;
	height: 100%;
	display: flex;
}

.marketNews {
	border-left: 3px solid var(--border-primary);
	height: 100%;
	width: 100%;
}

.marketList {
	width: 100%;
	min-width: 1px;
}

.jpNews {
	display: flex;
	border-top: 3px solid var(--border-primary);
	width: 100%;
	height: 100%;
	flex-flow: column nowrap;
}

.newsAndSignals {
	display: flex;
	border-top: 3px solid var(--border-primary);
	width: 100%;
	height: 100%;

	.newsOrSignalsColumn {
		width: 50%;
	}
}

.insights {
	border-top: 3px solid var(--border-primary);
}

.inline {
	display: flex;
	background-color: var(--surface-primary); /* background-color-light */
	height: 100%;
	width: 100%;
}

.marketTabs {
	float: left;
	border-right: 3px solid var(--border-primary); /* border-right-gray-30 */
	background-color: var(--surface-primary); /* background-color-gray-5 */
	height: 100%;
	width: 100%;
	overflow-x: hidden;
	@extend %custom-scrollbar-element;
	scrollbar-gutter: auto;
}

.lightpositionType {
	display: flex;
	background-color: #f3f4f4;
	color: black;
	padding: 4px;
	width: 100%;
	outline: none;
	cursor: pointer;
	transition: 0.3s;
	font-size: 17px;
	font-weight: bold;
	border-bottom: 1px solid lightgray;

	font-size: 13px;
	border-right: 4px solid var(--border-accent);
	text-align: left;
}

.position {
	display: flex;
	padding: 8px 12px;
	width: 100%;
	outline: none;
	cursor: pointer !important;
	font-size: var(--fs-13);
	text-align: left;
	color: var(--text-primary); /* //color-dark */
}

.positionType {
	@extend .position;
	font-weight: bold;
	color: var(--text-primary); //color--gray-10);
	border-bottom: 1px solid var(--border-primary);
	background-color: var(--surface-low);
	border: 1px solid var(--border-accent);
	cursor: default !important;
	display: flex;
	align-items: center;
	flex-direction: row;
	gap: 4px;
	height: 40px;
}

.positionTypeOption {
	@extend .position;
	background-color: var(--surface-primary);
	border-bottom: 1px solid var(--border-primary);
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 40px;
	gap: 4px;
	&:hover {
		background-color: var(--surface-low); /* background-color-gray-5 */
	}
}

.icon {
	width: 18px;
	height: 18px;
	margin-right: 4px;
	margin-left: 4px;
}

.labelText {
	white-space: nowrap;
	font-size: var(--fs-13);
	text-overflow: ellipsis;
	overflow: hidden;
}

.active {
	filter: var(--brightness-icon);
}

.iconTier1,
.iconTier2,
.iconTier3 {
	filter: none;
}

.categoryGroupContainer {
	position: relative;
	display: flex;
}

.selectedSearchCategory {
	z-index: 2;
	min-height: 100%;
	position: absolute;
	right: 0;
	transform: translateX(100%);
}

.hideSelectedSearchCategory {
	display: none;
}

.risers {
	border-right: 3px solid var(--border-primary); /* border-right-gray-30 */
	border-left: 3px solid var(--border-primary);
}

.fallers {
	border-left: 3px solid var(--border-primary);
}

html[dir='rtl'] {
	.news {
		border-top: 3px solid var(--border-primary);
		border-left: 3px solid var(--border-primary);
		border-right: initial;
	}

	.insights {
		border-left: initial;
	}

	.positionType {
		text-align: right;
		margin-right: 0px !important;
		border-right: initial;
	}

	.positionTypeOption {
		text-align: right;
		margin-right: 0px !important;
	}

	.marketTabs {
		border-right: none;
		border-left: 3px solid var(--border-primary);
	}

	.selectedSearchCategory {
		left: 0;
		right: initial;
		transform: rotate(180deg) translateX(100%);
	}

	.marketNews {
		border-right: 3px solid var(--border-primary); /* border-right-gray-30 */
	}

	.labelText {
		white-space: nowrap;
		font-size: 11px;
	}

	.fallers {
		border-right: 3px solid var(--border-primary); /* border-right-gray-30 */
		border-left: none;
	}
}
