.imageWrapper {
	display: flex;
	justify-content: center;

	.imageNoOutline {
		display: flex;
		justify-content: center;
		align-items: center;
		// border: none;
		width: 100%;
	}

	.imageOutline {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 3px solid var(--color-green-1);
		width: 48px;
		height: 48px;
		border-radius: 50%;
	}
}

img.small {
	width: 32px;
	height: auto;
}

img.large {
	width: 60px;
	height: auto;
	border-radius: 50%;
	color: var(--text-primary); //color--gray-10);
}

.small {
	font-size: 24px;
	cursor: pointer;
	color: var(--text-primary); /* //color-dark */
}

.large {
	font-size: 32px;
	color: var(--text-primary); /* //color-dark */
	color: var(--text-primary); //color--gray-10);
}

.loader:global(.ui.loader) {
	top: 0;
	left: 0;
	width: 26px;
	height: 26px;
	transform: none;

	&::before,
	&::after {
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		margin: 0;
		border-width: 3px;
	}

	&::before {
		border-color: transparent;
	}

	&::after {
		border-color: var(--text-secondary) transparent transparent;
	}
}
