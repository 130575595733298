@import 'src/css/common/_custom_scrollbar';

.popup {
	position: absolute;
	top: 0;
	right: -33px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	width: 260px;
	height: auto;
	border-radius: 20px;
	box-shadow: var(--box-shadow-S);
	background-color: var(--menu-primary);
	cursor: default;
	padding: 12px;

	.content {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: center;

		& > *:not(:last-child) {
			border-bottom: 1px solid var(--border-primary);
		}

		.wrapperItems {
			width: 100%;
		}
	}

	.itemContent {
		.item {
			display: flex;
			align-items: center;
			width: 260px;
			height: 40px;
			background-color: var(--menu-primary);
			height: 40px;
			display: flex;
			align-items: center;
			width: 100%;
			cursor: pointer;
			font-size: var(--font-size-13);
			color: var(--text-primary);
			border-radius: 8px;
			gap: 8px;
			padding: 0 8px;

			&:hover {
				background-color: var(--surface-tertiary); /* background-color-gray-5 */
			}
		}

		.itemNoBorder {
			border-bottom: none;
		}

		.iconWrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 32px;
			height: 32px;

			.icon {
				font-size: 20px;
				align-self: center;
				color: var(--icon-primary);
			}
		}
	}
}

.popupIsArabic {
	position: absolute;
	top: 0px;
	right: -220px !important;
	background-color: var(--menu-primary) !important;
}

html[dir='rtl'] {
	.icon {
		margin-left: 8px;
		margin-right: 12px;
		font-size: 20px;
		align-self: center;
	}
}
