.container {
	position: relative;
	height: 36px;
	background-color: var(--surface-secondary);
	transition: all ease 0.2s;
	display: flex;
	align-items: center;
}

.statsContainer {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	position: relative;
	cursor: default;
}

.statsLabel {
	font-size: var(--font-size-11);
	color: var(--text-secondary);

	line-height: 13px;
}

.value {
	font-size: var(--font-size-13);
	color: var(--text-primary);

	white-space: nowrap;
	line-height: 15px;
}

.divider {
	position: absolute;
	width: 14px;
	height: 16px;
	line-height: normal;
	color: var(--border-tertiary);
	top: 50%;
	left: 0;
	transform: translate(-57%, -50%);
	display: flex;

	align-items: center;
	justify-content: center;
	font-size: 24px;
	z-index: 1;
}

.default {
	color: var(--text-primary);
}

.green {
	color: var(--text-positive);
}

.orange {
	color: var(--text-indifferent);
}

.red {
	color: var(--text-negative);
}

html[dir='rtl'] {
	.value {
		direction: ltr;
		text-align: right;
	}
}
