.app {
	width: 100%;
	height: 100%;
}

.notifications-component {
	z-index: 10000 !important;
}

.rnc__notification-container--top-right {
	top: 55px !important;
}