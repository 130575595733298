:root {
	--light-blue: #1fc0d74d;

	--topaz: #1fc0d7;
	--topaz-light: #ddf6f9;
	--topaz-gradient: linear-gradient(147deg, #4c9ad7 0%, #1fc0d7 100%);
	--topaz-hover: #1da9bd;

	--mint: #13c0a0;
	--mint-hover: #058c73;

	--orange: #fc8b05;
	--light-orange: #fee8cd;

	--watermelon: #f74760;
	--watermelon-hover: #de324b;

	--positive: #13c0a0;
	--positive-hover: #058c73;

	--negative: #f74760;
	--negative-hover: #de324b;

	--surface-surface-tertialy: #e4e8ee;

	--icon-icon-primary: #19202D;

	// For text
	--light: #fff;
	--dark: #1c282e;

	--light-widget: #1c242e;
	--light-widget--hover: #2f3947;
	--light-widget--hover-reverse: #e9edf1;
	--light-widget--disabled: #1c242e;
	--light-widget-reverse: #ffffff;
	--light-widget--background: #f2f4f8;
	--light-widget--text: #556478;
	--light-widget-input-background: #e5e8ed;
	--light-widget--bullet: #cbd2dc;

	--login-page-sumbit-text: #fff;
	--login-page-links: #299310;
	--login-page-links-reverse: #24b502;

	// --constant-dark: #1c282e; // When this variable is being uset it wont be changed in the dark theme
	// --constant-light: #ffffff; // When this variable is being uset it wont be changed in dark theme
	--inverted-dark: #1c282e; // This variable should be used when you want the color to be dark in the light theme and light in the dark theme

	//Light theme grays
	--gray-5: #f3f4f4; //used also for hover
	--gray-10: #e8e9ea;
	--gray-30: #babec0;
	--gray-50: #8d9396; //generic gray
	--gray-90: #333d43;
	--selected-gray: #e6e6e6;

	// These colors have to be replaced or deleted
	--color-neutral-4: #3c4b53; // ???
	--color-neutral-5: #273840; // delete me
	--color-neutral-6: #121b1e; // delete me
	--color-neutral-7: #121212; // ???

	--font-size-9: 9px;
	--font-size-11: 11px;
	--font-size-13: 13px;
	--font-size-15: 15px;
	--font-size-20: 20px;
	--font-size-24: 24px;
	--font-size-36: 36px;

	--padding-4: 4px;
	--padding-8: 8px;
	--padding-12: 12px;
	--padding-16: 16px;
	--padding-24: 24px;

	--margin-4: 4px;
	--margin-8: 8px;
	--margin-12: 12px;
	--margin-16: 16px;
	--margin-24: 24px;
	--margin-32: 32px;

	--border-radius-4: 4px;

	--background-1: rgba(28, 40, 46, 0.02);
	--background-2: #f3f4f4;
	--background-3: rgba(255, 255, 255, 0.2);
	--shadow: var(--transparent-dark-30);

	--box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16); // from top to bottom
	--box-shadow-reversed: 0 -2px 4px 0 rgba(0, 0, 0, 0.16); // from bottom to top
	--box-shadow-S: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
	--input-field-box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.15);
	--box-shadow-hover-l: 0px 0px 20px rgba(0, 0, 0, 0.3);
	--box-shadow-S: 0 0 18px var(--shadow);

	--dark-blue-grey: #00484f;
	// This is used for the search bar instrument icons because they are based on brightness to change colors
	--brightness-icon: brightness(1.5);
	--theme-toggle-color: rgba(0, 0, 0, 0.2);
	// ------------------ OLD VARIABLES ------------------
	// These variables are still here because some of them are being used in the charts
	// Primary colors (blue)
	--color-primary-1: #1fc0d7;
	--color-primary-2: #11b0c7;
	--color-primary-3: #14acbf;
	--color-primary-4: #0c4c56; //Updated light-mode background for nav

	// Neutral colors
	--color-neutral-1: #f3f4f4;
	--color-neutral-2: #e8e9ea;
	--color-neutral-3: #939b9f;
	--color-neutral-4: #3c4b53;
	--color-neutral-5: #273840;
	--color-neutral-6: #121b1e;
	--color-neutral-7: #121212;
	--color-neutral-8: #1c282e;

	// White & Black Colors
	--constant-light: #ffffff;
	--color-black: #000000;

	// Red colors
	--color-red-1: #f74760;
	--color-red-2: #de324b;

	// Red colors
	--color-red-1: #f74760;
	--color-red-2: #de324b;
	--login-error: #f74545;
	--login-error-backrgound: #fff5f5;

	// Grey colors
	--color-grey-1: #939b9f;
	--color-grey-2: #939b9f;
	--color-grey-3: #f3f4f4;
	--color-grey-4: #8d9396;
	--color-grey-5: #e8e9ea;
	--color-grey-6: #babec0;
	--color-grey-7: #60686d;

	//Blue Colors
	--color-blue-1: #158696;
	--color-blue-2: #00c3da;

	// Font Family
	--font-family-all-languages: Figtree, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

	--font-family-vietnamese: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

	--font-family-japanese: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

	--font-family-thai: 'Noto Sans Thai', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

	--font-family-arab: 'Noto Sans Arabic', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif,
		'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

	--font-family-traditional-chinese: 'PingFang TC', 'Microsoft YaHei', 'Noto Sans Traditional Chinese', -apple-system,
		BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

	--font-family-simplified-chinese: 'PingFang SC', 'Microsoft YaHei', 'Noto Sans Simplified Chinese', -apple-system,
		BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

	// Font Sizes
	--fs-9: 9px;
	--fs-10: 10px;
	--fs-11: 11px;
	--fs-12: 12px;
	--fs-13: 13px;
	--fs-15: 15px;
	--fs-18: 18px;
	--fs-20: 20px;
	--fs-24: 24px;

	// Spacing (Padding & Margin)
	--p-4: 4px;
	--p-8: 8px;
	--p-12: 12px;
	--p-16: 16px;
	--p-24: 24px;
	--p-32: 32px;

	--m-4: 4px;
	--m-8: 8px;
	--m-12: 12px;
	--m-16: 16px;
	--m-24: 24px;
	--m-32: 32px;

	// Border Radius
	--br-4: 4px;
	--button-br: 8px;
	--modal-dialog-content-br: 24px;

	// Live account color
	--live-account-color: #13c0a0;

	// Login page background color
	--login-page-color: #070f1b;

	// Demo account color
	--demo-account-color: #1fc0d7;

	// Box-shadow colors
	--box-shadow-1: rgba(0, 0, 0, 0.16);
	--box-shadow-2: rgba(250, 251, 255, 0.75);
	--box-shadow-3: rgba(147, 155, 159, 0.2);
	--box-shadow-4: #b6c6d4;
	--box-shadow-5: rgba(0, 0, 0, 0.2);
	--box-shadow-6: rgba(0, 0, 0, 0.12);
	--box-shadow-7: rgba(0, 0, 0, 0.14);
	--box-shadow-8: rgba(28, 40, 46, 0.4);

	// Background colors
	--background-1: rgba(28, 40, 46, 0.02);
	--background-2: rgba(239, 241, 243, 0.993);

	// Border colors
	--br-color-1: rgba(39, 56, 64, 0.1);
	--br-color-2: rgba(34, 36, 38, 0.15);

	// Transparent colors
	--transparent-color-1: rgba(0, 0, 0, 0.1);

	// Font colors
	--font-color-1: rgba(28, 40, 46, 0.5);

	// Watermark colors
	--watermark-font-color: rgba(0, 0, 0, 0.2);

	// Slider handle gradient
	--slider-handle-gradient: linear-gradient(transparent, rgba(0, 0, 0, 0.05));

	//---------------------------------------------------------------

	--nav-background: linear-gradient(to bottom, #267871 0%, #136a8a 46%, #182848); //Updated based on approved design
	--nav-drop-shadow: none;

	//Primative colors
	--blue-50: #eff6ff;
	--blue-500: #4c84ee;
	--blue-600: #3266e3;
	--blue-700: #2652d0;
	--blue-950: #1a253d;
	--gray-0: #ffffff;
	--gray-100: #f2f5f8;
	--gray-150: #eaeef2;
	--gray-200: #e4e8ee;
	--gray-250: #d9dee6;
	--gray-300: #ced5dd;
	--gray-350: #b3bcc8;
	--gray-400: #99a3b2;
	--gray-450: #818c9d;
	--gray-50: #f8f9fb;
	--gray-500: #6a7585;
	--gray-550: #5b6676;
	--gray-600: #4b5666;
	--gray-650: #424c5b;
	--gray-700: #384250;
	--gray-750: #2d3643;
	--gray-800: #222a36;
	--gray-850: #19202d;
	--gray-900: #131926;
	--gray-950: #050814;
	--green-50: #e9f7ea;
	--green-500: #29c903;
	--green-600: #24b502;
	--green-700: #299310;
	--green-950: #1b2c2a;
	--orange-500: #fc8b05;
	--orange-600: #ee880f;
	--red-50: #feeded;
	--red-500: #f86363;
	--red-600: #f74747;
	--red-700: #d33d43;
	--red-950: #2f2430;
	--transparent-dark-30: #19202d4c;
	--transparent-dark-40: #05081466;
	--transparent-dark-60: #05081499;
	--transparent-light-30: #ffffff4c;
	--transparent-light-40: #ffffff66;
	--transparent-light-60: #ffffff99;

	//Light colors

	--border-accent: var(--blue-600);
	--border-accent-secondary: var(--blue-50);
	--border-alert: var(--red-600);
	--border-invert: var(--gray-800);
	--border-negative: var(--red-700);
	--border-positive: var(--green-600);
	--border-primary: var(--gray-150);
	--border-quaternary: var(--gray-700);
	--border-secondary: var(--gray-250);
	--border-success: var(--green-600);
	--border-tertiary: var(--gray-350);
	--button-negative: var(--red-700);
	--button-negative-hover: var(--red-600);
	--button-negative-secondary: var(--red-50);
	--button-neutral: var(--gray-850);
	--button-neutral-hover: var(--gray-700);
	--button-positive: var(--green-700);
	--button-positive-hover: var(--green-600);
	--button-positive-secondary: var(--green-50);
	--button-primary: var(--blue-700);
	--button-primary-hover: var(--blue-600);

	--constant-dark: var(--gray-850);
	--constant-light: var(--gray-50);

	--icon-accent: var(--blue-600);
	--icon-alert: var(--red-600);
	--icon-demo: var(--blue-600);
	--icon-invert: var(--gray-50);
	--icon-live: var(--green-600);
	--icon-negative: var(--red-600);
	--icon-positive: var(--green-600);
	--icon-primary: var(--gray-850);
	--icon-secondary: var(--gray-500);
	--icon-success: var(--green-600);
	--icon-tertiary: var(--gray-300);

	--input-alert: var(--red-50);
	--input-primary: var(--gray-100);
	--input-secondary: var(--gray-150);
	--input-tertiary: var(--gray-350);
	--input-low: var(--gray-100);

	--menus-menu-primary: var(--gray-0);

	--surface-accent: var(--blue-600);
	--surface-accent-secondary: var(--blue-50);
	--surface-alert: var(--red-600);
	--surface-alert-secondary: var(--red-50);
	--surface-indifferent: var(--orange-600);
	--surface-invert: var(--gray-850);
	--surface-low: var(--gray-100);
	--surface-negative: var(--red-600);
	--surface-overlay: var(--transparent-dark-40);
	--surface-positive: var(--green-600);
	--surface-primary: var(--gray-0);
	--surface-quaternary: var(--gray-350);
	--surface-secondary: var(--gray-50);
	--surface-succes-secondary: var(--green-50);
	--surface-success: var(--green-600);
	--surface-tertiary: var(--gray-200);
	--surface-transparent: var(--transparent-light-30);

	--text-accent: var(--blue-600);
	--text-alert: var(--red-600);
	--text-demo: var(--blue-600);
	--text-indifferent: var(--orange-600);
	--text-invert: var(--gray-50);
	--text-live: var(--green-600);
	--text-negative: var(--red-700);
	--text-positive: var(--green-600);
	--text-primary: var(--gray-850);
	--text-secondary: var(--gray-550);
	--text-success: var(--green-600);
	--text-tertiary: var(--gray-400);

	--bs-info-rgb: 50, 102, 227;

	//Light Japan colors
	--border-accent: var(--blue-600);
	--border-accent-secondary: var(--blue-50);
	--border-alert: var(--red-600);
	--border-invert: var(--gray-800);
	--border-negative: var(--red-700);
	--border-positive: var(--green-600);
	--border-primary: var(--gray-150);
	--border-quaternary: var(--gray-700);
	--border-secondary: var(--gray-250);
	--border-success: var(--green-600);
	--border-tertiary: var(--gray-350);
	--button-negative: var(--red-700);
	--button-negative-hover: var(--red-600);
	--button-negative-secondary: var(--red-50);
	--button-neutral: var(--gray-850);
	--button-neutral-hover: var(--gray-700);
	--button-positive: var(--green-700);
	--button-positive-hover: var(--green-600);
	--button-positive-secondary: var(--green-50);
	--button-primary: var(--blue-700);
	--button-primary-hover: var(--blue-600);

	--constant-dark: var(--gray-850);
	--constant-light: var(--gray-50);

	--icon-accent: var(--blue-600);
	--icon-alert: var(--red-600);
	--icon-demo: var(--blue-600);
	--icon-invert: var(--gray-50);
	--icon-live: var(--green-600);
	--icon-negative: var(--red-600);
	--icon-positive: var(--green-600);
	--icon-primary: var(--gray-850);
	--icon-secondary: var(--gray-500);
	--icon-success: var(--green-600);
	--icon-tertiary: var(--gray-300);

	--input-alert: var(--red-50);
	--input-primary: var(--gray-100);
	--input-secondary: var(--gray-150);
	--input-tertiary: var(--gray-350);
	--input-low: var(--gray-100);

	--menu-primary: var(--gray-0);

	--surface-accent: var(--blue-600);
	--surface-accent-secondary: var(--blue-50);
	--surface-alert: var(--red-600);
	--surface-alert-secondary: var(--red-50);
	--surface-indifferent: var(--orange-600);
	--surface-invert: var(--gray-850);
	--surface-low: var(--gray-100);
	--surface-negative: var(--red-600);
	--surface-overlay: var(--transparent-dark-40);
	--surface-positive: var(--green-600);
	--surface-primary: var(--gray-0);
	--surface-quaternary: var(--gray-350);
	--surface-secondary: var(--gray-50);
	--surface-succes-secondary: var(--green-50);
	--surface-success: var(--green-600);
	--surface-tertiary: var(--gray-200);
	--surface-transparent: var(--transparent-light-30);

	--text-accent: var(--blue-600);
	--text-alert: var(--red-600);
	--text-demo: var(--blue-600);
	--text-indifferent: var(--orange-600);
	--text-invert: var(--gray-50);
	--text-live: var(--green-600);
	--text-negative: var(--red-700);
	--text-positive: var(--green-600);
	--text-primary: var(--gray-850);
	--text-secondary: var(--gray-550);
	--text-success: var(--green-600);
	--text-tertiary: var(--gray-400);
}

:export {
	--positive: #299310;
	--positive-hover: #24b502;
	--dark: #1c282e;
	--light: #ffffff;
	--negative: #d33d43;
	--negative-hover: #f74747;
	--accent: #3266e3;
	--topaz-gradient: linear-gradient(147deg, #4c9ad7 0%, #1fc0d7 100%);
	--login-page-color: #070f1b;
	--constant-dark: #19202d;
	--constant-light: #8d9396;
	--border-accent: #3266e3;
	--text-primary: #19202d;
	--text-secondary: #5b6676;
	--button-neutral: #19202d;
	--icon-primary: #19202d;
	--button-primary: #2652d0;
	--surface-primary: #ffffff;
	--button-primary: #2652d0;
	--surface-negative: #f74747;
	--surface-positive: #24b502;
}
