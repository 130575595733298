@import 'src/css/common/_custom_scrollbar';

.tableSettingsPopup {
	position: absolute;
	right: 1rem;
	top: 5rem;
	padding: 12px 0 12px 12px;
	background-color: var(--menu-primary);
	width: 260px;
	height: 223px;
	z-index: 999999;
	border-radius: 20px;
	box-shadow: var(--box-shadow-S);
	@extend %custom-scrollbar-element;
	overflow: hidden;


	.tableSettingsTitle {
		color: var(--text-primary); /* //color-dark */
		font-size: var(--font-size-15);
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		margin-bottom: 12px;
		padding: 0px 8px;
	}

	.tableSettingOptionsContainer {
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		height: 170px;

		.checkboxContainer {
			display: flex;
			align-items: center;
			padding: 4px 8px;
			border-radius: 8px;
			&:hover {
				background-color: var(--surface-tertiary); /* background-color-gray-5 */
				cursor: pointer;
			}
			.label {
				color: var(--text-primary); /* //color-dark */
				font-size: var(--font-size-13);
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				margin-left: 3px;
				user-select: none;
			}
		}
	}
}

.tableSettingsPopupPosition {
	bottom: 12rem;
	top: inherit;
}

.tableSettingsPopupPositionChild {

}

.container {
	display: flex;
	position: relative;
	align-items: center;
	padding-left: 22px;
	width: 100%;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	.optionText {
		color: var(--text-primary); /* //color-dark */
		font-size: var(--font-size-13);
		font-style: normal;
		font-weight: 400;
	}
}

/* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 16px;
	width: 16px;
	border-radius: 3px;
	border: 1px solid var(--border-accent); //border-topaz
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	color: var(--text-primary); //color--gray-10);
}

/* When the checkbox is checked, add a teal background */
.container input:checked ~ .checkmark {
	background-color: var(--surface-accent);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
	left: 5px;
	top: 1px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.disabledCheckbox {
	opacity: 0.3;
}

html[dir='rtl'] {
	.tableSettingsPopup {
		right: unset;
		left: 1rem;

		.optionText {
			margin-right: 1.5rem;
		}

		.checkmark {
			right: 0;
			left: auto;
		}

		.checkmark:after {
			right: 5px;
			left: auto;
		}
	}
}
