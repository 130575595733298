@import 'src/css/common/_custom_scrollbar';

$cell-spacing: 6px 12px;

.centerContent {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.disableTextSelection {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

.container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	color: var(--text-primary); /* //color-dark */
	color: var(--text-primary); //color--gray-10);
	overflow-y: auto;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */

	.signalsItemWrapper {
		display: flex;
		align-items: center;
		padding-left: 6px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&.border {
		border-right: 3px solid var(--border-primary); /* border-right-gray-30 */
	}

	.headerContainer {
		background-color: var(--surface-primary);
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid var(--border-primary);
		padding: 0px 12px;

		.titleContainer {
			display: flex;
			width: 100%;
			align-items: center;
			min-height: 40px;

			.title {
				flex-grow: 1;
				text-align: center;
				font-size: var(--font-size-13);
				font-weight: bold;
			}

			.filterIconHolder {
				position: relative;
				cursor: pointer;

				.hasFiltersIndicator {
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background: var(--surface-negative); /* background-color-negative */
					position: absolute;
					top: -2px;
					right: -2px;
				}

				.filterIcon {
					cursor: pointer;
					font-size: 16px;
				}
			}
		}
	}

	.signalsTable {
		table-layout: fixed;
		position: relative;
		width: 100%;
		border-collapse: collapse;
		line-height: 21px;

		tr > td {
			&:first-of-type {
				//max-width: 0;
			}
		}

		.tableHeadersRow {
			th {
				position: sticky;
				top: 0;

				span {
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}

				&:first-of-type {
					width: 50%;
				}

				&:nth-of-type(2) {
					width: 20%;
				}

				&:nth-of-type(3) {
					width: 30%;
				}
			}

			.header {
				@extend .disableTextSelection;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 4px 0px 4px 12px;
				width: 100%;
				cursor: pointer;
				color: var(--text-secondary);
				font-weight: normal;
				font-size: var(--fs-13);
				background-color: var(--surface-primary);
				border-bottom: 1px solid var(--border-primary);

				.selected {
					color: var(--text-primary); /* //color-dark */
				}

				&:hover {
					color: var(--text-primary); /* //color-dark */
				}

				&:nth-child(1),
				&:nth-child(2) {
					border-right: 1px solid var(--border-primary);
				}

				.sortingChevtonsContainer {
					width: 10px; // this is used to avoid the jumping of the headers when there is no chevron
				}
			}
		}

		.signalItem {
			border-top: 1px solid var(--border-primary);
			background-color: var(--surface-primary);
			&:first-of-type {
				border-top: none;
			}
			&:hover {
				background-color: var(--surface-low); /* background-color-gray-5 */
				cursor: pointer;
			}

			&.selected {
				color: var(--text-primary); //color--gray-10);
				background-color: var(--surface-low);
				border: 1px solid var(--border-accent);
			}

			.instrumentInfo {
				display: flex;
				flex-direction: column;
				padding-top: 9px;
				padding-left: 4px;
				width: 100%;
				overflow: hidden;

				.code {
					font-weight: bold;
				}

				.description {
					font-size: var(--font-size-11);
					color: var(--text-secondary);
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			.confidencePercent {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: $cell-spacing;
			}

			.orderStats {
				display: flex;
				flex-direction: column;
				text-align: right;
				padding: $cell-spacing;
				font-size: var(--font-size-11);

				.orderType {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}

	.loader {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
		background-color: var(--surface-primary); /* background-color-gray-5 */
	}
}

.scrollableWrapper {
	@extend %custom-scrollbar-element;
	flex-grow: 1;
	overflow-y: auto;
	background-color: var(--surface-primary); /* background-color-gray-5 */

	.addSimulation {
		color: var(--text-primary); /* //color-dark */

		font-size: var(--font-size-13);
		font-weight: bold;
		display: flex;
		align-items: center;
		padding: 6px 12px;

		.plusIcon {
			font-size: 18px;
		}

		&:hover {
			cursor: pointer;
		}

		span {
			padding-left: 6px;
		}
	}
}

html[dir='rtl'] {
	.container {
		&.border {
			border-right: none;
			border-left: 3px solid var(--border-primary);
		}
	}

	.instrumentInfo {
		padding-left: 0px;
		padding-right: 4px;
	}

	.signalsItemWrapper {
		padding-left: 0px;
		padding-right: 6px;
	}
}
