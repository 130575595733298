@import 'src/css/common/_custom_scrollbar';

.popup {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	width: 600px;
	height: 300px;
	border-radius: 20px;
	background-color: var(--menu-primary);
	cursor: default;

	.header {
		z-index: 1;
		display: flex;
		justify-content: flex-start;
		padding: 16px;
		background-color: var(--menu-primary);
		color: var(--text-primary);
		font-weight: 600;
		font-size: 20px;
	}

	.content {
		@extend %custom-scrollbar-element;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow-y: auto;

		& > *:not(:last-child) {
			border-bottom: 1px solid var(--border-primary);
		}
	}

	.list {
		padding: 0 12px;
		border-bottom: 1px solid var(--border-primary);
	}

	.emptyList {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 15px 20px 20px 20px;
	}

	.emptyListIcon {
		width: 109px;
		height: 108px;
		margin: 15px 0;
	}

	.emptyText {
		font-size: var(--font-size-15);
		font-weight: 600;
	}

	.emptySubText {
		font-size: var(--font-size-13);
	}

	.emptyText,
	.emptySubText {
		margin: 10px 0 0;
		color: var(--text-primary);
	}
}

:global(html[dir='rtl'] .dark) {
	.popup {
		background-color: var(--gray10);
	}
}
