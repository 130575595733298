.wrapper {
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: 0 12px 10px;

	font-size: 13px;
	font-weight: 400;

	& > .title {
		color: var(--text-primary); /* //color-dark */
		margin-bottom: 4px;
	}

	& > *:last-child {
		border-bottom: solid 1px var(--gray-10);
		padding-bottom: 10px;
	}
}

.infoTooltip {
	font-size: 12px;
	margin-right: 4px;
}

.header {
	display: flex;
	justify-content: space-between;
	color: var(--text-secondary);
}
